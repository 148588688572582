import { useApi } from '@/api';
import { CacheKeys } from '@/api/keys';
import { useQuery } from '@tanstack/react-query';

interface IUseUserProps {
  enabled?: boolean;
}

export function useUser({ enabled }: IUseUserProps = { enabled: true }) {
  const { api, client, mutate } = useApi();

  const { isLoading, data: user } = useQuery({
    queryKey: [CacheKeys.user],
    queryFn: async () => api.user.getUser({ api: client }),
    enabled,
  });

  return {
    isLoading,
    user,
    mutate: async () => mutate([CacheKeys.user]),
  };
}
