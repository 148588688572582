import { ReactNode } from 'react';
import { ParentBackground } from './shared';

export enum ToastMessageKind {
  BRAND = 'brand',
  WAITING = 'waiting',
  DANGER = 'danger',
  SUCCESS = 'success',
}

export interface IToastMessageConfigProps {
  autoClose?: number | false;
  hideProgressBar?: boolean;
  parentBackground: ParentBackground;
  customButton?: ReactNode;
}

export interface IToastMessageStylesProps {
  kind: ToastMessageKind;
  parentBackground: ParentBackground;
}

export interface IToastMessageProps extends IToastMessageStylesProps {
  message: string;
  customButton?: ReactNode;
}

export interface IUseToastNotificationsProps {
  handleSuccess: (message: string, description?: string) => void;
  handleFailure: (message: string, description?: string) => void;
  handleInfo: (message: string, description?: string) => void;
  handleWaiting: (message: string, description?: string) => void;
}
