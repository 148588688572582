import { IBaseRequest } from '@/api/types';

interface IGetSendersProps extends IBaseRequest {
  clientId: string;
}

export async function getSenders({
  api,
  clientId,
}: IGetSendersProps): Promise<string[]> {
  const response = await api.get<string[]>(
    `clients/${clientId}/invoices/senders`,
  );

  return response.data;
}
