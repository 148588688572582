import { TariffGroup } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetTariffGroupProps extends IBaseRequest {
  clientId: string;
}

export async function getTariffGroup({
  api,
  clientId,
}: IGetTariffGroupProps): Promise<TariffGroup> {
  const response = await api.get<TariffGroup>(
    `clients/${clientId}/tariff_group`,
  );

  return response.data;
}
