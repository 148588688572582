import { IBaseRequest } from '@/api/types';
import { IGetPaymentsParams } from './types';
import { Payment } from '@/entities/Payments';

interface IGetPlannedPaymentsProps extends IBaseRequest {
  clientId: string;
  params?: IGetPaymentsParams;
}

export async function getPlannedPayments({
  api,
  clientId,
  params,
}: IGetPlannedPaymentsProps): Promise<Payment[]> {
  const response = await api.get<Payment[]>(
    `clients/${clientId}/payments/planned`,
    { params },
  );

  return response.data;
}
