import { Transaction } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetTransactionProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getAuditLogs({
  api,
  clientId,
  id,
}: IGetTransactionProps): Promise<Transaction> {
  const response = await api.get<Transaction>(
    `clients/${clientId}/display_transactions/${id}/audit_logs`,
  );

  return response.data;
}
