import { Draft } from '@/entities';
import { IBaseRequest } from '@/api/types';
import { IGetDraftParams } from '@/api/methods/drafts/types.ts';

interface IGetDraftsProps extends IBaseRequest {
  clientId: string;
  params?: IGetDraftParams;
}

export async function getDrafts({
  api,
  clientId,
  params,
}: IGetDraftsProps): Promise<Draft[]> {
  const response = await api.get<Draft[]>(`clients/${clientId}/drafts`, {
    params,
  });

  return response.data;
}
