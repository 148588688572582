import { ISearchQuery } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetSearchQueriesProps extends IBaseRequest {
  clientId: string;
}

export async function getSearchQueries({
  api,
  clientId,
}: IGetSearchQueriesProps): Promise<ISearchQuery[]> {
  const response = await api.get<ISearchQuery[]>(
    `clients/${clientId}/search_queries`,
  );

  return response.data;
}
