import { PropsWithChildren, useMemo } from 'react';
import { Gradient, gradientMap } from '@/ui/components/types/icon';

interface ISVGContainerProps {
  className?: string;
  viewBox: string;
  gradient?: Gradient | undefined;
}

export function SVGContainer({
  children,
  className,
  viewBox,
  gradient,
}: PropsWithChildren<ISVGContainerProps>) {
  const content = useMemo(() => {
    if (gradient) {
      return (
        <>
          <defs>
            {gradientMap[gradient]}

            <mask id="iconMask">{children}</mask>
          </defs>

          <rect
            width="100%"
            height="100%"
            fill={`url(#${gradient})`}
            mask="url(#iconMask)"
          />
        </>
      );
    }

    return children;
  }, [children, gradient]);

  return (
    <svg
      className={className}
      width="100%"
      height="100%"
      viewBox={viewBox}
      fill="none"
    >
      {content}
    </svg>
  );
}
