import { AxiosError, isAxiosError } from 'axios';
import {
  ApiErrorCodes,
  IBaseErrors,
  IBaseResponse,
  IBaseResponseMeta,
} from './types';

export function createSuccessResponse<T, E = unknown>({
  result,
  meta,
  extra,
}: {
  result: T;
  meta?: IBaseResponseMeta;
  extra?: E;
}): IBaseResponse<T, E> {
  return {
    success: true,
    code: undefined,
    result,
    meta,
    extra,
  };
}

export function createErrorResponse<T, E = unknown>({
  code,
  error,
  errors,
  extra,
}: {
  code?: ApiErrorCodes;
  error?: string;
  errors?: IBaseErrors;
  extra?: E;
}): IBaseResponse<T, E> {
  return {
    success: false,
    code: code ?? ApiErrorCodes.API_INTERNAL_SERVER_ERROR,
    error: error ?? 'Something went wrong, please try later or contact support',
    errors,
    extra,
  };
}

export function createErrorHandler<T, E = unknown>(
  error: unknown | AxiosError,
) {
  if (isAxiosError(error)) {
    const responseCode =
      error?.response?.data?.errorCode || error?.response?.status || 500;

    if (responseCode === 400 || responseCode === 422) {
      return createErrorResponse<T, E>({
        code: ApiErrorCodes.API_VALIDATION_ERROR,
        errors: error?.response?.data?.errors ?? {},
        error: error?.response?.data?.error ?? '',
      });
    }

    if (responseCode === 401) {
      return createErrorResponse<T, E>({
        code: ApiErrorCodes.API_UNAUTHORIZED,
      });
    }

    if (responseCode === 403) {
      return createErrorResponse<T, E>({
        code: ApiErrorCodes.API_FORBIDDEN,
      });
    }

    if (responseCode === 429) {
      return createErrorResponse<T, E>({
        code: ApiErrorCodes.API_RATE_LIMIT_ERROR,
      });
    }

    return createErrorResponse<T, E>({
      code: ApiErrorCodes.API_INTERNAL_SERVER_ERROR,
      error: error?.response?.data?.error ?? '',
    });
  }

  return createErrorResponse<T, E>({
    code: ApiErrorCodes.CLIENT_GENERAL_ERROR,
    error: (error as Error)?.message,
  });
}
