import { lazy } from 'react';
import { usePosthogIdentify } from '@/posthog/usePosthogIdentify.ts';
import { useAuth } from '@/auth/useAuth.ts';
import { AuthorizedState } from '@/auth/types.ts';
import { useResolution } from '@/device/useResolution.ts';
import { AuthChecking } from '@/auth/AuthChecking';
// import { useApplicationResolution } from '@/device/useApplicationResolution.ts';

const PublicApplication = lazy(() => import('../apps/public'));
const DesktopApplication = lazy(() => import('../apps/bank-desktop'));
const MobileApplication = lazy(() => import('../apps/bank-mobile'));

export function MainRouter() {
  const { isMobile } = useResolution();
  const { status } = useAuth();
  usePosthogIdentify();

  // authorization checking & basic loading
  if (status === AuthorizedState.FETCHING) {
    return <AuthChecking />;
  }

  if (status === AuthorizedState.UNAUTHORIZED) {
    return <PublicApplication />;
  }

  if (status === AuthorizedState.AUTHORIZED && !isMobile) {
    return <DesktopApplication />;
  }

  if (isMobile) {
    return <MobileApplication />;
  }

  // if (status === AuthorizedState.authorized && isMobile) {
  //   return <MobileApplication />;
  // }
  //
  // return <div>Not implemented yet</div>;
}
