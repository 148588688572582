import { Invoice } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IArchiveInvoiceProps extends IBaseRequest {
  clientId: string;
  invoiceId: string;
}

export async function archiveInvoice({
  api,
  clientId,
  invoiceId,
}: IArchiveInvoiceProps): Promise<IBaseResponse<Invoice>> {
  try {
    const response = await api.post<IBaseResponse<Invoice>>(
      `clients/${clientId}/invoices/${invoiceId}/archive`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
