import { IClientUserAccountDetails } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountsDetailsProps extends IBaseRequest {
  clientId: string;
  clientUserId: string;
}

export async function getAccountsDetails({
  api,
  clientId,
  clientUserId,
}: IGetAccountsDetailsProps): Promise<IClientUserAccountDetails[]> {
  const response = await api.get<IClientUserAccountDetails[]>(
    `clients/${clientId}/client_users/${clientUserId}/accounts_details`,
  );

  return response.data;
}
