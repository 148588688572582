import { FlexibleObject } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { createRequestWithTempToken } from '@/security/paranoid/createRequestWithTempToken';

export enum SendVerificationCodeType {
  PHONE = 'phone',
  EMAIL = 'email',
}

interface IVerificationCodeProps<T extends FlexibleObject>
  extends IBaseRequest {
  dto: T;
  type: SendVerificationCodeType;
  captchaToken: string;
}

export async function sendVerificationCode<T extends FlexibleObject>({
  api,
  dto,
  type,
  captchaToken,
}: IVerificationCodeProps<T>): Promise<IBaseResponse<string>> {
  try {
    const response = await createRequestWithTempToken<T, IBaseResponse<string>>(
      {
        api,
        dto,
        url: `auth/tfa/${type}/send`,
        captchaToken,
      },
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
