import { ITransactionSyncResult } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface ISyncTransactionProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function syncTransaction({
  api,
  clientId,
  id,
}: ISyncTransactionProps): Promise<IBaseResponse<ITransactionSyncResult>> {
  try {
    const response = await api.post<ITransactionSyncResult>(
      `clients/${clientId}/display_transactions/${id}/sync`,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
