import { css } from '@emotion/react';

export const allColorsObj: Record<string, string> = {
  '--color-chart-purpose-1-day': '#ffcf4a',
  '--color-chart-purpose-1-night': '#ffd664',
  '--color-chart-purpose-2-day': '#ff7d3e',
  '--color-chart-purpose-2-night': '#ff894f',
  '--color-chart-purpose-3-day': '#ff368d',
  '--color-chart-purpose-3-night': '#ff4897',
  '--color-chart-purpose-4-day': '#974dff',
  '--color-chart-purpose-4-night': '#a05cff',
  '--color-chart-purpose-5-day': '#3a86ff',
  '--color-chart-purpose-5-night': '#4a90ff',
  '--color-chart-purpose-6-day': '#44edcc',
  '--color-chart-purpose-6-night': '#60f4d7',
  '--color-chart-purpose-7-day': '#36c97e',
  '--color-chart-purpose-7-night': '#4ed892',
  '--color-chart-purpose-8-day': '#28af87',
  '--color-chart-purpose-8-night': '#3dc79e',
  '--color-chart-most-sent-1-day': '#942aa8',
  '--color-chart-most-sent-1-night': '#9b3aad',
  '--color-chart-most-sent-2-day': '#ea1c91',
  '--color-chart-most-sent-2-night': '#ea2d98',
  '--color-chart-most-sent-3-day': '#fb516a',
  '--color-chart-most-sent-3-night': '#f95d74',
  '--color-chart-most-sent-4-day': '#ff8954',
  '--color-chart-most-sent-4-night': '#ff9667',
  '--color-chart-most-sent-5-day': '#ffc250',
  '--color-chart-most-sent-5-night': '#ffc760',
  '--color-chart-most-sent-6-day': '#ffea7c',
  '--color-chart-most-sent-6-night': '#ffed8e',
  '--color-chart-most-sent-7-day': '#ddffba',
  '--color-chart-most-sent-7-night': '#e4ffc8',
  '--color-chart-most-received-1-day': '#6bfcb3',
  '--color-chart-most-received-1-night': '#80ffbf',
  '--color-chart-most-received-2-day': '#30d8ef',
  '--color-chart-most-received-2-night': '#43dff4',
  '--color-chart-most-received-3-day': '#4388fa',
  '--color-chart-most-received-3-night': '#5493fb',
  '--color-chart-most-received-4-day': '#5955f4',
  '--color-chart-most-received-4-night': '#6561f2',
  '--color-chart-most-received-5-day': '#6e37c9',
  '--color-chart-most-received-5-night': '#7644c9',
  '--color-chart-most-received-6-day': '#a623bb',
  '--color-chart-most-received-6-night': '#ab36be',
  '--color-chart-most-received-7-day': '#d12884',
  '--color-chart-most-received-7-night': '#d6368d',
  '--color-pressed-success-surface-on-bg-two-night': '#31554e',
  '--color-hover-success-surface-on-bg-one-night': '#2d463f',
  '--color-hover-success-surface-on-bg-two-day': '#c5f6df',
  '--color-hover-success-surface-on-bg-one-day': '#c1ecdf',
  '--color-hover-success-surface-on-bg-two-night': '#2d4742',
  '--color-pressed-successr-surface-on-bg-one-day': '#adeedb',
  '--color-pressed-successr-surface-on-bg-one-night': '#2a4e44',
  '--color-pressed-success-surface-on-bg-two-day': '#b5fbda',
  '--color-label-tertiary-night': '#777790',
  '--color-empty-illustration-on-bg-two-night': '#6f7184',
  '--color-brand-default-night': '#8286FF',
  '--color-teammate-a5surface-on-bg-one-night': '#283553',
  '--color-teammate-a7initial-night': '#34acae',
  '--color-teammate-a5initial-day': '#4681f1',
  '--color-teammate-a6surface-on-bg-two-day': '#daf2fc',
  '--color-teammate-a1surface-on-bg-two-night': '#4c2b61',
  '--color-teammate-a3surface-on-bg-two-night': '#372c6c',
  '--color-teammate-a8surface-on-bg-one-night': '#2b413f',
  '--color-teammate-a3initial-night': '#704aff',
  '--color-teammate-a4surface-on-bg-two-night': '#2f306b',
  '--color-teammate-a5surface-on-bg-two-day': '#dae6fc',
  '--color-teammate-a5initial-night': '#4681f1',
  '--color-teammate-a1initial-night': '#ae31dd',
  '--color-teammate-a1surface-on-bg-one-day': '#e7cef7',
  '--color-teammate-a4surface-on-bg-two-day': '#d8d8fc',
  '--color-teammate-a6surface-on-bg-one-night': '#293b47',
  '--color-teammate-a7surface-on-bg-two-day': '#cdf7f8',
  '--color-teammate-a1initial-day': '#bd34f0',
  '--color-teammate-a8surface-on-bg-one-day': '#c8efe6',
  '--color-teammate-a1surface-on-bg-two-day': '#f2d6fc',
  '--color-teammate-a7surface-on-bg-one-night': '#283d43',
  '--color-teammate-a2surface-on-bg-one-night': '#42255d',
  '--color-teammate-a1surface-on-bg-one-night': '#462559',
  '--color-teammate-a3initial-day': '#5c35f0',
  '--color-teammate-a3surface-on-bg-one-day': '#d4cef7',
  '--color-teammate-a3surface-on-bg-one-night': '#322765',
  '--color-teammate-a7initial-day': '#42cacc',
  '--color-teammate-a2surface-on-bg-one-day': '#e2cdf7',
  '--color-teammate-a2surface-on-bg-two-day': '#ecd6fc',
  '--color-teammate-a7surface-on-bg-one-day': '#c4eff4',
  '--color-teammate-a8surface-on-bg-two-day': '#d1f7ea',
  '--color-teammate-a3surface-on-bg-two-day': '#ded7fc',
  '--color-teammate-a2initial-night': '#a033f0',
  '--color-teammate-a8initial-night': '#3cac87',
  '--color-teammate-a7surface-on-bg-two-night': '#2e444c',
  '--color-teammate-a2initial-day': '#a033f0',
  '--color-teammate-a8initial-day': '#58d3aa',
  '--color-teammate-a8surface-on-bg-two-night': '#314848',
  '--color-teammate-a4surface-on-bg-one-night': '#2a2a63',
  '--color-teammate-a5surface-on-bg-one-day': '#d0ddf7',
  '--color-teammate-a4initial-night': '#5e5eff',
  '--color-teammate-a4surface-on-bg-one-day': '#cdcff7',
  '--color-teammate-a4initial-day': '#3b3bf0',
  '--color-teammate-a6initial-day': '#4dbbeb',
  '--color-teammate-a5surface-on-bg-two-night': '#2d3c5b',
  '--color-teammate-a6initial-night': '#47a3cc',
  '--color-teammate-a6surface-on-bg-one-day': '#d0e9f8',
  '--color-teammate-a2surface-on-bg-two-night': '#472b65',
  '--color-teammate-a6surface-on-bg-two-night': '#2f4150',
  '--color-input-on-bg-two-night': '#353548',
  '--color-input-on-bg-one-night': '#3a3b46',
  '--color-hover-no-surface-on-bg-one-day': '#e5e9f4',
  '--color-hover-no-surface-on-bg-two-day': '#f9f9ff',
  '--color-empty-illustration-on-bg-two-day': '#e6e6f0',
  '--color-disabled-on-bg-two-night': '#2f313f',
  '--color-disabled-on-bg-two-day': '#eff2f8',
  '--color-disabled-on-bg-one-night': '#282a35',
  '--color-disabled-on-bg-one-day': '#e6ebf6',
  '--color-label-primary-night': '#eff1ff',
  '--color-label-quaternary-night': '#454857',
  '--color-border-night': '#424555',
  '--color-label-tertiary-day': '#bbbbd5',
  '--color-border-day': '#dfdfe7',
  '--color-label-secondary-night': '#a0a3bd',
  '--color-label-secondary-day': '#8b8ba2',
  '--color-bg-one-night': '#1f2026',
  '--color-bg-one-day': '#F6F8FF',
  '--color-bg-two-night': '#262831',
  '--color-bg-two-day': '#ffffff',
  '--color-danger-additional-on-bg-one-night': '#42252A',
  '--color-danger-additional-on-bg-two-night': '#4C262D',
  '--color-colored-surface-three-additional-on-bg-two-night': '#4b462a',
  '--color-empty-illustration-on-bg-one-day': '#d9d9de',
  '--color-colored-surface-four-additional-on-bg-two-day': '#e3f7ff',
  '--color-empty-illustration-on-bg-one-night': '#4a4d53',
  '--color-waiting-additional-on-bg-two-day': '#FFF6E4',
  '--color-pressed-brand-surface-on-bg-two-night': '#353f5e',
  '--color-hover-brand-surface-on-bg-one-night': '#2d354c',
  '--color-colored-surface-four-additional-on-bg-one-night': '#2e3e4c',
  '--color-pressed-brand-surface-on-bg-one-night': '#323b59',
  '--color-pressed-no-surface-on-bg-one-day': '#e7e9ef',
  '--color-danger-additional-on-bg-one-day': '#FFEAEE',
  '--color-integration-surface-on-bg-two-day': '#21252e',
  '--color-colored-surface-three-additional-on-bg-one-night': '#3e3922',
  '--color-waiting-additional-on-bg-one-day': '#FFF2DD',
  '--color-hover-danger-surface-on-bg-one-day': '#f1dbe7',
  '--color-hover-brand-surface-on-bg-one-day': '#d8e0fb',
  '--color-colored-surface-five-additional-on-bg-one-night': '#2e3e4c',
  '--color-pressed-danger-surface-on-bg-one-day': '#f0d3e2',
  '--color-hover-no-surface-on-bg-two-night': '#2a2d37',
  '--color-integration-surface-on-bg-two-night': '#3f4453',
  '--color-colored-surface-five-additional-on-bg-two-night': '#2d3b48',
  '--color-hover-no-surface-on-bg-one-night': '#24252c',
  '--color-integration-surface-on-bg-one-day': '#21252e',
  '--color-colored-surface-five-default-night': '#0a84ff',
  '--color-hover-danger-surface-on-bg-two-night': '#573544',
  '--color-colored-surface-four-day': '#5ac8fa',
  '--color-pressed-brand-surface-on-bg-two-day': '#d8e0ff',
  '--color-pressed-danger-surface-on-bg-two-day': '#fbdce7',
  '--color-pressed-no-surface-on-bg-two-day': '#f3f3f5',
  '--color-hover-brand-surface-on-bg-two-night': '#313852',
  '--color-pressed-no-surface-on-bg-two-night': '#2f323d',
  '--color-hover-danger-surface-on-bg-two-day': '#fce4ec',
  '--color-hover-danger-surface-on-bg-one-night': '#52303d',
  '--color-pressed-danger-surface-on-bg-one-night': '#5c3342',
  '--color-pressed-brand-surface-on-bg-one-day': '#c5d3fb',
  '--color-integration-surface-on-bg-one-night': '#3a3e4a',
  '--color-colored-surface-three-additional-on-bg-two-day': '#fff8db',
  '--color-colored-surface-three-default-night': '#ffd60a',
  '--color-colored-surface-two-default-day': '#af52de',
  '--color-hover-brand-surface-on-bg-two-day': '#e7ecff',
  '--color-pressed-danger-surface-on-bg-two-night': '#63384a',
  '--color-colored-surface-three-additional-on-bg-one-day': '#f4eed6',
  '--color-colored-surface-five-default-day': '#007aff',
  '--color-pressed-no-surface-on-bg-one-night': '#282a32',
  '--color-colored-surface-two-additional-on-bg-two-day': '#f6ecfb',
  '--color-colored-surface-two-additional-on-bg-two-night': '#453258',
  '--color-colored-surface-two-additional-on-bg-one-day': '#ebe4f6',
  '--color-colored-surface-two-additional-on-bg-one-night': '#3f2c4f',
  '--color-brand-additional-on-bg-one-night': '#2D3555',
  '--color-waiting-default-night': '#FFAC4B',
  '--color-waiting-additional-on-bg-two-night': '#3C3534',
  '--color-danger-default-night': '#FF4D78',
  '--color-waiting-additional-on-bg-one-night': '#352E29',
  '--color-colored-surface-one-additional-on-bg-two-day': '#eeeefb',
  '--color-success-additional-on-bg-one-night': '#213619',
  '--color-success-default-night': '#66C42D',
  '--color-success-additional-on-bg-two-night': '#253822',
  '--color-colored-surface-one-additional-on-bg-one-day': '#dbdcf4',
  '--color-colored-surface-five-additional-on-bg-two-day': '#e3f7ff',
  '--color-colored-surface-four-additional-on-bg-one-day': '#dbeffa',
  '--color-colored-surface-five-additional-on-bg-one-day': '#dbeffa',
  '--color-colored-surface-four-additional-on-bg-two-night': '#2d3b48',
  '--color-colored-surface-two-default-night': '#bf5af2',
  '--color-colored-surface-one-additional-on-bg-two-night': '#313253',
  '--color-colored-surface-one-additional-on-bg-one-night': '#2e2e54',
  '--color-input-on-bg-two-day': '#f2f2f7',
  '--color-waiting-default-day': '#FF891D',
  '--color-success-default-day': '#55BA32',
  '--color-danger-additional-on-bg-two-day': '#FFF1F3',
  '--color-danger-default-day': '#ED3964',
  '--color-input-on-bg-one-day': '#e6e8f1',
  '--color-brand-default-day': '#3C59EF',
  '--color-label-quaternary-day': '#dedeec',
  '--color-chip-default-day': '#f2f2f7',
  '--color-white-static-day': '#ffffff',
  '--color-colored-surface-one-default-day': '#5856d6',
  '--color-white-static-night': '#ffffff',
  '--color-brand-additional-on-bg-one-day': '#E6ECFF',
  '--color-brand-additional-on-bg-two-night': '#2C324B',
  '--color-label-primary-day': '#0b1c49',
  '--color-card-bg-day': '#f6f8fd',
  '--color-card-bg-night': '#2e313d',
  '--color-success-additional-on-bg-two-day': '#E9FBE6',
  '--color-success-additional-on-bg-one-day': '#D7F4D6',
  '--color-brand-disable-night': '#686AA4',
  '--color-brand-disable-day': '#a2b6ff',
  '--color-integration-surface-disable-on-bg-one-day': '#75787f',
  '--color-integration-surface-disable-on-bg-one-night': '#2f323c',
  '--color-brand-additional-on-bg-two-day': '#F1F4FF',
  '--color-colored-surface-three-default-day': '#ffcc00',
  '--color-colored-surface-four-night': '#64d2ff',
  '--color-chip-default-night': '#2d2d31',
  '--color-colored-surface-one-default-night': '#5e5ce6',
  '--color-translucent-card-bg-day': '#FFFFFF80',
  '--color-translucent-card-bg-night': '#8EA4EC12',
};

export const allColors = css`
  --color-chart-purpose-1-day: #ffcf4a;
  --color-chart-purpose-1-night: #ffd664;
  --color-chart-purpose-2-day: #ff7d3e;
  --color-chart-purpose-2-night: #ff894f;
  --color-chart-purpose-3-day: #ff368d;
  --color-chart-purpose-3-night: #ff4897;
  --color-chart-purpose-4-day: #974dff;
  --color-chart-purpose-4-night: #a05cff;
  --color-chart-purpose-5-day: #3a86ff;
  --color-chart-purpose-5-night: #4a90ff;
  --color-chart-purpose-6-day: #44edcc;
  --color-chart-purpose-6-night: #60f4d7;
  --color-chart-purpose-7-day: #36c97e;
  --color-chart-purpose-7-night: #4ed892;
  --color-chart-purpose-8-day: #28af87;
  --color-chart-purpose-8-night: #3dc79e;
  --color-chart-most-sent-1-day: #942aa8;
  --color-chart-most-sent-1-night: #9b3aad;
  --color-chart-most-sent-2-day: #ea1c91;
  --color-chart-most-sent-2-night: #ea2d98;
  --color-chart-most-sent-3-day: #fb516a;
  --color-chart-most-sent-3-night: #f95d74;
  --color-chart-most-sent-4-day: #ff8954;
  --color-chart-most-sent-4-night: #ff9667;
  --color-chart-most-sent-5-day: #ffc250;
  --color-chart-most-sent-5-night: #ffc760;
  --color-chart-most-sent-6-day: #ffea7c;
  --color-chart-most-sent-6-night: #ffed8e;
  --color-chart-most-sent-7-day: #ddffba;
  --color-chart-most-sent-7-night: #e4ffc8;
  --color-chart-most-received-1-day: #6bfcb3;
  --color-chart-most-received-1-night: #80ffbf;
  --color-chart-most-received-2-day: #30d8ef;
  --color-chart-most-received-2-night: #43dff4;
  --color-chart-most-received-3-day: #4388fa;
  --color-chart-most-received-3-night: #5493fb;
  --color-chart-most-received-4-day: #5955f4;
  --color-chart-most-received-4-night: #6561f2;
  --color-chart-most-received-5-day: #6e37c9;
  --color-chart-most-received-5-night: #7644c9;
  --color-chart-most-received-6-day: #a623bb;
  --color-chart-most-received-6-night: #ab36be;
  --color-chart-most-received-7-day: #d12884;
  --color-chart-most-received-7-night: #d6368d;
  --color-pressed-success-surface-on-bg-two-night: #31554e;
  --color-hover-success-surface-on-bg-one-night: #2d463f;
  --color-hover-success-surface-on-bg-two-day: #c5f6df;
  --color-hover-success-surface-on-bg-one-day: #c1ecdf;
  --color-hover-success-surface-on-bg-two-night: #2d4742;
  --color-pressed-successr-surface-on-bg-one-day: #adeedb;
  --color-pressed-successr-surface-on-bg-one-night: #2a4e44;
  --color-pressed-success-surface-on-bg-two-day: #b5fbda;
  --color-label-tertiary-night: #777790;
  --color-empty-illustration-on-bg-two-night: #6f7184;
  --color-brand-default-night: #8286ff;
  --color-teammate-a5surface-on-bg-one-night: #283553;
  --color-teammate-a7initial-night: #34acae;
  --color-teammate-a5initial-day: #4681f1;
  --color-teammate-a6surface-on-bg-two-day: #daf2fc;
  --color-teammate-a1surface-on-bg-two-night: #4c2b61;
  --color-teammate-a3surface-on-bg-two-night: #372c6c;
  --color-teammate-a8surface-on-bg-one-night: #2b413f;
  --color-teammate-a3initial-night: #704aff;
  --color-teammate-a4surface-on-bg-two-night: #2f306b;
  --color-teammate-a5surface-on-bg-two-day: #dae6fc;
  --color-teammate-a5initial-night: #4681f1;
  --color-teammate-a1initial-night: #ae31dd;
  --color-teammate-a1surface-on-bg-one-day: #e7cef7;
  --color-teammate-a4surface-on-bg-two-day: #d8d8fc;
  --color-teammate-a6surface-on-bg-one-night: #293b47;
  --color-teammate-a7surface-on-bg-two-day: #cdf7f8;
  --color-teammate-a1initial-day: #bd34f0;
  --color-teammate-a8surface-on-bg-one-day: #c8efe6;
  --color-teammate-a1surface-on-bg-two-day: #f2d6fc;
  --color-teammate-a7surface-on-bg-one-night: #283d43;
  --color-teammate-a2surface-on-bg-one-night: #42255d;
  --color-teammate-a1surface-on-bg-one-night: #462559;
  --color-teammate-a3initial-day: #5c35f0;
  --color-teammate-a3surface-on-bg-one-day: #d4cef7;
  --color-teammate-a3surface-on-bg-one-night: #322765;
  --color-teammate-a7initial-day: #42cacc;
  --color-teammate-a2surface-on-bg-one-day: #e2cdf7;
  --color-teammate-a2surface-on-bg-two-day: #ecd6fc;
  --color-teammate-a7surface-on-bg-one-day: #c4eff4;
  --color-teammate-a8surface-on-bg-two-day: #d1f7ea;
  --color-teammate-a3surface-on-bg-two-day: #ded7fc;
  --color-teammate-a2initial-night: #a033f0;
  --color-teammate-a8initial-night: #3cac87;
  --color-teammate-a7surface-on-bg-two-night: #2e444c;
  --color-teammate-a2initial-day: #a033f0;
  --color-teammate-a8initial-day: #58d3aa;
  --color-teammate-a8surface-on-bg-two-night: #314848;
  --color-teammate-a4surface-on-bg-one-night: #2a2a63;
  --color-teammate-a5surface-on-bg-one-day: #d0ddf7;
  --color-teammate-a4initial-night: #5e5eff;
  --color-teammate-a4surface-on-bg-one-day: #cdcff7;
  --color-teammate-a4initial-day: #3b3bf0;
  --color-teammate-a6initial-day: #4dbbeb;
  --color-teammate-a5surface-on-bg-two-night: #2d3c5b;
  --color-teammate-a6initial-night: #47a3cc;
  --color-teammate-a6surface-on-bg-one-day: #d0e9f8;
  --color-teammate-a2surface-on-bg-two-night: #472b65;
  --color-teammate-a6surface-on-bg-two-night: #2f4150;
  --color-input-on-bg-two-night: #353548;
  --color-input-on-bg-one-night: #3a3b46;
  --color-hover-no-surface-on-bg-one-day: #e5e9f4;
  --color-hover-no-surface-on-bg-two-day: #f9f9ff;
  --color-empty-illustration-on-bg-two-day: #e6e6f0;
  --color-disabled-on-bg-two-night: #2f313f;
  --color-disabled-on-bg-two-day: #eff2f8;
  --color-disabled-on-bg-one-night: #282a35;
  --color-disabled-on-bg-one-day: #e6ebf6;
  --color-label-primary-night: #eff1ff;
  --color-label-quaternary-night: #454857;
  --color-border-night: #424555;
  --color-label-tertiary-day: #bbbbd5;
  --color-border-day: #dfdfe7;
  --color-label-secondary-night: #a0a3bd;
  --color-label-secondary-day: #8b8ba2;
  --color-bg-one-night: #1f2026;
  --color-bg-one-day: #f6f8ff;
  --color-bg-two-night: #262831;
  --color-bg-two-day: #ffffff;
  --color-danger-additional-on-bg-one-night: #42252a;
  --color-danger-additional-on-bg-two-night: #4c262d;
  --color-colored-surface-three-additional-on-bg-two-night: #4b462a;
  --color-empty-illustration-on-bg-one-day: #d9d9de;
  --color-colored-surface-four-additional-on-bg-two-day: #e3f7ff;
  --color-empty-illustration-on-bg-one-night: #4a4d53;
  --color-waiting-additional-on-bg-two-day: #fff6e4;
  --color-pressed-brand-surface-on-bg-two-night: #353f5e;
  --color-hover-brand-surface-on-bg-one-night: #2d354c;
  --color-colored-surface-four-additional-on-bg-one-night: #2e3e4c;
  --color-pressed-brand-surface-on-bg-one-night: #323b59;
  --color-pressed-no-surface-on-bg-one-day: #e7e9ef;
  --color-danger-additional-on-bg-one-day: #ffeaee;
  --color-integration-surface-on-bg-two-day: #21252e;
  --color-colored-surface-three-additional-on-bg-one-night: #3e3922;
  --color-waiting-additional-on-bg-one-day: #fff2dd;
  --color-hover-danger-surface-on-bg-one-day: #f1dbe7;
  --color-hover-brand-surface-on-bg-one-day: #d8e0fb;
  --color-colored-surface-five-additional-on-bg-one-night: #2e3e4c;
  --color-pressed-danger-surface-on-bg-one-day: #f0d3e2;
  --color-hover-no-surface-on-bg-two-night: #2a2d37;
  --color-integration-surface-on-bg-two-night: #3f4453;
  --color-colored-surface-five-additional-on-bg-two-night: #2d3b48;
  --color-hover-no-surface-on-bg-one-night: #24252c;
  --color-integration-surface-on-bg-one-day: #21252e;
  --color-colored-surface-five-default-night: #0a84ff;
  --color-hover-danger-surface-on-bg-two-night: #573544;
  --color-colored-surface-four-day: #5ac8fa;
  --color-pressed-brand-surface-on-bg-two-day: #d8e0ff;
  --color-pressed-danger-surface-on-bg-two-day: #fbdce7;
  --color-pressed-no-surface-on-bg-two-day: #f3f3f5;
  --color-hover-brand-surface-on-bg-two-night: #313852;
  --color-pressed-no-surface-on-bg-two-night: #2f323d;
  --color-hover-danger-surface-on-bg-two-day: #fce4ec;
  --color-hover-danger-surface-on-bg-one-night: #52303d;
  --color-pressed-danger-surface-on-bg-one-night: #5c3342;
  --color-pressed-brand-surface-on-bg-one-day: #c5d3fb;
  --color-integration-surface-on-bg-one-night: #3a3e4a;
  --color-colored-surface-three-additional-on-bg-two-day: #fff8db;
  --color-colored-surface-three-default-night: #ffd60a;
  --color-colored-surface-two-default-day: #af52de;
  --color-hover-brand-surface-on-bg-two-day: #e7ecff;
  --color-pressed-danger-surface-on-bg-two-night: #63384a;
  --color-colored-surface-three-additional-on-bg-one-day: #f4eed6;
  --color-colored-surface-five-default-day: #007aff;
  --color-pressed-no-surface-on-bg-one-night: #282a32;
  --color-colored-surface-two-additional-on-bg-two-day: #f6ecfb;
  --color-colored-surface-two-additional-on-bg-two-night: #453258;
  --color-colored-surface-two-additional-on-bg-one-day: #ebe4f6;
  --color-colored-surface-two-additional-on-bg-one-night: #3f2c4f;
  --color-brand-additional-on-bg-one-night: #2d3555;
  --color-waiting-default-night: #ffac4b;
  --color-waiting-additional-on-bg-two-night: #3c3534;
  --color-danger-default-night: #ff4d78;
  --color-waiting-additional-on-bg-one-night: #352e29;
  --color-colored-surface-one-additional-on-bg-two-day: #eeeefb;
  --color-success-additional-on-bg-one-night: #213619;
  --color-success-default-night: #66c42d;
  --color-success-additional-on-bg-two-night: #253822;
  --color-colored-surface-one-additional-on-bg-one-day: #dbdcf4;
  --color-colored-surface-five-additional-on-bg-two-day: #e3f7ff;
  --color-colored-surface-four-additional-on-bg-one-day: #dbeffa;
  --color-colored-surface-five-additional-on-bg-one-day: #dbeffa;
  --color-colored-surface-four-additional-on-bg-two-night: #2d3b48;
  --color-colored-surface-two-default-night: #bf5af2;
  --color-colored-surface-one-additional-on-bg-two-night: #313253;
  --color-colored-surface-one-additional-on-bg-one-night: #2e2e54;
  --color-input-on-bg-two-day: #f9f9ff;
  --color-waiting-default-day: #ff891d;
  --color-success-default-day: #55ba32;
  --color-danger-additional-on-bg-two-day: #fff1f3;
  --color-danger-default-day: #ed3964;
  --color-input-on-bg-one-day: #e6e8f1;
  --color-brand-default-day: #3c59ef;
  --color-label-quaternary-day: #dedeec;
  --color-chip-default-day: #f2f2f7;
  --color-white-static-day: #ffffff;
  --color-colored-surface-one-default-day: #5856d6;
  --color-white-static-night: #ffffff;
  --color-brand-additional-on-bg-one-day: #e6ecff;
  --color-brand-additional-on-bg-two-night: #2c324b;
  --color-label-primary-day: #0b1c49;
  --color-card-bg-day: #f6f8fd;
  --color-card-bg-night: #2e313d;
  --color-success-additional-on-bg-two-day: #e9fbe6;
  --color-success-additional-on-bg-one-day: #d7f4d6;
  --color-brand-disable-night: #686aa4;
  --color-brand-disable-day: #a2b6ff;
  --color-integration-surface-disable-on-bg-one-day: #75787f;
  --color-integration-surface-disable-on-bg-one-night: #2f323c;
  --color-brand-additional-on-bg-two-day: #f1f4ff;
  --color-colored-surface-three-default-day: #ffcc00;
  --color-colored-surface-four-night: #64d2ff;
  --color-chip-default-night: #2d2d31;
  --color-colored-surface-one-default-night: #5e5ce6;
  --color-translucent-card-bg-day: #ffffff80;
  --color-translucent-card-bg-night: #8ea4ec12;

  --color-recipient-avatar1-day: linear-gradient(
    45deg,
    #bd34f0 0%,
    #cb5ff4 100%
  );
  --color-recipient-avatar1-night: linear-gradient(
    45deg,
    #bd34f0 0%,
    #cb5ff4 100%
  );
  --color-recipient-avatar2-day: linear-gradient(
    45deg,
    #a033f0 0%,
    #b662f4 100%
  );
  --color-recipient-avatar2-night: linear-gradient(
    45deg,
    #a033f0 0%,
    #b662f4 100%
  );
  --color-recipient-avatar3-day: linear-gradient(
    45deg,
    #6c34f0 0%,
    #8f65f4 100%
  );
  --color-recipient-avatar3-night: linear-gradient(
    45deg,
    #6c34f0 0%,
    #8f65f4 100%
  );
  --color-recipient-avatar4-day: linear-gradient(
    45deg,
    #3b3bf0 0%,
    #6c6cf3 100%
  );
  --color-recipient-avatar4-night: linear-gradient(
    45deg,
    #3b3bf0 0%,
    #6c6cf3 100%
  );
  --color-recipient-avatar5-day: linear-gradient(
    45deg,
    #4681f1 0%,
    #4681f1 0.01%,
    #70a0f5 100%
  );
  --color-recipient-avatar5-night: linear-gradient(
    45deg,
    #4681f1 0%,
    #4681f1 0.01%,
    #70a0f5 100%
  );
  --color-recipient-avatar6-day: linear-gradient(
    45deg,
    #60c6f3 0%,
    #60c6f3 0.01%,
    #7cd4f8 100%
  );
  --color-recipient-avatar6-night: linear-gradient(
    45deg,
    #60c6f3 0%,
    #60c6f3 0.01%,
    #7cd4f8 100%
  );

  --color-recipient-avatar7-day: linear-gradient(
    45deg,
    #4ec8ca 0%,
    #5cd5d7 0.01%,
    #81fbfc 100%
  );
  --color-recipient-avatar7-night: linear-gradient(
    45deg,
    #4ec8ca 0%,
    #5cd5d7 0.01%,
    #81fbfc 100%
  );

  --color-recipient-avatar8-day: linear-gradient(
    45deg,
    #5fe1b5 0%,
    #5be1b4 0.01%,
    #82f3d2 100%
  );
  --color-recipient-avatar8-night: linear-gradient(
    45deg,
    #5fe1b5 0%,
    #5be1b4 0.01%,
    #82f3d2 100%
  );
  --color-gradient-mobile-default-day: linear-gradient(
    90deg,
    #f8eefc 0%,
    #e7ecff 100%
  );
  --color-gradient-mobile-default-night: linear-gradient(
    270deg,
    #352a46 0%,
    #292f46 100%
  );
  --color-gradient-bg-two-day: linear-gradient(
    270deg,
    var(--color-bg-two-day) 0%,
    var(--color-bg-two-day) 100%
  );
  --color-gradient-bg-two-night: linear-gradient(
    270deg,
    var(--color-bg-two-night) 0%,
    var(--color-bg-two-night) 100%
  );
  --color-gradient-blue-pink-day: linear-gradient(
    166.43deg,
    #007aff 2.29%,
    #f1a3ed 79.54%
  );
  --color-gradient-pink-purple-night: linear-gradient(
    147.45deg,
    #ff5e55 8.97%,
    #d582ff 93.09%
  );
  --color-gradient-vertical-not-intense-day: linear-gradient(
    0deg,
    #9038ff 0%,
    #464eff 100%
  );
  --color-gradient-blue-cian-night: linear-gradient(
    221.08deg,
    #7aefff 12.29%,
    #5475ff 88.68%
  );
  --color-gradient-green-purple-day: linear-gradient(
    156.04deg,
    #66edac 9.29%,
    #db77fe 87.02%
  );
  --color-gradient-blue-cian-day: linear-gradient(
    221.08deg,
    #7aefff 12.29%,
    #5475ff 88.68%
  );
  --color-gradient-horizontal-not-intense-night: linear-gradient(
    270deg,
    #9038ff 0%,
    #464eff 100%
  );
  --color-gradient-pink-purple-blue-day: linear-gradient(
    199.5deg,
    #e3418c 8.99%,
    #b580ec 49.68%,
    #64d8f6 92.1%
  );
  --color-gradient-default-day: linear-gradient(
    270deg,
    #b163ff 0%,
    #3b43ff 100%
  );
  --color-gradient-pink-purple-blue-night: linear-gradient(
    199.5deg,
    #e3418c 8.99%,
    #b580ec 49.68%,
    #64d8f6 92.1%
  );
  --color-gradient-orange-red-day: linear-gradient(
    150.05deg,
    #ffd83c -5.24%,
    #fe7b7b 75.74%
  );
  --color-gradient-vertical-not-intense-night: linear-gradient(
    0deg,
    #9038ff 0%,
    #464eff 100%
  );
  --color-gradient-orange-red-night: linear-gradient(
    150.05deg,
    #ffd83c -5.24%,
    #fe7b7b 75.74%
  );
  --color-gradient-pink-peach-day: linear-gradient(
    209.36deg,
    #ffb738 6.38%,
    #e272e1 90.62%
  );
  --color-gradient-cian-pink-day: linear-gradient(
    207.33deg,
    #ed8edd 5.68%,
    #71e4ff 83.12%
  );
  --color-gradient-blue-pink-night: linear-gradient(
    166.43deg,
    #007aff 2.29%,
    #f1a3ed 79.54%
  );
  --color-gradient-default-night: linear-gradient(
    270deg,
    #9038ff 0%,
    #464eff 100%
  );
  --color-gradient-cian-pink-night: linear-gradient(
    207.33deg,
    #ed8edd 5.68%,
    #71e4ff 83.12%
  );
  --color-gradient-green-purple-night: linear-gradient(
    156.04deg,
    #66edac 9.29%,
    #db77fe 87.02%
  );
  --color-gradient-pink-purple-day: linear-gradient(
    147.45deg,
    #ff5e55 8.97%,
    #d582ff 93.09%
  );
  --color-gradient-horizontal-not-intense-day: linear-gradient(
    270deg,
    #9038ff 0%,
    #464eff 100%
  );
  --color-gradient-pink-peach-night: linear-gradient(
    209.36deg,
    #ffb738 6.38%,
    #e272e1 90.62%
  );
  --color-gradient-blue-green-night: linear-gradient(
    224.65deg,
    #6dff9f 3.62%,
    #5c7bff 89.56%
  );
  --color-gradient-blue-green-day: linear-gradient(
    224.65deg,
    #6dff9f 3.62%,
    #5c7bff 89.56%
  );
  --color-gradient-default-for-website-night: linear-gradient(
    270deg,
    #b163ff 0%,
    #3b43ff 100%
  );
  --color-gradient-default-for-website-day: linear-gradient(
    270deg,
    #b163ff 0%,
    #3b43ff 100%
  );
`;
