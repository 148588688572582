import { IRole } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRoleProps extends IBaseRequest {
  roleId?: string;
  clientId?: string;
}

export async function getRole({
  api,
  roleId,
  clientId,
}: IGetRoleProps): Promise<IRole> {
  const response = await api.get<IRole>(`clients/${clientId}/roles/${roleId}`);

  return response.data;
}
