import { AccountMaxTransactionAmount } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetMaxTransactionAmountProps extends IBaseRequest {
  id: string;
  paymentMethod: string;
}

export async function getMaxTransactionAmount({
  api,
  id,
  paymentMethod,
}: IGetMaxTransactionAmountProps): Promise<AccountMaxTransactionAmount> {
  const response = await api.get<AccountMaxTransactionAmount>(
    `accounts/${id}/max_transaction_amount`,
    { params: { paymentMethod } },
  );

  return response.data;
}
