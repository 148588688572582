import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconDownloadFile(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 16 16">
        <path
          d="M3.75026 14.8999C3.3947 14.8999 3.08637 14.7694 2.82526 14.5083C2.56415 14.2471 2.43359 13.9388 2.43359 13.5833V2.41659C2.43359 2.04992 2.56415 1.73603 2.82526 1.47492C3.08637 1.21381 3.3947 1.08325 3.75026 1.08325H9.23359C9.41137 1.08325 9.58082 1.11936 9.74193 1.19159C9.90304 1.26381 10.0503 1.36659 10.1836 1.49992L13.1669 4.48325C13.3003 4.61658 13.403 4.76381 13.4753 4.92492C13.5475 5.08603 13.5836 5.25547 13.5836 5.43325V13.5833C13.5836 13.9388 13.453 14.2471 13.1919 14.5083C12.9308 14.7694 12.6169 14.8999 12.2503 14.8999H3.75026ZM9.10026 2.41659H3.75026V13.5833H12.2503V5.51658H9.76693C9.57804 5.51658 9.4197 5.4527 9.29193 5.32492C9.16415 5.19714 9.10026 5.03881 9.10026 4.84992V2.41659ZM3.75026 2.41659V4.84992C3.75026 5.03881 3.75026 5.19714 3.75026 5.32492C3.75026 5.4527 3.75026 5.51658 3.75026 5.51658V2.41659V4.84992C3.75026 5.03881 3.75026 5.19714 3.75026 5.32492C3.75026 5.4527 3.75026 5.51658 3.75026 5.51658V13.5833V2.41659Z"
          fill="currentColor"
        />
        <path
          d="M7.63314 7.74977C7.72758 7.64977 7.84703 7.59977 7.99147 7.59977C8.13592 7.59977 8.25536 7.64699 8.34981 7.74144C8.44425 7.83588 8.49147 7.95533 8.49147 8.09977L8.49147 10.9998L9.55814 9.9331C9.65814 9.8331 9.77481 9.7831 9.90814 9.7831C10.0415 9.7831 10.1581 9.82755 10.2581 9.91644C10.3581 10.0275 10.4109 10.1442 10.4165 10.2664C10.422 10.3887 10.3748 10.4998 10.2748 10.5998L8.49147 12.3831C8.42481 12.4498 8.35258 12.4998 8.27481 12.5331C8.19703 12.5664 8.1137 12.5831 8.02481 12.5831C7.94703 12.5831 7.86647 12.5664 7.78314 12.5331C7.69981 12.4998 7.62481 12.4498 7.55814 12.3831L5.75814 10.6664C5.65814 10.5775 5.60814 10.4609 5.60814 10.3164C5.60814 10.172 5.65258 10.0498 5.74147 9.94977C5.85258 9.84977 5.97203 9.79977 6.09981 9.79977C6.22758 9.79977 6.34147 9.84977 6.44147 9.94977L7.49147 10.9998L7.49147 8.09977C7.49147 7.96644 7.5387 7.84977 7.63314 7.74977Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
