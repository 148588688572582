import { IContactDetail, IPaymentMethodValidateResult } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IValidatePaymentMethodsProps extends IBaseRequest {
  clientId?: string;
  contactId?: string;
  dto: {
    paymentKind: string;
    paymentMethodId?: string;
    details: IContactDetail[];
  };
}

export async function validatePaymentMethod({
  api,
  clientId,
  contactId,
  dto,
}: IValidatePaymentMethodsProps): Promise<
  IBaseResponse<IPaymentMethodValidateResult>
> {
  try {
    const response = await api.post<IPaymentMethodValidateResult>(
      `/clients/${clientId}/contacts/${contactId}/payment_methods/validate`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
