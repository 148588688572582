import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IDeleteAllSearchQueriesProps extends IBaseRequest {
  clientId: string;
}

export async function deleteAllSearchQueries({
  api,
  clientId,
}: IDeleteAllSearchQueriesProps): Promise<IBaseResponse<boolean>> {
  try {
    const response = await api.delete<IBaseResponse<IBaseResponse<boolean>>>(
      `clients/${clientId}/search_queries/all`,
    );

    return createSuccessResponse({
      result: response.data.success,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
