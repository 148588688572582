import { IAccountLimitsStat } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountLimitsProps extends IBaseRequest {
  id: string;
}

export async function getAccountLimits({
  api,
  id,
}: IGetAccountLimitsProps): Promise<IAccountLimitsStat> {
  const response = await api.get<IAccountLimitsStat>(
    `accounts/${id}/limits_stat`,
  );

  return response.data;
}
