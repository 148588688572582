import { PublicAccountInfo } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPublicAccountInfoProps extends IBaseRequest {
  id: string;
}

export async function getPublicAccountInfo({
  api,
  id,
}: IGetPublicAccountInfoProps): Promise<PublicAccountInfo> {
  const response = await api.get<PublicAccountInfo>(`accounts/public/${id}`);

  return response.data;
}
