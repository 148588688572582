import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountDetailsPdfProps extends IBaseRequest {
  clientId: string;
  id: string;
  params: {
    pdfDisplayName: string;
    pdfFilename: string;
  };
}

interface IGetAccountDetailsPdfPdfResponse {
  clientId: string;
  createdAt: number;
  id: string;
  state: DocumentState;
  updatedAt: number;
  userId: string;
}

export async function getAccountDetailsPdf({
  api,
  clientId,
  id,
  params,
}: IGetAccountDetailsPdfProps) {
  const response = await api.get<IGetAccountDetailsPdfPdfResponse>(
    `clients/${clientId}/accounts/${id}/instructions/pdf`,
    { params },
  );

  return response.data;
}
