import { IBaseRequest } from '@/api/types';

interface ISendFingerprintEventProps extends IBaseRequest {
  fingerprint: string;
}

interface ISendFingerprintEventResponse {
  success: boolean;
}

export async function sendFingerprintEvent({
  api,
  fingerprint,
}: ISendFingerprintEventProps) {
  try {
    const response = await api.post<ISendFingerprintEventResponse>(
      'special_cases/fingerprint',
      { fingerprint },
    );

    return {
      success: response.data.success,
    };
  } catch (error) {
    return {
      success: false,
    };
  }
}
