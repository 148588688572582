import { PublicClientAccount } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPublicAccountByNoProps extends IBaseRequest {
  no: string;
  clientId: string;
}

export async function getPublicAccountByNo({
  api,
  no,
  clientId,
}: IGetPublicAccountByNoProps): Promise<PublicClientAccount> {
  const response = await api.get<PublicClientAccount>(
    `clients/${clientId}/client_accounts/public/${no}`,
  );

  return response.data;
}
