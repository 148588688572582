import { IBaseRequest } from '@/api/types';
import { UserDeactivation } from '@/entities/UserDeactivation';

export async function previewDeactivation({
  api,
}: IBaseRequest): Promise<UserDeactivation> {
  const response = await api.get<UserDeactivation>(`user/deactivate/preview`);

  return response.data;
}
