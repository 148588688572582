import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createSuccessResponse,
  createErrorResponse,
  createErrorHandler,
} from '@/api/helpers';

interface IInviteToArivalProps extends IBaseRequest {
  dto: {
    email: string;
    clientId: string;
  };
}

interface IInviteToArivalResponse {
  success: boolean;
}

export async function inviteToArival({
  api,
  dto,
}: IInviteToArivalProps): Promise<IBaseResponse<IInviteToArivalResponse>> {
  try {
    const response = await api.post<IBaseResponse<IInviteToArivalResponse>>(
      'special_cases/invite_to_arival',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
