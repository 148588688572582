import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IApproveLocationProps extends IBaseRequest {
  id: string;
}

interface IApproveResult {
  success: boolean;
  error: string | null;
}

export async function approveLocation({
  api,
  id,
}: IApproveLocationProps): Promise<IBaseResponse<unknown>> {
  try {
    const response = await api.post<IApproveResult>(
      `auth/user_device_locations/${id}/approve`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: '',
      });
    }

    return createErrorResponse({
      error: response.data.error ?? '',
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
