import { IBaseRequest } from '@/api/types';
import { UserDocument } from '@/entities';
import { IGetDocumentsParams } from './types';

interface IGetDocumentsProps extends IBaseRequest {
  params?: IGetDocumentsParams;
}

export async function getDocuments({
  api,
  params,
}: IGetDocumentsProps): Promise<UserDocument[]> {
  const response = await api.get<UserDocument[]>(`documents`, { params });

  return response.data;
}
