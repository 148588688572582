import { IBaseRequest } from '@/api';
import { IWiseExtension } from '@/entities';

interface IGetWiseExtensionFieldsProps extends IBaseRequest {
  clientId: string;
}

export async function getWiseExtensionFields({
  api,
  clientId,
}: IGetWiseExtensionFieldsProps) {
  const response = await api.get<IWiseExtension>(
    `wise/clients/${clientId}/extension`,
  );

  return response.data;
}
