import { IncomingInvite } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetUserInviteProps extends IBaseRequest {
  inviteId: string;
}

export async function getUserInvite({
  api,
  inviteId,
}: IGetUserInviteProps): Promise<IncomingInvite> {
  const response = await api.get<IncomingInvite>(`user/invites/${inviteId}`);

  return response.data;
}
