import { IBaseRequest } from '@/api/types';
import { ITopAccountsStats } from '@/entities';

interface IGetTopAccountsStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    since?: number;
    till?: number;
  };
}

export async function getTopAccountsStats({
  api,
  clientId,
  params,
}: IGetTopAccountsStatsProps): Promise<ITopAccountsStats> {
  const response = await api.get<ITopAccountsStats>(
    `clients/${clientId}/stat/top_accounts`,
    {
      params,
    },
  );

  return response.data;
}
