import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ButtonKind as DesktopButtonKind,
  ButtonStyleGuide as DesktopButtonStyleGuide,
} from '@/ui/components/desktop/Button/types';
import {
  ButtonKind as MobileButtonKind,
  ButtonStyleGuide as MobileButtonStyleGuide,
} from '@/ui/components/mobile/Button/types';
import { Text as DesktopText } from '@/ui/components/desktop/Text';
import { Text as MobileText } from '@/ui/components/mobile/Text';
import { fancyTimeFromSeconds } from '@/functions/utils/fancyTimeFromSeconds';
import { ConfirmationModal as MobileConfirmationModal } from '@/ui/components/mobile/ConfirmationModal';
import { StyledConfirmationModal } from './index.styles';
import { useResolution } from '@/device/useResolution.ts';
import { TextKind as DesktopTextKind } from '@/ui/components/desktop/Text/types.ts';
import { TextKind as MobileTextKind } from '@/ui/components/mobile/Text/types.ts';

export interface ISessionTimoutModal {
  opened: boolean;
  sessionExpired: boolean;
  sessionTimeout: number;
  remainingTime: number;
  enableAutoRefresh: () => void;
  makeRefreshIfNeeded: () => void;
  clearTracking: () => void;
  onClose: () => void;
}

const TITLE = 'Session timeout warning';
const EXPIRED_TITLE = 'Session timeout';
const LOGOUT_TEXT = 'Log out';
const DISMISS_TEXT = 'Stay logged in';
const LOGIN_TEXT = 'Log in';

export function SessionTimeoutModal({
  opened,
  sessionExpired,
  sessionTimeout,
  remainingTime,
  enableAutoRefresh,
  makeRefreshIfNeeded,
  clearTracking,
  onClose,
}: ISessionTimoutModal) {
  const { isMobile } = useResolution();
  const navigate = useNavigate();

  //   TODO: Uncomment
  //   const { handleLogout } = useLogoutLogic();
  //   const refreshToken = getRefreshToken();

  const refreshTokenLifetime = useMemo(() => {
    // TODO: Uncomment
    // const refreshTokenExpireTime = getExpireTime(refreshToken);
    // const refreshTokenCreatedTime = getCreatedTime(refreshToken);

    // return refreshTokenExpireTime - refreshTokenCreatedTime;

    return 100;
  }, []);

  const handleClickLogin = useCallback(() => {
    enableAutoRefresh();
    onClose();
    navigate('/');
  }, [enableAutoRefresh, navigate, onClose]);

  const handleClickLogout = useCallback(async () => {
    clearTracking();
    enableAutoRefresh();
    // TODO: Uncomment
    // await handleLogout(false);
    onClose();
  }, [clearTracking, enableAutoRefresh, onClose]);

  const handleClickStay = useCallback(() => {
    clearTracking();
    makeRefreshIfNeeded();
    enableAutoRefresh();
    onClose();
  }, [clearTracking, enableAutoRefresh, makeRefreshIfNeeded, onClose]);

  const formattedSessionTimeout = useMemo(
    () => fancyTimeFromSeconds(sessionTimeout),
    [sessionTimeout],
  );

  const formattedremainingTime = useMemo(
    () => fancyTimeFromSeconds(remainingTime),
    [remainingTime],
  );

  const title = useMemo(() => {
    if (sessionExpired) {
      return EXPIRED_TITLE;
    }

    return TITLE;
  }, [sessionExpired]);

  const description = useMemo(() => {
    if (sessionExpired) {
      return (
        <>
          You have been inactive for {formattedSessionTimeout}, for security
          reasons we had to log you out
        </>
      );
    }

    return (
      <>
        You have been inactive for {formattedSessionTimeout}. <br /> After{' '}
        {formattedremainingTime} your session will expire.
      </>
    );
  }, [formattedSessionTimeout, formattedremainingTime, sessionExpired]);

  const desktopButtons = useMemo(() => {
    if (sessionExpired) {
      return [
        {
          children: LOGIN_TEXT,
          kind: DesktopButtonKind.LARGE,
          styleGuide: DesktopButtonStyleGuide.BRAND,
          onClick: handleClickLogin,
        },
      ];
    }

    return [
      {
        children: LOGOUT_TEXT,
        kind: DesktopButtonKind.LARGE,
        onClick: handleClickLogout,
      },
      {
        children: DISMISS_TEXT,
        kind: DesktopButtonKind.LARGE,
        styleGuide: DesktopButtonStyleGuide.GRADIENT,
        onClick: handleClickStay,
      },
    ];
  }, [handleClickLogin, handleClickLogout, handleClickStay, sessionExpired]);

  const mobileButtons = useMemo(() => {
    if (sessionExpired) {
      return [
        {
          children: LOGIN_TEXT,
          kind: MobileButtonKind.MAIN,
          styleGuide: MobileButtonStyleGuide.BRAND,
          onClick: handleClickLogin,
        },
      ];
    }

    return [
      {
        children: DISMISS_TEXT,
        kind: MobileButtonKind.MAIN,
        styleGuide: MobileButtonStyleGuide.BRAND,
        onClick: handleClickStay,
      },
      {
        children: LOGOUT_TEXT,
        kind: MobileButtonKind.MAIN,
        onClick: handleClickLogout,
        styleGuide: MobileButtonStyleGuide.DANGER,
      },
    ];
  }, [handleClickLogin, handleClickLogout, handleClickStay, sessionExpired]);

  if (isMobile) {
    return (
      <MobileConfirmationModal
        opened={opened}
        closeByClickOutside={false}
        timer={
          !sessionExpired
            ? {
                initial: refreshTokenLifetime,
                remaining: remainingTime,
              }
            : undefined
        }
        title={title}
        buttons={mobileButtons}
      >
        <MobileText kind={MobileTextKind.BODY_REGULAR}>
          {description}
        </MobileText>
      </MobileConfirmationModal>
    );
  }

  return (
    <StyledConfirmationModal
      withoutScrollableWrapper
      opened={opened}
      closeByClickOutside={false}
      timer={
        !sessionExpired
          ? {
              initial: refreshTokenLifetime,
              remaining: remainingTime,
            }
          : undefined
      }
      title={title}
      buttons={desktopButtons}
    >
      <DesktopText kind={DesktopTextKind.BODY_DEFAULT}>
        {description}
      </DesktopText>
    </StyledConfirmationModal>
  );
}
