import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IDeleteFactProps extends IBaseRequest {
  dto: {
    fact: string;
    data: boolean;
  };
  clientDraftId: string;
}

export async function deleteFact({
  api,
  dto,
  clientDraftId,
}: IDeleteFactProps): Promise<IBaseResponse<string>> {
  try {
    const response = await api.delete<IBaseResponse<string>>(
      `client_drafts/${clientDraftId}/delete_fact`,
      { data: dto },
    );

    return createSuccessResponse({
      result: response.data.result ?? '',
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
