import { IDraftReferences } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetDraftReferencesProps extends IBaseRequest {
  id: string;
  clientId: string;
  limit?: number;
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
}

export async function getDraftReferences({
  api,
  id,
  clientId,
  limit = 10,
  orderBy = 'created_at',
  orderDirection = 'desc',
}: IGetDraftReferencesProps): Promise<IDraftReferences> {
  const response = await api.get<IDraftReferences>(
    `clients/${clientId}/drafts/${id}/references`,
    {
      params: {
        limit,
        orderBy,
        orderDirection,
      },
    },
  );

  return response.data;
}
