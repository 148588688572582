import { Transaction } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IConfirmTransactionProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function confirmTransaction({
  api,
  clientId,
  id,
}: IConfirmTransactionProps): Promise<IBaseResponse<Transaction>> {
  try {
    const response = await api.post<Transaction>(
      `clients/${clientId}/display_transactions/${id}/confirm`,
    );

    return createSuccessResponse({ result: response.data });
  } catch (error) {
    return createErrorHandler(error);
  }
}
