import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { AuthMethod } from '@/entities';

export enum TFAErrors {
  INVALID_CODE = 'invalid_code',
}

interface ISendVerificationProps extends IBaseRequest {
  dto: {
    verificationCode: string;
    channel: AuthMethod;
  };
}

export async function verifyTfa({
  api,
  dto,
}: ISendVerificationProps): Promise<IBaseResponse<string>> {
  try {
    const response = await api.post<IBaseResponse<string>>(
      `user/verify_tfa`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
