import { argon2id } from 'hash-wasm';
import { FlexibleObject } from '@/entities';

/**
 * Generates a verification sign for the given data using Argon2id hashing.
 * @param {Object} data The data to be hashed.
 * @param {string} seed The seed for the hash generation.
 * @returns {Promise<string>} A promise that resolves to the generated verification sign.
 */
export function getVerificationSign(data: FlexibleObject, seed: string) {
  return argon2id({
    password: JSON.stringify(data),
    salt: seed,
    parallelism: 1,
    // The number of iterations
    iterations: 3,
    // Used memory, in KiB
    memorySize: 32768,
    // Desired hash length
    hashLength: 64,
  });
}
