import { ClientUserDetail } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetClientsUsersDetailsProps extends IBaseRequest {
  clientId: string;
  accountId: string;
}

export async function getClientsUsersDetails({
  api,
  clientId,
  accountId,
}: IGetClientsUsersDetailsProps): Promise<ClientUserDetail[]> {
  const response = await api.get<ClientUserDetail[]>(
    `clients/${clientId}/accounts/${accountId}/client_users_details`,
    {
      params: { clientId },
    },
  );

  return response.data;
}
