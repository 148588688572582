import { IContact, FlexibleObject } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRecentContactsProps extends IBaseRequest {
  clientId?: string;
  params?: FlexibleObject;
}

export async function getRecent({
  api,
  clientId,
  params,
}: IGetRecentContactsProps): Promise<IContact[]> {
  const response = await api.get<IContact[]>(
    `clients/${clientId}/contacts/recent`,
    {
      params,
    },
  );

  return response.data;
}
