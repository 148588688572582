import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

export async function deleteSession({
  api,
}: IBaseRequest): Promise<IBaseResponse<boolean>> {
  try {
    const response =
      await api.delete<IBaseResponse<IBaseResponse<unknown>>>('auth/sessions');

    return createSuccessResponse({
      result: response.data.success,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
