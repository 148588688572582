import { TariffGroup } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetTariffGroupProps extends IBaseRequest {
  clientDraftId: string;
}

export async function getTariffGroup({
  api,
  clientDraftId,
}: IGetTariffGroupProps): Promise<TariffGroup> {
  const response = await api.get<TariffGroup>(
    `client_drafts/${clientDraftId}/tariff_group`,
  );

  return response.data;
}
