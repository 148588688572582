import { IBaseRequest } from '@/api/types';

export interface IGetContentTypesParams {
  clientIds?: string;
  kind?: string;
}

interface IGetContentTypesProps extends IBaseRequest {
  params?: IGetContentTypesParams;
}

export interface IGetContentTypesResponse {
  contentTypes: string[];
}

export async function getContentTypes({
  api,
  params,
}: IGetContentTypesProps): Promise<IGetContentTypesResponse> {
  const response = await api.get<IGetContentTypesResponse>(
    `documents/content_types`,
    {
      params,
    },
  );

  return response.data;
}
