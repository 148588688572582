import { IBaseRequest } from '@/api/types';
import { UserSession } from '@/entities';
import { IGetUserSessionsParams } from '@/api/methods/auth/types.ts';

interface IGetUserSessionsProps extends IBaseRequest {
  params: IGetUserSessionsParams;
}

export async function getUserSessions({
  api,
  params,
}: IGetUserSessionsProps): Promise<UserSession[]> {
  const response = await api.get<UserSession[]>(`auth/user_sessions`, {
    params,
  });

  return response.data;
}
