import { IBaseRequest } from '@/api/types';
import { CurrenciesByCountry } from '@/entities';

interface IGetCurrenciesByCountryProps extends IBaseRequest {
  clientId: string;
}

export async function getCurrenciesByCountry({
  api,
  clientId,
}: IGetCurrenciesByCountryProps): Promise<CurrenciesByCountry[]> {
  const response = await api.get<CurrenciesByCountry[]>(
    `clients/${clientId}/payment_methods/currencies_by_countries`,
  );

  return response.data;
}
