import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconMail(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 20 21">
        <path
          d="M16.6667 3.83325H3.33341C2.41675 3.83325 1.67508 4.58325 1.67508 5.49992L1.66675 15.4999C1.66675 16.4166 2.41675 17.1666 3.33341 17.1666H16.6667C17.5834 17.1666 18.3334 16.4166 18.3334 15.4999V5.49992C18.3334 4.58325 17.5834 3.83325 16.6667 3.83325ZM16.3334 7.37492L10.4417 11.0583C10.1751 11.2249 9.82508 11.2249 9.55842 11.0583L3.66675 7.37492C3.45841 7.24159 3.33341 7.01659 3.33341 6.77492C3.33341 6.21659 3.94175 5.88325 4.41675 6.17492L10.0001 9.66658L15.5834 6.17492C16.0584 5.88325 16.6667 6.21659 16.6667 6.77492C16.6667 7.01659 16.5417 7.24159 16.3334 7.37492Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
