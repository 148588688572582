import { AuthMethod, Draft, Transaction } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IApproveDraftProps extends IBaseRequest {
  clientId: string;
  draftId: string;
  code: string;
  channel: AuthMethod;
}

export async function executeDraft({
  api,
  clientId,
  draftId,
  code,
  channel,
}: IApproveDraftProps): Promise<IBaseResponse<Transaction>> {
  try {
    const response = await api.post<IBaseResponse<Transaction>>(
      `clients/${clientId}/drafts/${draftId}/execute`,
      { verificationCode: code, channel },
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
