import { createContext, useContext } from 'react';
import { IThemeContext, Theme } from '@/ui/ThemeProvider/types';

export const ThemeContext = createContext<IThemeContext>({
  theme: Theme.LIGHT,
  switchTheme: () => {},
});

export function useTheme() {
  return useContext(ThemeContext);
}
