import { Invoice } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetInvoiceProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getInvoice({
  api,
  clientId,
  id,
}: IGetInvoiceProps): Promise<Invoice> {
  const response = await api.get<Invoice>(`clients/${clientId}/invoices/${id}`);

  return response.data;
}
