import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountStatementFormatAvailabilityProps extends IBaseRequest {
  clientId: string;
  accountId?: string;
  params?: {
    moneyDirection?: string;
    since?: number;
    till?: number;
  };
}

export async function getAccountStatementFormatAvailability({
  api,
  clientId,
  accountId,
  params,
}: IGetAccountStatementFormatAvailabilityProps) {
  const response = await api.get<string[]>(
    `clients/${clientId}/accounts/${accountId}/statement`,
    { params },
  );

  return response.data;
}
