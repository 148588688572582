import { IBaseRequest } from '@/api/types';
import { IContactPaymentMethodFields } from '@/entities';

interface IGetWisePaymentMethodFieldsProps extends IBaseRequest {
  receiverCurrency: string;
}

export async function getWisePaymentMethodFields({
  api,
  receiverCurrency,
}: IGetWisePaymentMethodFieldsProps): Promise<IContactPaymentMethodFields[]> {
  const response = await api.get<IContactPaymentMethodFields[]>(
    'wise/payment_methods/payment_method_fields',
    {
      params: { receiverCurrency },
    },
  );

  return response.data;
}
