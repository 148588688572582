import { useTheme } from '@/ui/ThemeProvider/useTheme';
import { useErrorFallback } from '../useErrorFallback';
import {
  ButtonsWrapper,
  ContentWrapper,
  Description,
  DownloadReportContainer,
  DownloadReportDescription,
  Header,
  Root,
  StyledImage,
  StyledLogo,
  SubTitle,
  Title,
  Wrapper,
} from './index.styles';
import { TextKind } from '@/ui/components/desktop/Text/types';
import {
  ButtonKind,
  ButtonStyleGuide,
} from '@/ui/components/desktop/Button/types';
import { IconRefresh } from '@/ui/components/desktop/Icon/variations/Refresh';
import { IconDownloadFile } from '@/ui/components/desktop/Icon/variations/DownloadFile';
import rockyErrorConfusionDay from '@/assets/png/rocky-error-confusion-day.png';
import rockyErrorConfusionNight from '@/assets/png/rocky-error-confusion-night.png';
import { Theme } from '@/ui/ThemeProvider/types';
import { Button } from '@/ui/components/desktop/Button';
import { IconNewIntercomChat } from '@/ui/components/desktop/Icon/variations/NewIntercomChat';
import { IconMail } from '@/ui/components/desktop/Icon/variations/Mail';
import { ParentBackground } from '@/ui/components/types/shared';

const ILLUSTRATION_MAP: Record<Theme, string> = {
  [Theme.LIGHT]: rockyErrorConfusionDay,
  [Theme.DARK]: rockyErrorConfusionNight,
};

export function ErrorFallback() {
  const {
    handleReloadPage,
    handleOpenChat,
    handleSendEmail,
    handleDownloadReport,
  } = useErrorFallback();
  const { theme } = useTheme();

  return (
    <Root>
      <Header>
        <StyledLogo onClick={handleReloadPage} />
      </Header>
      <Wrapper>
        <ContentWrapper>
          <Title kind={TextKind.TITLE_1_DEFAULT}>
            Oooops! Well, this is unexpected.
          </Title>
          <SubTitle kind={TextKind.BODY_DEFAULT}>
            An error has occurred, and we are working on fixing the problem.{' '}
          </SubTitle>
          <Description>
            Please try refreshing the page. If the error persists, contact our
            support team via chat or email with trace ID below.
          </Description>
          <ButtonsWrapper>
            <Button
              fullWidth
              icon={<IconRefresh />}
              onClick={handleReloadPage}
              styleGuide={ButtonStyleGuide.GRADIENT}
            >
              Reload page
            </Button>
            <Button icon={<IconNewIntercomChat />} onClick={handleOpenChat}>
              Chat
            </Button>
            <Button icon={<IconMail />} onClick={handleSendEmail}>
              Email
            </Button>
          </ButtonsWrapper>
          <DownloadReportContainer>
            <Button
              kind={ButtonKind.SMALL}
              icon={<IconDownloadFile />}
              parentBackground={ParentBackground.ONE}
              onClick={handleDownloadReport}
            >
              Copy device report
            </Button>
            <DownloadReportDescription kind={TextKind.FOOTNOTE_DEFAULT}>
              Copy your device data and submit it to the support. <br />
              We’ll fix everything faster and easier this way.
            </DownloadReportDescription>
          </DownloadReportContainer>
        </ContentWrapper>
        <StyledImage src={ILLUSTRATION_MAP[theme]} />
      </Wrapper>
    </Root>
  );
}
