import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IValidatePaymentMethodsProps extends IBaseRequest {
  clientId?: string;
  contactId?: string;
  contactPaymentMethodId?: string;
}

export async function validateAndFixPaymentMethod({
  api,
  clientId,
  contactId,
  contactPaymentMethodId,
}: IValidatePaymentMethodsProps): Promise<IBaseResponse<unknown>> {
  try {
    const response = await api.post<unknown>(
      `/clients/${clientId}/contacts/${contactId}/payment_methods/${contactPaymentMethodId}/validate_and_fix`,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
