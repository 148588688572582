import { Referral } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetReferralsProps extends IBaseRequest {
  params?: {
    orderBy?: string;
    orderDirection?: string;
  };
}

export async function getReferrals({
  api,
  params,
}: IGetReferralsProps): Promise<Referral[]> {
  const response = await api.get<Referral[]>(`user/referrals`, {
    params,
  });

  return response.data;
}
