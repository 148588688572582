import { Tariff } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetTariffProps extends IBaseRequest {
  clientId: string;
}

export async function getTariff({
  api,
  clientId,
}: IGetTariffProps): Promise<Tariff> {
  const response = await api.get<Tariff>(`clients/${clientId}/tariff`);

  return response.data;
}
