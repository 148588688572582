import { UserDocument } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetDocumentProps extends IBaseRequest {
  documentId?: string;
}

export async function getDocument({
  api,
  documentId,
}: IGetDocumentProps): Promise<UserDocument> {
  const response = await api.get<UserDocument>(`documents/${documentId}`);

  return response.data;
}
