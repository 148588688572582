import { AuthMethod, User } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IConfirmTfaSessionProps extends IBaseRequest {
  dto: {
    channel: AuthMethod;
    phone?: string;
    code: string;
  };
}

type IConfirmTfaSessionExtra = {
  token: string;
  refreshToken: string;
};

type IConfirmTfaSessionResponse = IBaseResponse<User> & IConfirmTfaSessionExtra;

export async function confirmTfaSession({
  api,
  dto,
}: IConfirmTfaSessionProps): Promise<
  IBaseResponse<User, IConfirmTfaSessionExtra>
> {
  try {
    const response = await api.post<IConfirmTfaSessionResponse>(
      'auth/tfa_sessions/confirm',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse<User, IConfirmTfaSessionExtra>({
        result: response.data.result,
        extra: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse<User, IConfirmTfaSessionExtra>({
      error: response.data.error,
      extra: {
        token: '',
        refreshToken: '',
      },
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
