import { ClassAttributes, ElementType, HTMLAttributes } from 'react';

export enum TextKind {
  AMOUNT_BOLD = 'amount-bold',
  AMOUNT_DEFAULT = 'amount_default',
  TITLE_H0 = 'title-h0',
  TITLE_H1 = 'title-h1',
  TITLE_H2 = 'title-h2',
  TITLE_H3 = 'title-h3',
  TITLE_H4 = 'title-h4',
  BODY_MEDIUM = 'body-medium',
  BODY_REGULAR = 'body-regular',
  BODY_SMALL = 'body-small',
  BODY_SMALL_SEMIBOLD = 'body-small-semibold',
  CAPTION_REGULAR = 'caption-regular',
  CAPTION_MEDIUM = 'caption-medium',
  CAPTION_AVATAR = 'caption-avatar',
  CAPTION_SMALL_SEMIBOLD = 'caption-small-semibold',
  CAPTION_SMALL = 'caption-small',
}

export interface ITextProps
  extends ClassAttributes<HTMLParagraphElement>,
    HTMLAttributes<HTMLParagraphElement> {
  as?: ElementType;
  kind?: TextKind | undefined;
  lineThrough?: boolean;
  ellipsis?: boolean;
}
