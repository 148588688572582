import styled from '@emotion/styled';
import { Gradient } from '@/ui/components/types/icon';
import { css } from '@emotion/react';

interface IWrapperStyleProps {
  color?: string;
  size?: string;
  width?: string;
  height?: string;
  gradient?: Gradient;
}

function getIconDimensions(
  size: string | undefined,
  width: string | undefined,
  height: string | undefined,
) {
  if (width && !height) {
    return css`
      --icon-width: ${width};
      --icon-height: auto;
    `;
  }

  if (!width && height) {
    return css`
      --icon-width: auto;
      --icon-height: ${height};
    `;
  }

  if (width && height) {
    return css`
      --icon-width: ${width};
      --icon-height: ${height};
    `;
  }

  if (size) {
    return css`
      --icon-width: ${size};
      --icon-height: ${size};
    `;
  }

  return css`
    --icon-width: 20px;
    --icon-height: 20px;
  `;
}

export const Wrapper = styled.div<IWrapperStyleProps>(
  ({
    size,
    width,
    height,
    color = 'var(--color-brand-default)',
    gradient,
  }) => css`
    ${getIconDimensions(size, width, height)}
    --icon-color: ${gradient ? 'white' : color};

    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: var(--icon-width);
    height: var(--icon-height);

    svg {
      color: var(--icon-color);
      fill: none;
    }
  `,
);

export const FullScreenWrapper = styled(Wrapper)<IWrapperStyleProps>(
  ({
    size = '80px',
    width,
    height,
    color = 'var(--color-label-primary)',
    gradient,
  }) => css`
    ${getIconDimensions(size, width, height)}
    --icon-color: ${gradient ? 'white' : color};

    position: fixed;
    display: inline-flex;
    width: 100%;
    height: 100vh;
    z-index: 4;
    background-color: var(--color-bg-two);

    svg {
      width: var(--icon-width);
      height: var(--icon-height);
    }
  `,
);
