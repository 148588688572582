import { useApi } from '@/api';
import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '@/config';
import { Loader } from '@/ui/components/Loader.tsx';

export function AuthChecking() {
  const { api, client } = useApi();
  const navigate = useNavigate();
  const location = useLocation();

  const redirectId = useMemo(
    () => new URLSearchParams(location.search).get('redirect_id') ?? '',
    [location.search],
  );

  const handleAuthRedirect = useCallback(async () => {
    if (!redirectId) {
      console.error('Redirect id param not found');
      return;
    }

    const response = await api.auth.getSessionRedirect({
      api: client,
      id: redirectId,
    });

    if (response.success) {
      localStorage.setItem(
        config.security.session.storageKeys.accessToken,
        response.extra?.token ?? '',
      );
      localStorage.setItem(
        config.security.session.storageKeys.refreshToken,
        response.extra?.refreshToken ?? '',
      );
      localStorage.setItem(
        config.security.session.storageKeys.lastClient,
        response.extra?.model.subjectId ?? '',
      );

      navigate('/');
    }
  }, [api.auth, client, navigate, redirectId]);

  useEffect(() => {
    if (location.pathname === '/redirect-mobapp') {
      handleAuthRedirect();
    }
  }, []);

  return <Loader />;
}
