import { IBaseRequest, IBaseSortable } from '@/api/types';

interface IGetPaymentMethodAccountsProps extends IBaseRequest, IBaseSortable {
  clientId: string;
}

export async function getPaymentKinds({
  api,
  clientId,
  orderBy,
  orderDirection,
}: IGetPaymentMethodAccountsProps): Promise<string[]> {
  const response = await api.get<string[]>(
    `clients/${clientId}/contacts/payment_kinds`,
    { params: { orderBy, orderDirection } },
  );

  return response.data;
}
