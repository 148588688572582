import { IBaseRequest } from '@/api/types';
import { FlexibleObject } from '@/entities';

interface ISettingsResult {
  settings: FlexibleObject;
}

export async function getSettings({
  api,
}: IBaseRequest): Promise<ISettingsResult> {
  const response = await api.get<ISettingsResult>(`user/settings`);

  return response.data;
}
