import { ReactNode } from 'react';
import { Global, css } from '@emotion/react';

import PoppinsThin from './Poppins/Poppins-Thin.ttf';
import PoppinsExtraLight from './Poppins/Poppins-ExtraLight.ttf';
import PoppinsLight from './Poppins/Poppins-Light.ttf';
import PoppinsRegular from './Poppins/Poppins-Regular.ttf';
import PoppinsMedium from './Poppins/Poppins-Medium.ttf';
import PoppinsSemiBold from './Poppins/Poppins-SemiBold.ttf';
import PoppinsBold from './Poppins/Poppins-Bold.ttf';
import PoppinsExtraBold from './Poppins/Poppins-ExtraBold.ttf';
import PoppinsBlack from './Poppins/Poppins-Black.ttf';

export const fontStyles = css`
  @font-face {
    font-family: 'Poppins';
    font-weight: 100;
    font-style: normal;
    src: url(${PoppinsThin}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 200;
    font-style: normal;
    src: url(${PoppinsExtraLight}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 300;
    font-style: normal;
    src: url(${PoppinsLight}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 400;
    font-style: normal;
    src: url(${PoppinsRegular}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 500;
    font-style: normal;
    src: url(${PoppinsMedium}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 600;
    font-style: normal;
    src: url(${PoppinsSemiBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 700;
    font-style: normal;
    src: url(${PoppinsBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 800;
    font-style: normal;
    src: url(${PoppinsExtraBold}) format('truetype');
  }

  @font-face {
    font-family: 'Poppins';
    font-weight: 900;
    font-style: normal;
    src: url(${PoppinsBlack}) format('truetype');
  }
`;

export function FontProvider({ children }: { children: ReactNode }) {
  return (
    <>
      <Global styles={fontStyles} />
      {children}
    </>
  );
}
