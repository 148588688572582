import { AidFlow } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAidFlowProps extends IBaseRequest {
  clientUserId: string;
}

export async function getAidVerifications({
  api,
  clientUserId,
}: IGetAidFlowProps): Promise<AidFlow[]> {
  const response = await api.get<AidFlow[]>(
    `user/client_users/${clientUserId}/aid_verifications`,
  );

  return response.data;
}
