import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface ISetFactProps extends IBaseRequest {
  dto: {
    fact: string;
    data: boolean;
  };
  clientDraftId: string;
}

export async function setFact({
  api,
  dto,
  clientDraftId,
}: ISetFactProps): Promise<IBaseResponse<string>> {
  try {
    const response = await api.post<IBaseResponse<string>>(
      `client_drafts/${clientDraftId}/set_fact`,
      dto,
    );

    return createSuccessResponse({
      result: response.data.result ?? '',
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
