import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { ParentBackground } from '../../types/shared';
import { getToastMessage } from './getToastMessage';
import {
  IToastMessageConfigProps,
  IUseToastNotificationsProps,
  ToastMessageKind,
} from '../../types/toastNotification';

export function useToastNotifications(
  config: IToastMessageConfigProps = {
    autoClose: 5000,
    hideProgressBar: false,
    parentBackground: ParentBackground.ONE,
  },
): IUseToastNotificationsProps {
  const handleInfo = useCallback(
    (message: string) => {
      toast.info(
        getToastMessage({
          kind: ToastMessageKind.BRAND,
          message,
          parentBackground: config.parentBackground,
          customButton: config.customButton,
        }),
        {
          progressClassName: 'brand',
          autoClose: config.autoClose,
          hideProgressBar: config.hideProgressBar,
          closeButton: false,
        },
      );
    },
    [config],
  );

  const handleWaiting = useCallback(
    (message: string) => {
      toast.warning(
        getToastMessage({
          kind: ToastMessageKind.WAITING,
          message,
          parentBackground: config.parentBackground,
          customButton: config.customButton,
        }),
        {
          progressClassName: ToastMessageKind.WAITING,
          autoClose: config.autoClose,
          hideProgressBar: config.hideProgressBar,
          closeButton: false,
        },
      );
    },
    [config],
  );

  const handleFailure = useCallback(
    (message: string) => {
      toast.error(
        getToastMessage({
          kind: ToastMessageKind.DANGER,
          message,
          parentBackground: config.parentBackground,
          customButton: config.customButton,
        }),
        {
          progressClassName: ToastMessageKind.DANGER,
          autoClose: config.autoClose,
          hideProgressBar: config.hideProgressBar,
          closeButton: false,
        },
      );
    },
    [config],
  );

  const handleSuccess = useCallback(
    (message: string) => {
      toast.success(
        getToastMessage({
          kind: ToastMessageKind.SUCCESS,
          message,
          parentBackground: config.parentBackground,
          customButton: config.customButton,
        }),
        {
          progressClassName: ToastMessageKind.SUCCESS,
          autoClose: config.autoClose,
          hideProgressBar: config.hideProgressBar,
          closeButton: false,
        },
      );
    },
    [config],
  );

  return {
    handleSuccess,
    handleFailure,
    handleInfo,
    handleWaiting,
  };
}
