import { IBaseRequest } from '@/api/types';
import { UnifiedAccount } from '@/entities';

export async function getAccounts({
  api,
}: IBaseRequest): Promise<UnifiedAccount[]> {
  const response = await api.get<UnifiedAccount[]>('accounts');

  return response.data;
}
