import { IBaseRequest } from '@/api/types';
import { TariffQuestion } from '@/entities';

export async function getTariffQuestions({
  api,
}: IBaseRequest): Promise<TariffQuestion[]> {
  const response = await api.get<TariffQuestion[]>(`tariff_questions`);

  return response.data;
}
