import { useResolution } from '@/device/useResolution.ts';
import {
  ButtonKind as DesktopButtonKind,
  ButtonStyleGuide as DesktopButtonStyleGuide,
} from '@/ui/components/desktop/Button/types';
import {
  ButtonKind as MobileButtonKind,
  ButtonStyleGuide as MobileButtonStyleGuide,
} from '@/ui/components/mobile/Button/types';
import { ConfirmationModal as DesktopConfirmationModal } from '@/ui/components/desktop/ConfirmationModal';
import { ConfirmationModal as MobileConfirmationModal } from '@/ui/components/mobile/ConfirmationModal';

export interface ILogoutConfirmationModalProps {
  opened: boolean;
  close: () => void;
  onLogout: () => void;
}

const TITLE = 'Are you sure you want to log out?';

export function LogoutConfirmationModal({
  opened,
  close,
  onLogout,
}: ILogoutConfirmationModalProps) {
  const { isMobile } = useResolution();

  if (isMobile) {
    return (
      <MobileConfirmationModal
        opened={opened}
        title={TITLE}
        buttons={[
          {
            children: 'Back to application',
            kind: MobileButtonKind.MAIN,
            onClick: close,
          },
          {
            children: 'Log out',
            kind: MobileButtonKind.MAIN,
            styleGuide: MobileButtonStyleGuide.DANGER,
            onClick: onLogout,
          },
        ]}
      />
    );
  }

  return (
    <DesktopConfirmationModal
      opened={opened}
      title={TITLE}
      buttons={[
        {
          children: 'Back to application',
          kind: DesktopButtonKind.LARGE,
          onClick: close,
        },
        {
          children: 'Log out',
          kind: DesktopButtonKind.LARGE,
          styleGuide: DesktopButtonStyleGuide.GRADIENT,
          onClick: onLogout,
        },
      ]}
      close={close}
    />
  );
}
