import { IAddMoneySubmission } from '@/entities';
import { IBaseRequest } from '@/api/types';
import { IGetAddMoneySubmissionsParams } from '@/api/methods/addMoneySubmissions/types.ts';

interface IGetAddMoneySubmissionProps extends IBaseRequest {
  clientId: string;
  id: string;
  params?: IGetAddMoneySubmissionsParams;
}

export async function getAddMoneySubmission({
  api,
  clientId,
  params,
  id,
}: IGetAddMoneySubmissionProps): Promise<IAddMoneySubmission> {
  const response = await api.get<IAddMoneySubmission>(
    `clients/${clientId}/add_money_submissions/${id}`,
    { params },
  );

  return response.data;
}
