import { UserClientUser } from '@/entities';
import { IBaseRequest } from '@/api/types';

export async function getUserClientUsers({
  api,
}: IBaseRequest): Promise<UserClientUser[]> {
  const response = await api.get<UserClientUser[]>('user/client_users');

  return response.data;
}
