import { type AxiosError, AxiosInstance } from 'axios';

export interface IBaseRequest {
  api: AxiosInstance;
}

export interface IBaseSortable {
  orderBy?: string;
  orderDirection?: 'asc' | 'desc';
}

export interface IBaseResponseMeta {
  offset?: number;
  limit?: number;
  total?: number;
}

export type IBaseCatchableError = unknown | AxiosError;

export interface IBaseErrors {
  [key: string]: string;
}

export enum ApiErrorCodes {
  CLIENT_GENERAL_ERROR = 'CLIENT_GENERAL_ERROR',
  API_INTERNAL_SERVER_ERROR = 'API_INTERNAL_SERVER_ERROR', // 500
  API_UNAUTHORIZED = 'API_UNAUTHORIZED', // 401
  API_FORBIDDEN = 'API_FORBIDDEN', // 403
  API_VALIDATION_ERROR = 'API_VALIDATION_ERROR', // 400 or success: false
  API_RATE_LIMIT_ERROR = 'API_RATE_LIMIT_ERROR', // 429
}

export interface IBaseSuccessResponse<T, E> {
  success: true;
  code?: string;
  errors?: IBaseErrors;
  error?: string;
  result: T;
  data?: T;
  meta?: IBaseResponseMeta;
  extra?: E;
}

export interface IBaseErrorResponse<T, E> {
  success: false;
  code: ApiErrorCodes;
  errors?: IBaseErrors;
  error?: string;
  result?: T;
  meta?: IBaseResponseMeta;
  extra?: E;
  data?: T;
}

export type IBaseResponse<T, E = unknown> =
  | IBaseSuccessResponse<T, E>
  | IBaseErrorResponse<T, E>;
