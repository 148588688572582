import { PropsWithChildren, Suspense } from 'react';
import { ToastContainer } from 'react-toastify';
import { MainAppContainer } from './index.styles';
import { Loader } from '@/ui/components/Loader.tsx';

export function AppContainer({ children }: PropsWithChildren<unknown>) {
  return (
    <>
      <ToastContainer />
      <MainAppContainer>
        <Suspense fallback={<Loader />}>{children}</Suspense>
      </MainAppContainer>
    </>
  );
}
