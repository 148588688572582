import { IBaseRequest, IBaseSortable } from '@/api/types';
import { DirectoryItems } from '@/entities/DirectoryItems';

interface IGetDirectoryItemsProps extends IBaseRequest, IBaseSortable {
  kinds?: string;
}

export async function getDirectoryItems({
  api,
  kinds,
}: IGetDirectoryItemsProps): Promise<DirectoryItems> {
  const response = await api.get<DirectoryItems>('directory_items/bulk', {
    params: {
      kinds,
    },
  });

  return response.data;
}
