import { useDeviceReport } from '@/device/useDeviceReport';
import { useCallback } from 'react';

export function useErrorFallback() {
  const { handleDownloadReport } = useDeviceReport();

  const handleReloadPage = useCallback(async () => {
    window.location.reload();
  }, []);

  const handleOpenChat = useCallback(async () => {
    // TODO: Implement open chat function
  }, []);

  const handleSendEmail = useCallback(async () => {
    // TODO: Implement email send function
  }, []);

  return {
    handleReloadPage,
    handleOpenChat,
    handleSendEmail,
    handleDownloadReport,
  };
}
