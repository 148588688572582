import { Invoice } from '@/entities';
import { IBaseRequest } from '@/api/types';
import { IGetInvoicesParams } from '@/api/methods/invoices/types.ts';

interface IGetInvoicesProps extends IBaseRequest {
  clientId: string;
  params?: IGetInvoicesParams;
}

export async function getInvoices({
  api,
  clientId,
  params,
}: IGetInvoicesProps): Promise<Invoice[]> {
  const response = await api.get<Invoice[]>(`clients/${clientId}/invoices`, {
    params,
  });

  return response.data;
}
