import { useKey } from 'react-use';
import { IConfirmationModalProps } from '@/ui/components/desktop/ConfirmationModal/types.ts';
import { createPortal } from 'react-dom';
import {
  ButtonsWrapper,
  ChildrenWrapper,
  ModalWrapper,
  ScrollableWrapper,
  TimeoutIndicator,
  TimeoutIndicatorWrapper,
  TitleWrapper,
} from '@/ui/components/desktop/ConfirmationModal/index.styles.ts';
import { TextOrChildren } from '../Text';
import { TextKind } from '../Text/types';
import { Modal } from '@/ui/components/desktop/Modal';
import { getPercentage } from '@/functions/utils/getPercentage.ts';
import { useMemo } from 'react';
import { Button } from '@/ui/components/desktop/Button';
import { ButtonKind } from '@/ui/components/desktop/Button/types.ts';

export function ConfirmationModal({
  opened,
  icon,
  title,
  timer,
  children,
  className,
  closeByEscape = true,
  closeByClickOutside,
  modalStyles,
  buttons,
  withoutScrollableWrapper,
  contentStyles,
  close,
}: IConfirmationModalProps) {
  useKey('Escape', closeByEscape ? close : undefined);

  const renderedButtons = useMemo(
    () =>
      buttons?.map((props, index) => (
        <Button key={index} kind={ButtonKind.LARGE} {...props} />
      )),
    [buttons],
  );

  const renderedContent = useMemo(() => {
    if (children) {
      if (withoutScrollableWrapper) {
        return (
          <ChildrenWrapper style={contentStyles}>{children}</ChildrenWrapper>
        );
      }

      return (
        <ScrollableWrapper style={contentStyles}>{children}</ScrollableWrapper>
      );
    }
  }, [children, contentStyles, withoutScrollableWrapper]);

  return createPortal(
    <Modal
      opened={opened}
      close={close}
      closeByClickOutside={closeByClickOutside}
    >
      <ModalWrapper className={className} style={modalStyles}>
        {timer && (
          <TimeoutIndicatorWrapper>
            <TimeoutIndicator
              style={{
                width: `${getPercentage(timer.remaining, timer.initial)}%`,
              }}
            />
          </TimeoutIndicatorWrapper>
        )}
        {(title || icon) && (
          <TitleWrapper>
            {icon}
            <TextOrChildren kind={TextKind.TITLE_2_DEFAULT}>
              {title}
            </TextOrChildren>
          </TitleWrapper>
        )}
        {renderedContent}
        {buttons && <ButtonsWrapper>{renderedButtons}</ButtonsWrapper>}
      </ModalWrapper>
    </Modal>,
    document.body,
  );
}
