import { useContext } from 'react';
import {
  AppAuthContext,
  IAppAuthContext,
} from '@/auth/AuthorizationProvider.tsx';

export function useAuth() {
  const value = useContext(AppAuthContext);

  return value as IAppAuthContext;
}
