import { IBaseRequest } from '@/api/types';
import { ClientAverageBalanceDetails } from '@/entities';

interface IGetAverageBalanceDetailsProps extends IBaseRequest {
  clientId?: string;
}

export async function getAverageBalanceDetails({
  api,
  clientId,
}: IGetAverageBalanceDetailsProps): Promise<ClientAverageBalanceDetails> {
  const response = await api.get<ClientAverageBalanceDetails>(
    `clients/${clientId}/avg_balance_details`,
  );

  return response.data;
}
