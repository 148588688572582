import { Country } from '@/entities';
import { IBaseRequest, IBaseSortable } from '@/api/types';

interface IGetContactCountriesProps extends IBaseRequest {
  clientId?: string;
  params: IBaseSortable;
}

export async function getCountries({
  api,
  clientId,
  params,
}: IGetContactCountriesProps): Promise<Country[]> {
  const response = await api.get<Country[]>(
    `clients/${clientId}/contacts/countries`,
    {
      params,
    },
  );

  return response.data;
}
