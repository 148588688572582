import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountBankLetterPdfProps extends IBaseRequest {
  clientId: string;
  id: string;
  params: {
    pdfDisplayName: string;
    pdfFilename: string;
    paymentClientAccountId?: string;
    includeBalance?: boolean;
    includeDate?: boolean;
  };
}

interface IGetAccountBankLetterPdfResponse {
  clientId: string;
  createdAt: number;
  id: string;
  state: DocumentState;
  updatedAt: number;
  userId: string;
}

export async function getAccountBankLetterPdf({
  api,
  clientId,
  id,
  params,
}: IGetAccountBankLetterPdfProps) {
  const response = await api.get<IGetAccountBankLetterPdfResponse>(
    `clients/${clientId}/accounts/${id}/bank_letter/pdf`,
    { params },
  );

  return response.data;
}
