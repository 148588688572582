import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { browserVersion, isSafari } from 'react-device-detect';
import { css } from '@emotion/react';
import { Modal } from '@/ui/components/mobile/Modal';
import { Orientation } from '@/ui/components/types/shared.ts';
import { TextOrChildren } from '@/ui/components/mobile/Text';

const isModernSafari = isSafari && Number(browserVersion) >= 12;

interface ITimeoutIndicatorStyleProps {
  widthPercent: number;
}

interface IButtonsWrapperStyleProps {
  orientation?: Orientation;
}

export const TimeoutIndicatorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--color-brand-additional-on-bg-two);
`;

export const TimeoutIndicator = styled.div<ITimeoutIndicatorStyleProps>`
  width: ${({ widthPercent }) => `${widthPercent}%` || 0};
  height: 4px;
  border-radius: 4px;
  background-color: var(--color-brand-default);
  transition: width 0.6s cubic-bezier(0.16, 1, 0.3, 1);
  will-change: width;
`;

export const StyledModal = styled(Modal)`
  align-items: end;
`;

export const ModalWrapper = styled(motion.div)`
  --scrollbar-width: 4px;
  --wrapper-padding: 16px;

  position: relative;
  display: grid;
  grid-row-gap: 20px;
  width: 100%;
  margin: 16px;
  padding: var(--wrapper-padding)
    calc(var(--wrapper-padding) / 2 - var(--scrollbar-width) * 2)
    var(--wrapper-padding) var(--wrapper-padding);
  overflow: hidden;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--color-bg-two);
  box-shadow: 0 4px 28px rgba(10, 12, 27, 0.1);
  pointer-events: all;
  box-sizing: border-box;
`;

export const ScrollableWrapper = styled.div`
  width: 100%;
  max-height: calc(80vh - 96px);
  padding-right: calc(var(--wrapper-padding) / 2 + var(--scrollbar-width) * 2);
  overflow-y: auto;
  box-sizing: border-box;

  @supports (scrollbar-gutter: stable both-edges) {
    scrollbar-gutter: stable both-edges;
    margin-left: calc(var(--scrollbar-width) * -1);
    padding-right: calc(var(--wrapper-padding) / 2);
  }

  @supports (-moz-appearance: none) {
    margin-left: 0;
    padding-right: calc(
      var(--wrapper-padding) / 2 + var(--scrollbar-width) * 2
    );
  }

  ${!isModernSafari &&
  css`
    &::-webkit-scrollbar {
      width: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-track {
      background: var(--color-label-quaternary);
      border-radius: var(--scrollbar-width);
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color-label-tertiary);
      border: none;
      border-radius: var(--scrollbar-width);
    }
  `}
`;

export const TitleWrapper = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-right: calc(var(--wrapper-padding) / 2 + var(--scrollbar-width) * 2);
`;

export const Title = styled(TextOrChildren)`
  text-align: center;
`;

export const ButtonsWrapper = styled.div<IButtonsWrapperStyleProps>`
  display: grid;
  grid-auto-flow: ${({ orientation = Orientation.VERTICAL }) =>
    orientation === Orientation.HORIZONTAL ? 'column' : 'row'};
  grid-auto-columns: 1fr;
  grid-row-gap: 10px;
  grid-column-gap: 12px;
  align-items: center;
  width: 100%;
  padding-right: calc(var(--wrapper-padding) / 2 + var(--scrollbar-width) * 2);

  & > button {
    width: 100%;
  }
`;
