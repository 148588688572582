import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { IWebAuthnCredential } from '@/entities/WebAuthn';
import { RegistrationPublicKeyCredential } from '@github/webauthn-json/browser-ponyfill';

interface ICreateWebAuthnCredentialProps extends IBaseRequest {
  dto: {
    displayName: string;
    credential: RegistrationPublicKeyCredential;
  };
}

export async function createWebAuthnCredential({
  api,
  dto,
}: ICreateWebAuthnCredentialProps): Promise<
  IBaseResponse<IWebAuthnCredential>
> {
  try {
    const response = await api.post<IBaseResponse<IWebAuthnCredential>>(
      'auth/webauthn_credentials',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
