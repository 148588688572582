import { Account } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAvailableAccountByPublicNoProps extends IBaseRequest {
  no: string;
  clientId: string;
}

export async function getAvailableAccountByPublicNo({
  api,
  no,
  clientId,
}: IGetAvailableAccountByPublicNoProps): Promise<Account[]> {
  const response = await api.get<Account[]>(
    `/clients/${clientId}/client_accounts/public/${no}/client_accounts`,
  );

  return response.data;
}
