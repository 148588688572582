import { PublicLinkData } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPublicLinkProps extends IBaseRequest {
  id: string;
}

export async function getPublicLink({
  api,
  id,
}: IGetPublicLinkProps): Promise<PublicLinkData> {
  const response = await api.get<PublicLinkData>(`public_links/${id}`);

  return response.data;
}
