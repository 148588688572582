export enum ParentBackground {
  ONE = 'one',
  TWO = 'two',
}

export const InvertedParentBackground = {
  [ParentBackground.ONE]: ParentBackground.TWO,
  [ParentBackground.TWO]: ParentBackground.ONE,
};

export enum Direction {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
}

export enum Orientation {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

export type OptionWithId<T> = T & { id: string };

export interface ISelectOption<D> {
  label: string;
  value: string;
  data?: D;
  disabled?: boolean;
}
