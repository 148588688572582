import { User } from '@/entities';
import { IBaseRequest } from '@/api/types';
import { createErrorHandler } from '@/api/helpers';

interface IRegistrationProps<T> extends IBaseRequest {
  dto: T;
}

type ICreateRegistrationExtra = {
  token: string;
  refreshToken: string;
};

interface IBaseCreateRegistrationResponse {
  success: boolean;
  result: User;
  // TODO: standardtization of error format on the backend
  error: Record<string, string[]>;
}

type ICreateRegistrationResponse = IBaseCreateRegistrationResponse &
  ICreateRegistrationExtra;

export async function createRegistration<T>({
  api,
  dto,
}: IRegistrationProps<T>) {
  try {
    const response = await api.post<ICreateRegistrationResponse>(
      'auth/registration',
      dto,
    );

    if (response.data.success) {
      return {
        success: true,
        result: response.data.result,
        extra: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      };
    }

    return {
      success: false,
      result: undefined,
      error: response.data.error,
      extra: {
        token: '',
        refreshToken: '',
      },
    };
  } catch (error) {
    return createErrorHandler<unknown, ICreateRegistrationExtra>(error);
  }
}
