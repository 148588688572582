import { AvailableCountry } from '@/entities';
import { IBaseRequest, IBaseSortable } from '@/api/types';

interface IGetCountriesProps extends IBaseRequest, IBaseSortable {
  clientKind?: string;
  countryKind?: string;
}

export async function getCountries({
  api,
  clientKind,
  countryKind,
  orderBy,
  orderDirection,
}: IGetCountriesProps): Promise<AvailableCountry[]> {
  const response = await api.get<AvailableCountry[]>('countries', {
    params: {
      clientKind,
      countryKind,
      orderBy,
      orderDirection,
    },
  });

  return response.data;
}
