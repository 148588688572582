import styled from '@emotion/styled';
import { Image } from '@/ui/components/desktop/Image';
import { Text } from '@/ui/components/desktop/Text';
import { getBreakpoint } from '@/functions/utils/getBreakpoint';
import { Logo } from '@/ui/components/desktop/Icon/variations/Logo';

export const StyledLogo = styled(Logo)`
  cursor: pointer;
`;

export const Root = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  background-color: var(--color-bg-one);
  padding: 32px;
`;

export const Header = styled.div`
  width: 100%;
  max-width: var(--desktop-content-max-width);
  margin: 0 auto;
`;

export const Wrapper = styled.div`
  --image-size: 400px;

  width: 100%;
  height: 100%;
  max-width: var(--desktop-content-max-width);
  display: grid;
  grid-template-areas: 'content image';
  grid-auto-flow: column;
  grid-template-columns: auto auto;
  grid-column-gap: 200px;
  align-items: center;
  margin: 0 auto;
  padding: 120px 0;

  @media (max-width: ${getBreakpoint('DESKTOP_M')}) {
    grid-column-gap: 248px;
  }

  @media (max-width: ${getBreakpoint('DESKTOP_S')}) {
    grid-column-gap: 88px;
  }
  @media (max-width: ${getBreakpoint('TABLET_L')}) {
    grid-column-gap: 48px;
  }
`;

export const ContentWrapper = styled.div`
  width: 520px;
  grid-area: content;
`;

export const Title = styled(Text)`
  color: var(--color-label-primary);
`;

export const SubTitle = styled(Text)`
  margin-top: 32px;
  color: var(--color-label-secondary);
`;

export const Description = styled(Text)`
  margin-top: 24px;
  color: var(--color-label-secondary);
`;

export const DownloadReportDescription = styled(Text)`
  margin-top: 12px;
  color: var(--color-label-secondary);
`;

export const ButtonsWrapper = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  grid-column-gap: 14px;
  width: 100%;
`;

export const DownloadReportContainer = styled.div`
  margin-top: 32px;
`;

export const StyledImage = styled(Image)`
  width: var(--image-size);
  height: var(--image-size);
  grid-area: image;
`;
