import { DocumentState } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ICreateAccountsMonthlyStatementsPdf extends IBaseRequest {
  clientId: string;
  dto?: {
    accountId?: string;
    dates?: {
      since?: number;
      till?: number;
    }[];
  }[];
}

interface ICreateAccountsMonthlyStatementsPdfResponse {
  id: string;
  createdAt: number;
  documentIds: Array<string>;
  state: string;
  totalCount: number;
  updatedAt: number;
}

export async function createAccountsMonthlyStatementsPdf({
  api,
  clientId,
  dto,
}: ICreateAccountsMonthlyStatementsPdf): Promise<
  IBaseResponse<ICreateAccountsMonthlyStatementsPdfResponse>
> {
  try {
    const response = await api.post<
      IBaseResponse<ICreateAccountsMonthlyStatementsPdfResponse>
    >(`clients/${clientId}/client_accounts/statement/bulk/pdf`, {
      payload: dto,
    });

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
