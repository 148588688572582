import { createContext, ReactNode } from 'react';
import { useAuthorization } from './useAuthorization';
import { IUseTokensExport } from './useTokens';
import { SessionTimeoutModal } from './SessionTimoutModal';
import { LogoutConfirmationModal } from './LogoutConfirmationModal';
import { AuthorizedState, ILogout } from './types';

export type IAppAuthContext = {
  status: AuthorizedState;
  authTokenLogic: IUseTokensExport;
  logout: ILogout;
  checkAuthorization: () => void;
};

export const AppAuthContext = createContext<IAppAuthContext>({
  status: AuthorizedState.FETCHING,
  authTokenLogic: {
    handleSetAuthHeader: () => {},
    handleSetTokens: () => {},
    handleClearTokens: () => {},
    accessToken: '',
    refreshToken: '',
  },
  checkAuthorization: () => {},
  logout: {
    popup: {
      opened: false,
      toggle: () => {},
      close: () => {},
      open: () => {},
    },
    process: () => Promise.resolve(),
  },
});

type IAuthorizationProviderProps = {
  children: ReactNode;
  tooManyRequestsUrl?: string;
};

export function AuthorizationProvider({
  children,
}: IAuthorizationProviderProps) {
  const {
    authorized,
    authTokenLogic,
    session,
    logout,
    forceCheckAuthorization,
  } = useAuthorization();

  return (
    <AppAuthContext.Provider
      value={{
        status: authorized,
        checkAuthorization: forceCheckAuthorization,
        authTokenLogic,
        logout,
      }}
    >
      {children}
      <SessionTimeoutModal
        opened={session.timeoutSessionPopup.opened}
        sessionExpired={session.sessionExpired}
        sessionTimeout={session.sessionTimeout}
        remainingTime={session.remainingTime}
        clearTracking={session.clearTracking}
        enableAutoRefresh={session.enableAutoRefresh}
        makeRefreshIfNeeded={session.makeRefreshIfNeeded}
        onClose={session.timeoutSessionPopup.close}
      />
      <LogoutConfirmationModal
        opened={logout.popup.opened}
        close={logout.popup.close}
        onLogout={logout.process}
      />
    </AppAuthContext.Provider>
  );
}
