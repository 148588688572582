import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ISetNewPasswordProps extends IBaseRequest {
  hash: string;
  dto: {
    password: string;
    passwordConfirmation: string;
  };
}

export async function setNewPassword({
  api,
  hash,
  dto,
}: ISetNewPasswordProps): Promise<IBaseResponse<null>> {
  try {
    const response = await api.put<IBaseResponse<string>>(
      `auth/password_resets/${hash}`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: null,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
