import { IAddMoneySubmission } from '@/entities';
import { IBaseRequest } from '@/api/types';
import { IGetAddMoneySubmissionsParams } from '@/api/methods/addMoneySubmissions/types.ts';

interface IGetAddMoneySubmissionsProps extends IBaseRequest {
  clientId: string;
  params?: IGetAddMoneySubmissionsParams;
}

export async function getAddMoneySubmissions({
  api,
  clientId,
  params,
}: IGetAddMoneySubmissionsProps): Promise<IAddMoneySubmission[]> {
  const response = await api.get<IAddMoneySubmission[]>(
    `clients/${clientId}/add_money_submissions`,
    { params },
  );

  return response.data;
}
