import { IContact, IContactDetail } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ICreatePaymentMethodsProps extends IBaseRequest {
  clientId?: string;
  id: string;
  dto: {
    contactPaymentMethods: {
      id?: string;
      paymentKind: string;
      details: IContactDetail[];
    }[];
  };
}

export async function createPaymentMethods({
  api,
  clientId,
  id,
  dto,
}: ICreatePaymentMethodsProps): Promise<IBaseResponse<IContact>> {
  try {
    const response = await api.post<IBaseResponse<IContact>>(
      `clients/${clientId}/contacts/${id}/payment_methods/bulk`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
