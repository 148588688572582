import { IQRCodeData } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetQRLoginProps extends IBaseRequest {
  id: string;
}

export async function getQRLogin({
  api,
  id,
}: IGetQRLoginProps): Promise<IQRCodeData> {
  const response = await api.get<IQRCodeData>(`auth/qr/${id}`);

  return response.data;
}
