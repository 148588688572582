import { IBaseRequest } from '@/api/types';

interface IGetDocumentsKindsProps extends IBaseRequest {
  clientId?: string;
}

export async function getDocumentsKinds({
  api,
  clientId,
}: IGetDocumentsKindsProps): Promise<string[]> {
  const response = await api.get<string[]>(`documents/document_kinds`, {
    params: {
      clientId,
    },
  });

  return response.data;
}
