import styled from '@emotion/styled';
import { SVGContainer } from '@/ui/components/mobile/Icon/shared';

export const StyledSVGContainer = styled(SVGContainer)`
  overflow: hidden;
  border-radius: 50%;
`;

export const AnimatedGroup = styled.g`
  @keyframes move {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-584%);
    }
  }

  animation: move 4s infinite linear;
`;

export const AnimatedPath = styled.path`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  transform-origin: center;
  animation: rotate 0.75s infinite linear;
`;
