import { css } from '@emotion/react';

export const darkColors = css`
  --color-recipient-avatar5: var(--color-recipient-avatar5-night);
  --color-recipient-avatar6: var(--color-recipient-avatar6-night);
  --color-recipient-avatar2: var(--color-recipient-avatar2-night);
  --color-recipient-avatar8: var(--color-recipient-avatar8-night);
  --color-recipient-avatar3: var(--color-recipient-avatar3-night);
  --color-recipient-avatar7: var(--color-recipient-avatar7-night);
  --color-recipient-avatar4: var(--color-recipient-avatar4-night);
  --color-recipient-avatar1: var(--color-recipient-avatar1-night);
  --color-chart-purpose-1: var(--color-chart-purpose-1-night);
  --color-chart-purpose-2: var(--color-chart-purpose-2-night);
  --color-chart-purpose-3: var(--color-chart-purpose-3-night);
  --color-chart-purpose-4: var(--color-chart-purpose-4-night);
  --color-chart-purpose-5: var(--color-chart-purpose-5-night);
  --color-chart-purpose-6: var(--color-chart-purpose-6-night);
  --color-chart-purpose-7: var(--color-chart-purpose-7-night);
  --color-chart-purpose-8: var(--color-chart-purpose-8-night);
  --color-chart-most-sent-1: var(--color-chart-most-sent-1-night);
  --color-chart-most-sent-2: var(--color-chart-most-sent-2-night);
  --color-chart-most-sent-3: var(--color-chart-most-sent-3-night);
  --color-chart-most-sent-4: var(--color-chart-most-sent-4-night);
  --color-chart-most-sent-5: var(--color-chart-most-sent-5-night);
  --color-chart-most-sent-6: var(--color-chart-most-sent-6-night);
  --color-chart-most-sent-7: var(--color-chart-most-sent-7-night);
  --color-chart-most-received-1: var(--color-chart-most-received-1-night);
  --color-chart-most-received-2: var(--color-chart-most-received-2-night);
  --color-chart-most-received-3: var(--color-chart-most-received-3-night);
  --color-chart-most-received-4: var(--color-chart-most-received-4-night);
  --color-chart-most-received-5: var(--color-chart-most-received-5-night);
  --color-chart-most-received-6: var(--color-chart-most-received-6-night);
  --color-chart-most-received-7: var(--color-chart-most-received-7-night);
  --color-gradient-mobile-default: var(--color-gradient-mobile-default-night);
  --color-gradient-pink-purple: var(--color-gradient-pink-purple-night);
  --color-gradient-blue-cian: var(--color-gradient-blue-cian-night);
  --color-gradient-horizontal-not-intense: var(
    --color-gradient-horizontal-not-intense-night
  );
  --color-gradient-pink-purple-blue: var(
    --color-gradient-pink-purple-blue-night
  );
  --color-gradient-vertical-not-intense: var(
    --color-gradient-vertical-not-intense-night
  );
  --color-gradient-orange-red: var(--color-gradient-orange-red-night);
  --color-gradient-blue-pink: var(--color-gradient-blue-pink-night);
  --color-gradient-default: var(--color-gradient-default-night);
  --color-gradient-cian-pink: var(--color-gradient-cian-pink-night);
  --color-gradient-green-purple: var(--color-gradient-green-purple-night);
  --color-gradient-default-for-website: var(
    --color-gradient-default-for-website-night
  );
  --color-gradient-pink-peach: var(--color-gradient-pink-peach-night);
  --color-gradient-blue-green: var(--color-gradient-blue-green-night);
  --color-pressed-success-surface-on-bg-two: var(
    --color-pressed-success-surface-on-bg-two-night
  );
  --color-hover-success-surface-on-bg-one: var(
    --color-hover-success-surface-on-bg-one-night
  );
  --color-hover-success-surface-on-bg-two: var(
    --color-hover-success-surface-on-bg-two-night
  );
  --color-pressed-successr-surface-on-bg-one: var(
    --color-pressed-successr-surface-on-bg-one-night
  );
  --color-label-tertiary: var(--color-label-tertiary-night);
  --color-empty-illustration-on-bg-two: var(
    --color-empty-illustration-on-bg-two-night
  );
  --color-brand-default: var(--color-brand-default-night);
  --color-teammate-a5surface-on-bg-one: var(
    --color-teammate-a5surface-on-bg-one-night
  );
  --color-teammate-a7initial: var(--color-teammate-a7initial-night);
  --color-teammate-a1surface-on-bg-two: var(
    --color-teammate-a1surface-on-bg-two-night
  );
  --color-teammate-a3surface-on-bg-two: var(
    --color-teammate-a3surface-on-bg-two-night
  );
  --color-teammate-a8surface-on-bg-one: var(
    --color-teammate-a8surface-on-bg-one-night
  );
  --color-teammate-a3initial: var(--color-teammate-a3initial-night);
  --color-teammate-a4surface-on-bg-two: var(
    --color-teammate-a4surface-on-bg-two-night
  );
  --color-teammate-a5initial: var(--color-teammate-a5initial-night);
  --color-teammate-a1initial: var(--color-teammate-a1initial-night);
  --color-teammate-a6surface-on-bg-one: var(
    --color-teammate-a6surface-on-bg-one-night
  );
  --color-teammate-a7surface-on-bg-one: var(
    --color-teammate-a7surface-on-bg-one-night
  );
  --color-teammate-a2surface-on-bg-one: var(
    --color-teammate-a2surface-on-bg-one-night
  );
  --color-teammate-a1surface-on-bg-one: var(
    --color-teammate-a1surface-on-bg-one-night
  );
  --color-teammate-a3surface-on-bg-one: var(
    --color-teammate-a3surface-on-bg-one-night
  );
  --color-teammate-a2initial: var(--color-teammate-a2initial-night);
  --color-teammate-a8initial: var(--color-teammate-a8initial-night);
  --color-teammate-a7surface-on-bg-two: var(
    --color-teammate-a7surface-on-bg-two-night
  );
  --color-teammate-a8surface-on-bg-two: var(
    --color-teammate-a8surface-on-bg-two-night
  );
  --color-teammate-a4surface-on-bg-one: var(
    --color-teammate-a4surface-on-bg-one-night
  );
  --color-teammate-a4initial: var(--color-teammate-a4initial-night);
  --color-teammate-a5surface-on-bg-two: var(
    --color-teammate-a5surface-on-bg-two-night
  );
  --color-teammate-a6initial: var(--color-teammate-a6initial-night);
  --color-teammate-a2surface-on-bg-two: var(
    --color-teammate-a2surface-on-bg-two-night
  );
  --color-teammate-a6surface-on-bg-two: var(
    --color-teammate-a6surface-on-bg-two-night
  );
  --color-input-on-bg-two: var(--color-input-on-bg-two-night);
  --color-input-on-bg-one: var(--color-input-on-bg-one-night);
  --color-disabled-on-bg-two: var(--color-disabled-on-bg-two-night);
  --color-disabled-on-bg-one: var(--color-disabled-on-bg-one-night);
  --color-label-primary: var(--color-label-primary-night);
  --color-label-quaternary: var(--color-label-quaternary-night);
  --color-border: var(--color-border-night);
  --color-label-secondary: var(--color-label-secondary-night);
  --color-bg-one: var(--color-bg-one-night);
  --color-bg-two: var(--color-bg-two-night);
  --color-danger-additional-on-bg-one: var(
    --color-danger-additional-on-bg-one-night
  );
  --color-danger-additional-on-bg-two: var(
    --color-danger-additional-on-bg-two-night
  );
  --color-colored-surface-three-additional-on-bg-two: var(
    --color-colored-surface-three-additional-on-bg-two-night
  );
  --color-empty-illustration-on-bg-one: var(
    --color-empty-illustration-on-bg-one-night
  );
  --color-pressed-brand-surface-on-bg-two: var(
    --color-pressed-brand-surface-on-bg-two-night
  );
  --color-hover-brand-surface-on-bg-one: var(
    --color-hover-brand-surface-on-bg-one-night
  );
  --color-colored-surface-four-additional-on-bg-one: var(
    --color-colored-surface-four-additional-on-bg-one-night
  );
  --color-pressed-brand-surface-on-bg-one: var(
    --color-pressed-brand-surface-on-bg-one-night
  );
  --color-colored-surface-three-additional-on-bg-one: var(
    --color-colored-surface-three-additional-on-bg-one-night
  );
  --color-colored-surface-five-additional-on-bg-one: var(
    --color-colored-surface-five-additional-on-bg-one-night
  );
  --color-hover-no-surface-on-bg-two: var(
    --color-hover-no-surface-on-bg-two-night
  );
  --color-integration-surface-on-bg-two: var(
    --color-integration-surface-on-bg-two-night
  );
  --color-colored-surface-five-additional-on-bg-two: var(
    --color-colored-surface-five-additional-on-bg-two-night
  );
  --color-hover-no-surface-on-bg-one: var(
    --color-hover-no-surface-on-bg-one-night
  );
  --color-colored-surface-five-default: var(
    --color-colored-surface-five-default-night
  );
  --color-hover-danger-surface-on-bg-two: var(
    --color-hover-danger-surface-on-bg-two-night
  );
  --color-hover-brand-surface-on-bg-two: var(
    --color-hover-brand-surface-on-bg-two-night
  );
  --color-pressed-no-surface-on-bg-two: var(
    --color-pressed-no-surface-on-bg-two-night
  );
  --color-hover-danger-surface-on-bg-one: var(
    --color-hover-danger-surface-on-bg-one-night
  );
  --color-pressed-danger-surface-on-bg-one: var(
    --color-pressed-danger-surface-on-bg-one-night
  );
  --color-integration-surface-on-bg-one: var(
    --color-integration-surface-on-bg-one-night
  );
  --color-colored-surface-three-default: var(
    --color-colored-surface-three-default-night
  );
  --color-pressed-danger-surface-on-bg-two: var(
    --color-pressed-danger-surface-on-bg-two-night
  );
  --color-pressed-no-surface-on-bg-one: var(
    --color-pressed-no-surface-on-bg-one-night
  );
  --color-colored-surface-two-additional-on-bg-two: var(
    --color-colored-surface-two-additional-on-bg-two-night
  );
  --color-colored-surface-two-additional-on-bg-one: var(
    --color-colored-surface-two-additional-on-bg-one-night
  );
  --color-brand-additional-on-bg-one: var(
    --color-brand-additional-on-bg-one-night
  );
  --color-waiting-default: var(--color-waiting-default-night);
  --color-waiting-additional-on-bg-two: var(
    --color-waiting-additional-on-bg-two-night
  );
  --color-danger-default: var(--color-danger-default-night);
  --color-waiting-additional-on-bg-one: var(
    --color-waiting-additional-on-bg-one-night
  );
  --color-success-additional-on-bg-one: var(
    --color-success-additional-on-bg-one-night
  );
  --color-success-default: var(--color-success-default-night);
  --color-success-additional-on-bg-two: var(
    --color-success-additional-on-bg-two-night
  );
  --color-colored-surface-four-additional-on-bg-two: var(
    --color-colored-surface-four-additional-on-bg-two-night
  );
  --color-colored-surface-two-default: var(
    --color-colored-surface-two-default-night
  );
  --color-colored-surface-one-additional-on-bg-two: var(
    --color-colored-surface-one-additional-on-bg-two-night
  );
  --color-colored-surface-one-additional-on-bg-one: var(
    --color-colored-surface-one-additional-on-bg-one-night
  );
  --color-white-static: var(--color-white-static-night);
  --color-brand-additional-on-bg-two: var(
    --color-brand-additional-on-bg-two-night
  );
  --color-card-bg: var(--color-card-bg-night);
  --color-brand-disable: var(--color-brand-disable-night);
  --color-integration-surface-disable-on-bg-one: var(
    --color-integration-surface-disable-on-bg-one-night
  );
  --color-colored-surface-four: var(--color-colored-surface-four-night);
  --color-chip-default: var(--color-chip-default-night);
  --color-colored-surface-one-default: var(
    --color-colored-surface-one-default-night
  );
  --color-translucent-card-bg: var(--color-translucent-card-bg-night);
`;
