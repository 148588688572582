import { INotification } from '@/entities';
import { IBaseRequest } from '@/api/types';

export interface IGetNotificationsParams {
  clientId?: string;
  important?: boolean;
  orderBy?: string;
  orderDirection?: string;
  states?: string;
  limit?: number;
  offset?: number;
}

interface IGetNotificationsProps extends IBaseRequest {
  params: IGetNotificationsParams;
}

export async function getNotifications({
  api,
  params,
}: IGetNotificationsProps) {
  const response = await api.get<INotification[]>(`notifications`, {
    params,
  });

  return response.data;
}
