import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createSuccessResponse,
  createErrorResponse,
  createErrorHandler,
} from '@/api/helpers';
import { IAccountLimits } from '@/entities';

interface IClientUserAccountDto {
  clientAccountId: string;
  limits: IAccountLimits;
}

interface IInviteNewAdminProps extends IBaseRequest {
  dto: {
    clientId: string;
    newRoleId: string;
    accounts: IClientUserAccountDto[];
    firstName: string;
    lastName: string;
    email: string;
    channel: string;
    verificationCode: string;
  };
}

export async function inviteNewAdmin({
  api,
  dto,
}: IInviteNewAdminProps): Promise<IBaseResponse<string>> {
  try {
    const response = await api.post<IBaseResponse<string>>(
      'special_cases/invite_new_admin',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
