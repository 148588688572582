import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IDeactivateUserSupportProps extends IBaseRequest {
  dto: {
    reasonId?: string;
    additionalInfo?: string;
  };
}

interface IDeactivateUserSupportResponse {
  success: boolean;
}

export async function deactivateUserSupport({
  api,
  dto,
}: IDeactivateUserSupportProps): Promise<
  IBaseResponse<IDeactivateUserSupportResponse>
> {
  try {
    const response = await api.post<
      IBaseResponse<IDeactivateUserSupportResponse>
    >(`user/deactivate/support`, dto);

    if (response.data.success) {
      return createSuccessResponse({
        result: {
          success: response.data.success,
        },
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
