import { Draft } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetDraftProps extends IBaseRequest {
  id: string;
  clientId: string;
}

export async function getDraft({
  api,
  id,
  clientId,
}: IGetDraftProps): Promise<Draft> {
  const response = await api.get<Draft>(`clients/${clientId}/drafts/${id}`);

  return response.data;
}
