import { Transaction } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ISendTransactionNowProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function sendTransactionNow({
  api,
  clientId,
  id,
}: ISendTransactionNowProps): Promise<IBaseResponse<Transaction>> {
  try {
    const response = await api.post<IBaseResponse<Transaction>>(
      `clients/${clientId}/display_transactions/${id}/send_now`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
