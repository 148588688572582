import { useMemo } from 'react';
import { useWindowSize } from 'react-use';

export function useResolution() {
  const { width, height } = useWindowSize();
  const isMobile = useMemo(() => width < 900, [width]);
  const isTablet = useMemo(() => width <= 1200, [width]);

  return {
    width,
    height,
    isMobile,
    isTablet,
  };
}
