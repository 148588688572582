import { IBaseRequest } from '@/api';
import { IAggregatedClient } from '@/entities';

export async function getAggregatedClients({
  api,
}: IBaseRequest): Promise<IAggregatedClient[]> {
  const response = await api.get<IAggregatedClient[]>(
    `user/aggregated_clients`,
  );

  return response.data;
}
