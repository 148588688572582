import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';

export interface IIconProps
  extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  gradient?: Gradient;
  color?: string;
  size?: string;
  width?: string;
  height?: string;
}

//TODO: add all gradients
export enum Gradient {
  BRAND_DEFAULT = 'gradient-brand-default',
  GREEN_BLUE = 'gradient-green-blue',
}

export const gradientMap: Record<Gradient, ReactNode> = {
  [Gradient.BRAND_DEFAULT]: (
    <linearGradient id={Gradient.BRAND_DEFAULT} y2="1" x2="0">
      <stop offset="0.3" stopColor="#a200ff" />
      <stop offset="1" stopColor="#0b3fff" />
    </linearGradient>
  ),
  [Gradient.GREEN_BLUE]: (
    <linearGradient id={Gradient.GREEN_BLUE} x1="0" x2="1">
      <stop offset="0.3" stopColor="#E2F7ED" />
      <stop offset="1" stopColor="#E4E9F9" />
    </linearGradient>
  ),
};
