import { UserClientUser } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetByUserIdProps extends IBaseRequest {
  clientId: string;
  userId: string;
}

export async function getByUserId({
  api,
  clientId,
  userId,
}: IGetByUserIdProps): Promise<UserClientUser> {
  const response = await api.get<UserClientUser>(
    `clients/${clientId}/client_users/by_user_id/${userId}`,
  );

  return response.data;
}
