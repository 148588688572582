import { AuthMethod, ChangeDataRequest } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ICreateChangeDataRequestProps extends IBaseRequest {
  clientId: string;
  dto: {
    kind?: string;
    verificationCode?: string;
    channel?: AuthMethod;
  };
}

export async function createChangeDataRequest({
  api,
  clientId,
  dto,
}: ICreateChangeDataRequestProps): Promise<IBaseResponse<ChangeDataRequest>> {
  try {
    const response = await api.post<IBaseResponse<ChangeDataRequest>>(
      `clients/${clientId}/change_data_requests`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
