import { IBaseRequest } from '@/api/types';
import { Account } from '@/entities';

interface IGetClientAccountProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getClientAccount({
  api,
  clientId,
  id,
}: IGetClientAccountProps): Promise<Account> {
  const response = await api.get<Account>(`clients/${clientId}/accounts/${id}`);

  return response.data;
}
