import { useCallback, useEffect, useState } from 'react';
import { getAccessToken } from '@/auth/functions/getAccessToken.ts';
import { getRefreshToken } from '@/auth/functions/getRefreshToken.ts';
import { setAuthTokenToHeader, useApi } from '@/api';
import { config } from '@/config';

export interface IUseTokensExport {
  handleSetTokens: (tokens: {
    accessToken: string;
    refreshToken: string;
  }) => void;
  handleSetAuthHeader: (token: string) => void;
  handleClearTokens: () => void;
  accessToken: string;
  refreshToken: string;
}

export function useTokens() {
  const { client } = useApi();

  const [tokens, setTokens] = useState({
    accessToken: getAccessToken(),
    refreshToken: getRefreshToken(),
  });

  const updateTokens = useCallback(() => {
    setTokens({
      accessToken: getAccessToken(),
      refreshToken: getRefreshToken(),
    });
  }, []);

  const handleSetAuthHeader = useCallback(
    (token: string) => {
      setAuthTokenToHeader(client, token);
    },
    [client],
  );

  const handleSetTokens = useCallback(
    ({
      accessToken,
      refreshToken,
    }: {
      accessToken: string;
      refreshToken: string;
    }) => {
      handleSetAuthHeader(accessToken);
      localStorage.setItem(
        config.security.session.storageKeys.refreshToken,
        refreshToken,
      );
      localStorage.setItem(
        config.security.session.storageKeys.accessToken,
        accessToken,
      );
      setTokens({
        refreshToken,
        accessToken,
      });
    },
    [handleSetAuthHeader],
  );

  const handleClearTokens = useCallback(() => {
    setAuthTokenToHeader(client, undefined);
    localStorage.removeItem(config.security.session.storageKeys.accessToken);
    localStorage.removeItem(config.security.session.storageKeys.refreshToken);

    setTokens({
      accessToken: '',
      refreshToken: '',
    });
  }, [client]);

  useEffect(() => {
    // Sync between tab
    window.addEventListener('storage', updateTokens, false);

    return () => {
      window.removeEventListener('storage', updateTokens, false);
    };
  }, []);

  useEffect(() => {
    updateTokens();
  }, []);

  return {
    handleSetTokens,
    handleSetAuthHeader,
    handleClearTokens,
    accessToken: tokens.accessToken,
    refreshToken: tokens.refreshToken,
  } as IUseTokensExport;
}
