import { IBaseRequest } from '@/api/types';
import { ISupportingDocumentsValidation } from '@/entities';

interface IGetSupportingDocumentsRequestValidationProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getSupportingDocumentsRequestValidation({
  api,
  clientId,
  id,
}: IGetSupportingDocumentsRequestValidationProps): Promise<ISupportingDocumentsValidation> {
  const response = await api.post<ISupportingDocumentsValidation>(
    `clients/${clientId}/supporting_documents_requests/${id}/validate`,
  );

  return response.data;
}
