import { IBaseRequest } from '@/api/types';
import { IAuditLog } from '@/entities/AuditLog.ts';

interface IGetInvoiceAuditLogsProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getInvoiceAuditLogs({
  api,
  clientId,
  id,
}: IGetInvoiceAuditLogsProps): Promise<IAuditLog[]> {
  const response = await api.get<IAuditLog[]>(
    `clients/${clientId}/invoices/${id}/audit_logs`,
  );

  return response.data;
}
