import { IBaseRequest } from '@/api/types';

export async function getWSAuthToken({
  api,
}: IBaseRequest): Promise<{ success: boolean; token: string }> {
  const response = await api.get<{ success: boolean; token: string }>(
    'user/ws_connection_token',
  );

  return response.data;
}
