import styled from '@emotion/styled';
import { TextKind, ITextProps } from '@/ui/components/mobile/Text/types.ts';
import { StyledText } from '@/ui/components/mobile/Text/index.styles.ts';

export function Text({
  as,
  kind = TextKind.BODY_REGULAR,
  ...props
}: ITextProps) {
  return <StyledText as={as} {...props} kind={kind} />;
}

export function TextOrChildren({ children, ...props }: ITextProps) {
  if (typeof children === 'string' || typeof children === 'number') {
    return <StyledText {...props}>{children}</StyledText>;
  }

  return children;
}

export const PrimaryText = styled(Text)`
  color: var(--color-label-primary);
`;

export const SecondaryText = styled(Text)`
  color: var(--color-label-secondary);
`;

export const TertiaryText = styled(Text)`
  color: var(--color-label-tertiary);
`;
