import { Country } from '@/entities';
import { IBaseRequest, IBaseSortable } from '@/api/types';

interface IGetContactsCountriesProps extends IBaseRequest, IBaseSortable {
  clientId?: string;
}

export async function getContactsCountries({
  api,
  clientId,
  orderBy = 'display_name',
  orderDirection = 'asc',
}: IGetContactsCountriesProps): Promise<Country[]> {
  const response = await api.get<Country[]>(
    `clients/${clientId}/contacts/countries`,
    {
      params: {
        orderBy,
        orderDirection,
      },
    },
  );

  return response.data;
}
