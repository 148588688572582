import { ClientUser } from '@/entities';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { IBaseRequest, IBaseResponse } from '@/api/types';

interface IFastLoginExtra {
  token: string;
  refreshToken: string;
}

type IFastLoginResponse = IBaseResponse<ClientUser> & IFastLoginExtra;

export async function fastLogin({
  api,
}: IBaseRequest): Promise<IBaseResponse<ClientUser, IFastLoginExtra>> {
  try {
    const response = await api.post<IFastLoginResponse>('auth/sessions/jeremy');

    if (response.data.success) {
      return createSuccessResponse<ClientUser, IFastLoginExtra>({
        result: response.data.result,
        extra: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse<ClientUser, IFastLoginExtra>({
      error: response.data.error,
      extra: {
        token: '',
        refreshToken: '',
      },
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
