import { IRole } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRolesProps extends IBaseRequest {
  clientId?: string;
}

export async function getRoles({
  api,
  clientId,
}: IGetRolesProps): Promise<IRole[]> {
  const response = await api.get<IRole[]>(`clients/${clientId}/roles`);

  return response.data;
}
