import { Tariff } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetTariffProps extends IBaseRequest {
  clientDraftId: string;
}

export async function getTariff({
  api,
  clientDraftId,
}: IGetTariffProps): Promise<Tariff> {
  const response = await api.get<Tariff>(
    `client_drafts/${clientDraftId}/tariff`,
  );

  return response.data;
}
