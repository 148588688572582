import { IBaseRequest } from '@/api/types';
import { IPaymentsByLabelsStats } from '@/entities';

interface IGetPaymentsByLabelsStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    direction?: string;
    accountId?: string;
    since?: number;
    till?: number;
  };
}

export async function getPaymentsByLabelsStats({
  api,
  clientId,
  params,
}: IGetPaymentsByLabelsStatsProps): Promise<IPaymentsByLabelsStats> {
  const response = await api.get<IPaymentsByLabelsStats>(
    `clients/${clientId}/stat/payments_by_labels`,
    {
      params,
    },
  );

  return response.data;
}
