import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface ISaveSettingsProps extends IBaseRequest {
  dto: {
    settings: Record<string, unknown>;
  };
}

export async function saveSettings({
  api,
  dto,
}: ISaveSettingsProps): Promise<IBaseResponse<unknown>> {
  try {
    const response = await api.post<IBaseResponse<unknown>>(
      `user/settings`,
      dto,
    );

    return createSuccessResponse({
      result: response.data.result,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
