import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IDeactivateUserProps extends IBaseRequest {
  dto: {
    reasonId?: string;
    additionalInfo?: string;
  };
}

interface IDeactivateUserResponse {
  success: boolean;
}

export async function deactivateUser({
  api,
  dto,
}: IDeactivateUserProps): Promise<IBaseResponse<IDeactivateUserResponse>> {
  try {
    const response = await api.post<IBaseResponse<IDeactivateUserResponse>>(
      `user/deactivate`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: {
          success: response.data.success,
        },
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
