import { MouseEvent, MouseEventHandler } from 'react';

/**
 * Prevent call unexpected onClick handler for disabled state
 * @param disabled
 * @param onClick
 */
export function disabledClickPrevent<T>(
  disabled: boolean | undefined,
  onClick: MouseEventHandler<T> | undefined,
) {
  return (event: MouseEvent<T>) => {
    if (!disabled) {
      onClick && onClick(event);
    }
  };
}
