import { IBaseRequest } from '@/api/types';

interface ICheckBankEntitiesHolidays extends IBaseRequest {
  id: string;
  timestamp: number;
}

export async function checkBankEntitiesHolidays({
  api,
  id,
  timestamp,
}: ICheckBankEntitiesHolidays): Promise<{
  date: string;
  success: boolean;
}> {
  const response = await api.get<{
    date: string;
    success: boolean;
  }>(`bank_entities/${id}/validate_holidays`, { params: { date: timestamp } });

  return response.data;
}
