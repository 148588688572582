import dayjs from 'dayjs';
import { decodeToken } from '@/auth/functions/decodeToken.ts';

export function checkToken(
  token: string,
  differenceInSeconds: number = 0,
  serverTime: number = dayjs().unix(),
) {
  const underMaintenance = import.meta.env.VITE_UNDER_MAINTENANCE === 'true';

  if (token && !underMaintenance) {
    const decodedToken = decodeToken(token);

    if (decodedToken.success) {
      const expiredAt = decodedToken.payload?.exp ?? 0;

      if (expiredAt - differenceInSeconds > serverTime) {
        return true;
      }
    }
  }

  return false;
}
