import { Wrapper } from '@/ui/components/desktop/Modal/index.styles.ts';
import { IModalProps } from '@/ui/components/types/modal.ts';
import { createPortal } from 'react-dom';
import { VARIANTS } from '@/ui/components/desktop/Modal/animation.ts';
import { AnimatePresence, useAnimate } from 'framer-motion';
import { useCallback, useRef, MouseEvent } from 'react';
import { composeRefs } from '@/ui/hooks/useComposedRefs.ts';
import { LockedModal } from '@/ui/shared/LockedModal';

export function Modal({
  opened,
  close,
  closeByClickOutside,
  ...props
}: IModalProps) {
  const [scope, animate] = useAnimate();
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = useCallback(
    (event: MouseEvent) => {
      const isOutsideClick = event.target === wrapperRef.current;

      if (closeByClickOutside && close && isOutsideClick) {
        return close();
      }

      if (isOutsideClick) {
        return animate(
          scope.current,
          {
            scale: [1, 1.01, 1],
          },
          {
            duration: 0.2,
          },
        );
      }
    },
    [animate, close, closeByClickOutside, scope],
  );

  return createPortal(
    <AnimatePresence>
      {opened && (
        <LockedModal>
          <Wrapper
            ref={composeRefs(scope, wrapperRef)}
            variants={VARIANTS}
            initial="initial"
            animate="opened"
            exit="closed"
            onClick={handleOutsideClick}
            {...props}
          />
        </LockedModal>
      )}
    </AnimatePresence>,
    document.body,
  );
}
