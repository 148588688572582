import { AuthMethod, ClientUser, IAccountLimits } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IClientUserAccount {
  clientAccountId?: string;
  limits?: IAccountLimits;
}

interface IPatchClientUserProps extends IBaseRequest {
  clientId: string;
  clientUserId: string;
  dto?: {
    accounts?: IClientUserAccount[];
    roleId?: string;
    isAdmin?: boolean;
    isApprover?: boolean;
    verificationCode?: string;
    channel?: AuthMethod;
  };
}

export async function patchClientUser({
  api,
  clientId,
  clientUserId,
  dto,
}: IPatchClientUserProps): Promise<IBaseResponse<ClientUser>> {
  try {
    const response = await api.patch<IBaseResponse<ClientUser>>(
      `clients/${clientId}/client_users/${clientUserId}`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
