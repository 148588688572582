import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IResetPasswordProps extends IBaseRequest {
  dto: {
    email: string;
  };
}

export async function resetPassword({
  api,
  dto,
}: IResetPasswordProps): Promise<IBaseResponse<null>> {
  try {
    const response = await api.post<IBaseResponse<null>>(
      'auth/password_resets',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: null,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
