import { IBaseRequest, IBaseResponse } from '@/api/types';
import { ISupportingDocumentsRequest } from '@/entities';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers.ts';

interface ICreateSupportingDocumentsRequestProps extends IBaseRequest {
  clientId: string;
  dto: ICreateSupportingDocumentsRequestDto;
}

export interface INewDto {
  data: Array<{
    code: string;
    value: string;
    displayName: string;
    valueDisplayName: string;
  }>;
  subjectType:
    | 'Draft'
    | 'Invoice'
    | 'AddMoneySubmission'
    | 'SupportingDocumentsRequest'
    | 'DisplayTransaction'; // extend if needed
  subjectId: string;
  documentIds: string[];
}

export interface ICreateSupportingDocumentsRequestDto extends INewDto {}

export async function createSupportingDocumentsRequest({
  api,
  clientId,
  dto,
}: ICreateSupportingDocumentsRequestProps): Promise<
  IBaseResponse<ISupportingDocumentsRequest>
> {
  try {
    const response = await api.post<IBaseResponse<ISupportingDocumentsRequest>>(
      `clients/${clientId}/supporting_documents_requests`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
