import { SessionRedirect, User } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ISessionRedirectProps extends IBaseRequest {
  id: string;
}

type ISessionRedirectExtra = {
  model: SessionRedirect;
  token: string;
  refreshToken: string;
};

type ISessionRedirectResponse = IBaseResponse<User> & ISessionRedirectExtra;

export async function getSessionRedirect({
  api,
  id,
}: ISessionRedirectProps): Promise<IBaseResponse<User, ISessionRedirectExtra>> {
  try {
    const response = await api.get<ISessionRedirectResponse>(
      `auth/session_redirects/${id}`,
    );

    if (response.data.success) {
      return createSuccessResponse<User, ISessionRedirectExtra>({
        result: response.data.result,
        extra: {
          model: response.data.model,
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse<User, ISessionRedirectExtra>({
      error: response.data.error,
      extra: {
        model: {} as SessionRedirect,
        token: '',
        refreshToken: '',
      },
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
