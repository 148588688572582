import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { User } from '@/entities';

interface ISetEmailSubscriptionProps extends IBaseRequest {
  dto: {
    mailchimpSubscribed: boolean;
  };
}

export async function setEmailSubscription({
  api,
  dto,
}: ISetEmailSubscriptionProps): Promise<IBaseResponse<User>> {
  try {
    const response = await api.patch<IBaseResponse<User>>(
      `user/mailchimp_subscription`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
