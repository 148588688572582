import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IDeleteLabelProps extends IBaseRequest {
  clientId?: string;
  labelId?: string;
}

export async function deleteLabel({
  api,
  clientId,
  labelId,
}: IDeleteLabelProps): Promise<IBaseResponse<boolean>> {
  try {
    const response = await api.delete<IBaseResponse<boolean>>(
      `clients/${clientId}/labels/${labelId}`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.success,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
