import { useCallback, useEffect } from 'react';
import dayjs from 'dayjs';
import { config } from '@/config';
import { useApi } from '@/api';

function setServerTimeDelta(value: number) {
  localStorage.setItem(
    config.security.session.storageKeys.serverTime,
    String(value),
  );
}

function getServerTimeDelta() {
  return Number(
    localStorage.getItem(config.security.session.storageKeys.serverTime) ?? '0',
  );
}

export function getServerTime() {
  return dayjs().unix() + getServerTimeDelta();
}

export function useServerTime() {
  const { api, client } = useApi();

  const syncTimeWithServer = useCallback(async () => {
    const serverTime = await api.auth.getHealthCheck({ api: client });
    const delta = (serverTime || dayjs().unix()) - dayjs().unix();
    setServerTimeDelta(delta);
  }, [api.auth, client]);

  useEffect(() => {
    syncTimeWithServer();
  }, []);

  return {
    syncTimeWithServer,
  };
}
