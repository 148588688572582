import { Theme } from '@/ui/ThemeProvider/types';
import { useTheme } from '@/ui/ThemeProvider/useTheme';
import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function Logo({ height = '16px', ...props }: IIconProps) {
  const { theme } = useTheme();
  const letterColor =
    theme === Theme.LIGHT
      ? 'var(--color-label-primary-day)'
      : 'var(--color-bg-two-day)';

  return (
    <Wrapper {...props} height={height}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 190 30">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.37436 23.0402H22.5062L25.2041 29.993H30.7283L18.0097 0H12.8709L0.152344 29.993H5.54805L8.37436 23.0402ZM15.4392 5.59131L20.7066 18.6792H10.1719L15.4392 5.59131Z"
          fill={letterColor}
        />
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.1045 21.2145L62.8856 29.9976H68.5384L61.8579 20.1335C63.785 19.3227 65.3266 18.1066 66.3544 16.4851C67.3821 14.8636 67.896 12.9719 67.896 10.6747C67.896 8.51277 67.5106 6.62111 66.4827 4.9996C65.455 3.37809 64.0419 2.16196 62.2433 1.3512C60.4447 0.405377 58.2607 0 55.8197 0H44.1289V29.9976H49.5246V21.2145H55.9482H57.1045ZM55.694 4.73328C58.0064 4.73328 59.805 5.13865 60.9612 6.21971C62.1175 7.30061 62.7598 8.78705 62.7598 10.6787C62.7598 12.5705 62.1175 14.0568 60.9612 15.1379C59.805 16.2189 58.0064 16.7594 55.694 16.7594H49.5273V4.73328H55.694Z"
            fill="url(#paint0_linear_9185_123541)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.1826 21.2145L57.9637 29.9976H63.6165L56.936 20.1335C58.8631 19.3227 60.4048 18.1066 61.4325 16.4851C62.4602 14.8636 62.9742 12.9719 62.9742 10.6747C62.9742 8.51277 62.5887 6.62111 61.5609 4.9996C60.5331 3.37809 59.12 2.16196 57.3214 1.3512C55.5228 0.405377 53.3388 0 50.8978 0H39.207V29.9976H44.6027V21.2145H51.0263H52.1826ZM50.7721 4.73328C53.0845 4.73328 54.883 5.13865 56.0393 6.21971C57.1955 7.30061 57.8378 8.78705 57.8378 10.6787C57.8378 12.5705 57.1955 14.0568 56.0393 15.1379C54.883 16.2189 53.0845 16.7594 50.7721 16.7594H44.6055V4.73328H50.7721Z"
            fill={letterColor}
          />
        </g>
        <path d="M77.7734 0H82.6957V30H77.7734V0Z" fill={letterColor} />
        <path
          d="M123.057 0L110.836 30H105.744L93.5234 0H99.1256L108.417 23.1818L117.837 0H123.057Z"
          fill={letterColor}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M136.777 23.048H150.909L153.736 30.0008H159.132L146.413 0.0078125H141.274L128.555 30.0008H133.951L136.777 23.048ZM143.843 5.59644L149.11 18.6843H138.574L143.843 5.59644Z"
          fill={letterColor}
        />
        <path
          d="M169.328 0H174.761V25.2273H190.002V30H169.328V0Z"
          fill={letterColor}
        />
        <defs>
          <linearGradient
            id="paint0_linear_9185_123541"
            x1="68.5384"
            y1="14.9988"
            x2="44.1289"
            y2="14.9988"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#A200FF" />
            <stop offset="1" stopColor="#0B3FFF" />
          </linearGradient>
        </defs>
      </SVGContainer>
    </Wrapper>
  );
}
