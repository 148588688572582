import { IBaseRequest } from '@/api/types';
import {
  IAddMoneySubmission,
  Invoice,
  Transaction,
  ISupportingDocumentsRequest,
  Draft,
  Account,
} from '@/entities';

interface IGetDocumentRelationsProps extends IBaseRequest {
  id: string;
  clientId?: string;
}

interface IGetDocumentRelationsResponse {
  supportingDocumentsRequests?: ISupportingDocumentsRequest[];
  displayTransactions?: Transaction[];
  drafts?: Draft[];
  invoices?: Invoice[];
  addMoneySubmissions?: IAddMoneySubmission[];
  clientAccounts?: Account[];
}

export async function getDocumentRelations({
  api,
  id,
  clientId,
}: IGetDocumentRelationsProps): Promise<IGetDocumentRelationsResponse> {
  const response = await api.get<IGetDocumentRelationsResponse>(
    `clients/${clientId}/document_relations/by_document/${id}`,
  );

  return response.data;
}
