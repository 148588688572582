import { IBaseRequest } from '@/api/types';
import { IExtendedSuggestedAddress } from '@/entities';

interface IGetAddressPredictionsProps extends IBaseRequest {
  clientId: string;
  placeId: string;
}

export async function getAddressExtended({
  api,
  clientId,
  placeId,
}: IGetAddressPredictionsProps): Promise<IExtendedSuggestedAddress> {
  const response = await api.get<IExtendedSuggestedAddress>(
    `clients/${clientId}/addresses/extended`,
    { params: { placeId } },
  );

  return response.data;
}
