import { ClientDraft, FlexibleObject, User } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createSuccessResponse,
  createErrorResponse,
  createErrorHandler,
} from '@/api/helpers';

interface ICreateUserAndClientDraftProps<T extends FlexibleObject>
  extends IBaseRequest {
  dto: T;
}

interface ICreateUserAndClientDraftResponse {
  success: boolean;
  user: {
    success: boolean;
    model: User;
    error: Record<string, string[]>;
    accessToken: string;
    refreshToken: string;
  };
  clientDraft: {
    success: boolean;
    model: ClientDraft;
    error: Record<string, string[]>;
  };
  clientUser: {
    id: string;
  };
}

type ICreateUserAndClientDraftResult = Pick<
  ICreateUserAndClientDraftResponse,
  'user' | 'clientDraft' | 'clientUser'
>;

export async function createUserAndClientDraft<T extends FlexibleObject>({
  api,
  dto,
}: ICreateUserAndClientDraftProps<T>): Promise<
  IBaseResponse<ICreateUserAndClientDraftResult>
> {
  try {
    const response = await api.post<ICreateUserAndClientDraftResponse>(
      'special_cases/create_user_and_client_draft',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: {
          user: response.data.user,
          clientDraft: response.data.clientDraft,
          clientUser: response.data.clientUser,
        },
      });
    }

    return createErrorResponse({});
  } catch (error) {
    return createErrorHandler(error);
  }
}
