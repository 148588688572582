import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface ISetSpecialActivitiesProps extends IBaseRequest {
  dto: {
    specialActivitiesIds: string[];
  };
  clientDraftId: string;
}

export async function setSpecialActivities({
  api,
  dto,
  clientDraftId,
}: ISetSpecialActivitiesProps): Promise<IBaseResponse<string>> {
  try {
    const response = await api.post<IBaseResponse<string>>(
      `client_drafts/${clientDraftId}/special_activities`,
      dto,
    );

    return createSuccessResponse({
      result: response.data.result ?? '',
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
