import { IAgreementSign } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementSignsProps extends IBaseRequest {
  clientDraftId: string;
  state: string;
  latest: boolean;
}

export async function getAgreementSigns({
  api,
  clientDraftId,
  state,
  latest,
}: IGetAgreementSignsProps): Promise<IAgreementSign[]> {
  const response = await api.get<IAgreementSign[]>(
    `client_drafts/${clientDraftId}/agreement_signs`,
    {
      params: {
        state,
        latest,
      },
    },
  );

  return response.data;
}
