import { IBaseCatchableError, IBaseRequest } from '@/api/types';
import { isAxiosError } from 'axios';

interface IGetHealthCheckProps extends IBaseRequest {}

export async function getHealthCheck({
  api,
}: IGetHealthCheckProps): Promise<number> {
  try {
    const response = await api.get<unknown>('health_check');

    return Number(response.headers?.['x-current-utc-timestamp']);
  } catch (error: IBaseCatchableError) {
    if (isAxiosError(error)) {
      return Number(error?.response?.headers?.['x-current-utc-timestamp']);
    }

    return 0;
  }
}
