import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

export async function signAgreements({
  api,
}: IBaseRequest): Promise<IBaseResponse<unknown>> {
  try {
    const response = await api.post<IBaseResponse<unknown>>(
      `user/agreement_signs/sign`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
