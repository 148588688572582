import axios from 'axios';
import type { AxiosInstance } from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { getPair } from '@/security/metricass';

export function createAxiosClient(apiUrl: string) {
  const options = {
    // For correct convert (example: DocumentService::Document)
    preservedKeys: [],
  };

  const client = applyCaseMiddleware(
    axios.create({
      baseURL: apiUrl,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
      withCredentials: true,
    }),
    options,
  );

  client.interceptors.request.use(async (request) => {
    const { hash, token } = await getPair();

    request.headers['METRICASS_PRINT_ID'] = token;
    request.headers['METRICASS_PRINT_TK'] = hash;

    return request;
  });

  return client;
}

export function setAuthTokenToHeader(
  api: AxiosInstance,
  token?: string | undefined,
) {
  if (token) {
    return (api.defaults.headers.common['Authorization'] = `Bearer ${token}`);
  }

  api.defaults.headers.common['Authorization'] = '';
}
