import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ParentBackground } from '@/ui/components/types/shared.ts';
import {
  ButtonKind,
  ButtonStyleGuide,
  MergedButtonStyleGuide,
} from '@/ui/components/mobile/Button/types.ts';
import { Wrapper as IconWrapper } from '@/ui/components/mobile/Icon/shared';
import { TextOrChildren } from '@/ui/components/mobile/Text';

interface IWrapperStyleProps {
  styleGuide: ButtonStyleGuide;
  kind: ButtonKind;
  hasIcon: boolean;
  fullWidth: boolean;
  hasChildren: boolean;
  parentBackground: ParentBackground;
}

const DIMENSIONS = {
  [ButtonKind.ENTITY_ACTION]: css`
    --direction: row;
    --width: fit-content;
    --padding: 6px 12px;
    --border-radius: 4px;
    --button-icon-size: 12px;
    --button-icon-margin: 0 4px 0 0;
    --button-icon-order: 0;
  `,
  [ButtonKind.MAIN]: css`
    --direction: row;
    --width: 100%;
    --padding: 14px 28px;
    --border-radius: 4px;
    --button-icon-size: 28px;
    --button-icon-margin: 0 0 0 12px;
    --button-icon-order: 1;
  `,
  [ButtonKind.SECONDARY]: css`
    --direction: column;
    --width: 100%;
    --padding: 5px 15px 6px;
    --border-radius: 10px;
    --button-icon-size: 24px;
    --button-icon-margin: 0 0 3px 0;
    --button-icon-order: 0;
  `,
  [ButtonKind.LOAD_MORE]: css`
    --direction: row;
    --width: 100%;
    --padding: 11px 16px;
    --column-gap: 8px;
    --border-radius: 10px;
    --button-icon-size: 20px;
    --button-icon-margin: 0 8px 0 0;
    --button-icon-order: 0;
  `,
};

const SHARED_STYLEGUIDES = {
  [MergedButtonStyleGuide.BRAND_INVERTED]: {
    default: css`
      --background-color: var(--color-brand-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-brand-default);
      --content-color: var(--color-white-static);
    `,
  },
  [MergedButtonStyleGuide.DANGER_INVERTED]: {
    default: css`
      --background-color: var(--color-danger-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-danger-default);
      --content-color: var(--color-white-static);
    `,
  },
  [MergedButtonStyleGuide.WARNING_INVERTED]: {
    default: css`
      --background-color: var(--color-waiting-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-waiting-default);
      --content-color: var(--color-white-static);
    `,
  },
  [MergedButtonStyleGuide.SUCCESS_INVERTED]: {
    default: css`
      --background-color: var(--color-success-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-success-default);
      --content-color: var(--color-white-static);
    `,
  },
};

const STYLEGUIDE = {
  [ParentBackground.ONE]: {
    [MergedButtonStyleGuide.BRAND]: {
      default: css`
        --background-color: var(--color-brand-additional-on-bg-one);
        --content-color: var(--color-brand-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-brand-surface-on-bg-one);
        --content-color: var(--color-brand-default);
      `,
    },
    [MergedButtonStyleGuide.DANGER]: {
      default: css`
        --background-color: var(--color-danger-additional-on-bg-one);
        --content-color: var(--color-danger-default);
      `,
      pressed: css`
        --background-color: var(--color-hover-danger-surface-on-bg-one);
        --content-color: var(--color-danger-default);
      `,
    },
    [MergedButtonStyleGuide.WARNING]: {
      default: css`
        --background-color: var(--color-waiting-additional-on-bg-one);
        --content-color: var(--color-waiting-default);
      `,
      pressed: css`
        --background-color: var(--color-waiting-additional-on-bg-one);
        --content-color: var(--color-waiting-default);
      `,
    },
    [MergedButtonStyleGuide.SUCCESS]: {
      default: css`
        --background-color: var(--color-success-additional-on-bg-one);
        --content-color: var(--color-success-default);
      `,
      pressed: css`
        --background-color: var(--color-hover-success-surface-on-bg-one);
        --content-color: var(--color-success-default);
      `,
    },
    [MergedButtonStyleGuide.DISABLED]: {
      default: css`
        --background-color: var(--color-disabled-on-bg-one);
        --content-color: var(--color-label-tertiary);
      `,
    },
    ...SHARED_STYLEGUIDES,
  },
  [ParentBackground.TWO]: {
    [MergedButtonStyleGuide.BRAND]: {
      default: css`
        --background-color: var(--color-brand-additional-on-bg-two);
        --content-color: var(--color-brand-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-brand-surface-on-bg-two);
        --content-color: var(--color-brand-default);
      `,
    },
    [MergedButtonStyleGuide.DANGER]: {
      default: css`
        --background-color: var(--color-danger-additional-on-bg-two);
        --content-color: var(--color-danger-default);
      `,
      pressed: css`
        --background-color: var(--color-hover-danger-surface-on-bg-two);
        --content-color: var(--color-danger-default);
      `,
    },
    [MergedButtonStyleGuide.WARNING]: {
      default: css`
        --background-color: var(--color-waiting-additional-on-bg-two);
        --content-color: var(--color-waiting-default);
      `,
      pressed: css`
        --background-color: var(--color-waiting-additional-on-bg-two);
        --content-color: var(--color-waiting-default);
      `,
    },
    [MergedButtonStyleGuide.SUCCESS]: {
      default: css`
        --background-color: var(--color-success-additional-on-bg-two);
        --content-color: var(--color-success-default);
      `,
      pressed: css`
        --background-color: var(--color-hover-success-surface-on-bg-two);
        --content-color: var(--color-success-default);
      `,
    },
    [MergedButtonStyleGuide.DISABLED]: {
      default: css`
        --background-color: var(--color-disabled-on-bg-two);
        --content-color: var(--color-label-tertiary);
      `,
    },
    ...SHARED_STYLEGUIDES,
  },
};

const clickAnimation = css`
  transform: scale(1);

  &:active {
    transform: scale(0.98);
  }
`;

export const Wrapper = styled.button<IWrapperStyleProps>(
  ({
    fullWidth,
    kind,
    parentBackground,
    styleGuide,
    onClick,
    disabled,
    hasChildren,
  }) => css`
    ${DIMENSIONS[kind]};
    ${STYLEGUIDE[parentBackground][styleGuide].default}

    display: flex;
    flex-direction: var(--direction);
    align-items: center;
    justify-content: center;
    width: ${fullWidth ? '100%' : 'var(--width)'};
    height: fit-content;
    margin: 0;
    padding: var(--padding);
    border: none;
    border-radius: var(--border-radius);
    background: var(--background-color);
    transition:
      transform 0.3s cubic-bezier(0.16, 1, 0.3, 1),
      background-color 0.3s cubic-bezier(0.22, 1, 0.36, 1);
    -webkit-tap-highlight-color: transparent;
    ${onClick && !disabled && clickAnimation}

    ${IconWrapper} {
      --icon-width: var(--button-icon-size);
      --icon-height: var(--button-icon-size);
      --icon-color: var(--content-color);

      margin: ${hasChildren && 'var(--button-icon-margin)'};
      order: var(--button-icon-order);

      & > svg {
        transition: color 0.3s cubic-bezier(0.22, 1, 0.36, 1);
      }
    }

    & > span {
      color: var(--content-color);
    }

    &:active {
      ${STYLEGUIDE[parentBackground][styleGuide].pressed}
    }

    &:disabled {
      ${STYLEGUIDE[parentBackground][MergedButtonStyleGuide.DISABLED].default};
    }
  `,
);

export const ButtonText = styled(TextOrChildren)`
  color: var(--content-color);
  transition: color 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;
