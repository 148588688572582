import { IAddMoneySubmissionPreview } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';

interface IGetDraftPreviewProps extends IBaseRequest {
  id: string;
  clientId: string;
}

export async function getAddMoneySubmissionPreview({
  api,
  id,
  clientId,
}: IGetDraftPreviewProps): Promise<IBaseResponse<IAddMoneySubmissionPreview>> {
  const response = await api.get<IBaseResponse<IAddMoneySubmissionPreview>>(
    `clients/${clientId}/add_money_submissions/${id}/preview`,
  );

  return response.data;
}
