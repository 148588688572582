import { User } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ICreateSessionByQRProps extends IBaseRequest {
  dto: {
    loginKey: string;
  };
}

interface ICreateSessionByQRExtra {
  token: string;
  refreshToken: string;
}

type ICreateSessionByQRResponse = IBaseResponse<User> & ICreateSessionByQRExtra;

export async function createSessionByQR({
  api,
  dto,
}: ICreateSessionByQRProps): Promise<
  IBaseResponse<User, ICreateSessionByQRExtra>
> {
  try {
    const response = await api.post<ICreateSessionByQRResponse>(
      'auth/sessions/by_qr',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse<User, ICreateSessionByQRExtra>({
        result: response.data.result,
        extra: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse<User, ICreateSessionByQRExtra>({
      error: response.data.error,
      extra: {
        token: '',
        refreshToken: '',
      },
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
