import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { INotification } from '@/entities';

interface IArchiveNotificationProps extends IBaseRequest {
  id: string;
}

export async function archiveNotification({
  api,
  id,
}: IArchiveNotificationProps): Promise<IBaseResponse<INotification>> {
  try {
    const response = await api.post<IBaseResponse<INotification>>(
      `notifications/${id}/archive`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
