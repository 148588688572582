import { IBaseRequest } from '@/api/types';
import { Account } from '@/entities';

interface IGetClientAccountsProps extends IBaseRequest {
  clientId: string;
}

export async function getClientAccounts({
  api,
  clientId,
}: IGetClientAccountsProps): Promise<Account[]> {
  const response = await api.get<Account[]>(`clients/${clientId}/accounts`);

  return response.data;
}
