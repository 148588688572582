import { Wrapper, FullScreenWrapper } from '@/ui/components/mobile/Icon/shared';
import { SVGContainer } from '@/ui/components/mobile/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';
import {
  AnimatedPath,
  AnimatedGroup,
  StyledSVGContainer,
} from '@/ui/components/mobile/Icon/variations/Loader/index.styles.ts';

export enum IconLoaderKind {
  FULL_SCREEN = 'full-screen',
  PULSE = 'pulse',
  SMALL = 'small',
  LARGE = 'large',
}

interface IIconLoaderProps extends IIconProps {
  kind?: IconLoaderKind;
}

export function IconLoader({ kind, ...props }: IIconLoaderProps) {
  if (kind === IconLoaderKind.FULL_SCREEN) {
    return (
      <FullScreenWrapper {...props}>
        <StyledSVGContainer viewBox="0 0 80 80">
          <g>
            <circle
              fill="none"
              stroke="currentColor"
              strokeWidth="6"
              cx="40"
              cy="40"
              r="37"
            />
          </g>
          <AnimatedGroup>
            <path
              fill="currentColor"
              d="M241.7,70c-0.4,0-0.9-0.1-1.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4
		c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6
		c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3
		c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6
		c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3
		c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7
		c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4
		c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3
		c0.1-1.2,0.3-2.3,0.4-3.5c0.1-1.1,0.3-2.2,0.4-3.3L247,31c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4
		c0.2-0.7,0.4-1.5,1-2.2c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6
		c0.5,1.6,0.8,3.2,1.1,4.9c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2
		c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5
		c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1
		c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3
		c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6
		c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7
		c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3
		c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0
		c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5
		c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5
		l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3
		c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1
		l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2
		c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4
		c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1
		c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8
		c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2
		c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6
		c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3
		c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7
		c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9
		c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1
		c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5
		c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4
		c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3
		c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1
		c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.7,0.4,0.3,0.6c-0.4,0.2-1.2,0.2-1.8,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2
		h0c-0.9,0-1.6,0.2-2.5,0.4c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.4,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5
		c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5
		c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1
		c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6c-0.3,0.2-0.6,0.4-0.9,0.6L409,43c-0.7,0.5-1.5,0.9-2.8,1.3c-0.9,0.3-2.5,0.4-3.8,0.1
		c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1c-0.6-0.4-1.2-0.7-2-0.8
		c-0.3,0-0.8,0.3-1.3,0.5c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1c-0.6,0.4-1.5,0.9-3.1,1
		c-1.9,0-2.8-0.4-3.4-0.7c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2
		c-0.5-0.4-1-0.8-1.8-1.1c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4c-0.7,0.8-1.3,1.6-2.3,2.4
		c-0.6,0.4-1.3,1-2.8,1.1c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9c-0.3-0.4-0.6-0.8-0.9-1.2
		c-0.7-0.9-1.3-1.9-2.2-2.8l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8
		c-0.7,1.2-1.5,2.5-2.5,3.7l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2c-0.9-0.1-1.5-0.3-1.8-0.4
		c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3c-0.3-0.4-0.6-0.9-1.2-1.2
		c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3c-0.8,1.2-1.4,2.4-1.9,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4c-0.5,1.1-1.1,2.1-2,3.2
		l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1c-1-1-1.4-2-1.9-2.9l-0.2-0.4
		c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4l-0.2-0.2c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5l-0.3,0.6
		c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1
		c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5
		c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2
		c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5
		c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5
		l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1
		c-0.2,1-0.4,2.1-0.6,3.1c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1
		c-0.3,1.6-0.7,3.4-1.3,5.1l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5
		c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8
		l-0.8-5.6c-0.3-1.9-0.6-3.7-0.9-5.6c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3
		c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3
		c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5
		C242,70,241.9,70,241.7,70z"
            />
            <path
              fill="currentColor"
              d="M221.5,70c0.4,0,0.9-0.1,1.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4
		c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6
		c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3
		c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6
		c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5
		l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7
		c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4
		c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3
		c-0.1-1.2-0.3-2.3-0.4-3.5c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4
		c-0.2-0.7-0.4-1.5-1-2.2c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6
		c-0.5,1.6-0.8,3.2-1.1,4.9c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2
		c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5
		c-0.6-1.8-1-3.5-1.3-5.1c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1
		c-0.2-1-0.4-2.1-0.6-3.1c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6
		c-0.6,0-1.1,0.1-1.5,0.3c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5
		c-0.2,0.9-0.4,1.7-0.5,2.6c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2
		c-0.5-0.8-0.7-1.6-1-2.3l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5
		c-0.2-0.9-0.5-1.8-0.8-2.7l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1
		c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1
		c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4
		c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4
		l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.4,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4
		c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4
		c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3
		c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3
		c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3
		c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9
		c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6
		c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3
		c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9
		c-0.6,0.6-1.3,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4
		c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2L65,40.2c-0.6,0.4-1.2,0.8-1.7,1.3
		c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1l-0.4-0.2
		c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3c-0.4,0.2-0.8,0.4-1.1,0.6
		c-0.8,0.4-1.5,0.8-2.4,1.2C39.6,43,39,43,38.7,43c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4c-1-0.5-2.1-1-3.5-1.4c-1.3-0.4-2.8-0.5-4-0.3
		c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9c-0.8,0.2-1.5,0.3-2.2,0.3c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2
		c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1C4.1,41,4,41.3,4.4,41.5
		c0.4,0.2,1.2,0.2,1.8,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7
		l0.7,0.3c1.2,0.4,2.4,0.7,4.1,0.8c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8
		c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2l1,0.5c1,0.4,2.2,0.9,4,1.1c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3
		c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1c0.5,0,1.1,0.2,1.3,0.2c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2
		c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1c1.5-0.4,2.3-0.9,3.1-1.4l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2
		l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8c0.3,0,0.8,0.3,1.3,0.5c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1
		c0.6,0.4,1.5,0.9,3.1,1c1.9,0,2.8-0.4,3.4-0.7c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9
		c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2c0.5-0.4,1-0.8,1.8-1.1c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4
		c0.7,0.8,1.3,1.6,2.3,2.4c0.6,0.4,1.3,1,2.8,1.1c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9
		c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3
		c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2
		c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3
		c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4
		c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1c0.2,0,0.4,0,0.6,0.1c1.5,0,2.1-0.5,2.3-0.7c0.1,0,0.1-0.1,0.2-0.1
		c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2
		c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4c0.5,1.2,1,2.5,1.6,4.1c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6
		c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7c0.2,0,0.4,0,0.6-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2
		c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4l0.7-2.5c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6
		c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9
		c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8
		c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1
		c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4
		c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1
		c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8
		c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8
		c0.4-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5
		c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4
		c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3
		c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1
		c0.1,0.2,0.4,0.5,1.2,0.5C221.3,70,221.4,70,221.5,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M241.7,70c-0.4,0-0.9-0.1-1.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6
		c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2
		c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5
		c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3
		c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1
		c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9
		c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7
		c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3c0.1-1.2,0.3-2.3,0.4-3.5
		c0.1-1.1,0.3-2.2,0.4-3.3L247,31c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4c0.2-0.7,0.4-1.5,1-2.2
		c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6c0.5,1.6,0.8,3.2,1.1,4.9
		c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8
		c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4
		l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4
		c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7
		c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9
		l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1
		c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9
		l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4
		c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2
		c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7
		c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1
		c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7
		l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3
		c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1
		c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1
		c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1
		c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8
		c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2
		l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3
		c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2
		c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6
		c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3
		c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2
		c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.7,0.4,0.3,0.6
		c-0.4,0.2-1.2,0.2-1.8,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2h0c-0.9,0-1.6,0.2-2.5,0.4
		c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.4,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3
		c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6
		c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6
		c-0.3,0.2-0.6,0.4-0.9,0.6L409,43c-0.7,0.5-1.5,0.9-2.8,1.3c-0.9,0.3-2.5,0.4-3.8,0.1c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2
		c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1c-0.6-0.4-1.2-0.7-2-0.8c-0.3,0-0.8,0.3-1.3,0.5
		c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1c-0.6,0.4-1.5,0.9-3.1,1c-1.9,0-2.8-0.4-3.4-0.7
		c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2c-0.5-0.4-1-0.8-1.8-1.1
		c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4c-0.7,0.8-1.3,1.6-2.3,2.4c-0.6,0.4-1.3,1-2.8,1.1
		c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.3-1.9-2.2-2.8
		l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8c-0.7,1.2-1.5,2.5-2.5,3.7
		l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2c-0.9-0.1-1.5-0.3-1.8-0.4c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3
		c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3c-0.3-0.4-0.6-0.9-1.2-1.2c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3
		c-0.8,1.2-1.4,2.4-1.9,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4c-0.5,1.1-1.1,2.1-2,3.2l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1
		c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1c-1-1-1.4-2-1.9-2.9l-0.2-0.4c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1
		c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4l-0.2-0.2c-0.1-0.2-0.3-0.4-0.5-0.6c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4
		c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5l-0.3,0.6c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1
		c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8
		l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1
		c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9
		c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1
		c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7
		c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1c-0.2,1-0.4,2.1-0.6,3.1
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1c-0.3,1.6-0.7,3.4-1.3,5.1
		l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5
		c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8l-0.8-5.6c-0.3-1.9-0.6-3.7-0.9-5.6
		c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6
		l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5
		l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5C242,70,241.9,70,241.7,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M221.5,70c0.4,0,0.9-0.1,1.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6
		c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2
		c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5
		c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1
		c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9
		c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7
		c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3c-0.1-1.2-0.3-2.3-0.4-3.5
		c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4c-0.2-0.7-0.4-1.5-1-2.2
		c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.5,1.6-0.8,3.2-1.1,4.9
		c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8
		c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5c-0.6-1.8-1-3.5-1.3-5.1
		c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1c-0.2-1-0.4-2.1-0.6-3.1
		c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.6,0-1.1,0.1-1.5,0.3
		c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5c-0.2,0.9-0.4,1.7-0.5,2.6
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2c-0.5-0.8-0.7-1.6-1-2.3
		l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5c-0.2-0.9-0.5-1.8-0.8-2.7
		l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0
		c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5
		c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1
		c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4
		c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5
		l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.4,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7
		l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3
		c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3
		c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1
		c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1
		c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1
		c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2
		c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9c-0.6,0.6-1.3,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6
		l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2L65,40.2
		c-0.6,0.4-1.2,0.8-1.7,1.3c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1
		l-0.4-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3
		c-0.4,0.2-0.8,0.4-1.1,0.6c-0.8,0.4-1.5,0.8-2.4,1.2C39.6,43,39,43,38.7,43c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4
		c-1-0.5-2.1-1-3.5-1.4c-1.3-0.4-2.8-0.5-4-0.3c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9c-0.8,0.2-1.5,0.3-2.2,0.3
		c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1
		c-0.2,0-0.3,0.1-0.5,0.1C4.1,41,4,41.3,4.4,41.5c0.4,0.2,1.2,0.2,1.8,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0
		c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7l0.7,0.3c1.2,0.4,2.4,0.7,4.1,0.8c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1
		l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2l1,0.5c1,0.4,2.2,0.9,4,1.1
		c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1c0.5,0,1.1,0.2,1.3,0.2
		c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1c1.5-0.4,2.3-0.9,3.1-1.4
		l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8c0.3,0,0.8,0.3,1.3,0.5
		c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1c0.6,0.4,1.5,0.9,3.1,1c1.9,0,2.8-0.4,3.4-0.7
		c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2c0.5-0.4,1-0.8,1.8-1.1
		c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4c0.7,0.8,1.3,1.6,2.3,2.4c0.6,0.4,1.3,1,2.8,1.1
		c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2
		c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3
		c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2
		l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1
		c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1c0.2,0,0.4,0,0.6,0.1c1.5,0,2.1-0.5,2.3-0.7
		c0.1,0,0.1-0.1,0.2-0.1c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4
		c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4c0.5,1.2,1,2.5,1.6,4.1
		c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7c0.2,0,0.4,0,0.6-0.1
		c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4l0.7-2.5
		c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3
		c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7
		c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2
		c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6
		c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4
		c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7
		c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5
		c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8c0.4-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6
		c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3
		c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7
		c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.4,0.5,1.2,0.5C221.3,70,221.4,70,221.5,70z"
            />
            <path
              fill="currentColor"
              d="M688.6,70c-0.4,0-0.9-0.1-1.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4
		c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6
		c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3
		c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6
		c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3
		c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7
		c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4
		c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3
		c0.1-1.2,0.3-2.3,0.4-3.5c0.1-1.1,0.3-2.2,0.4-3.3l0.7-5.3c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4
		c0.2-0.7,0.4-1.5,1-2.2c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6
		c0.5,1.6,0.8,3.2,1.1,4.9c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2
		c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5
		c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1
		c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3
		c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6
		c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7
		c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3
		c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0
		c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5
		c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5
		l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3
		c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1
		l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2
		c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4
		c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1
		c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8
		c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2
		c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6
		c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3
		c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7
		c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9
		c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1
		c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5
		c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4
		c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3
		c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1
		c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.4,0.4,0,0.6c-0.4,0.2-1,0.2-1.5,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2h0
		c-0.9,0-1.6,0.2-2.5,0.4c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.5,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5
		c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5
		c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1
		c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6c-0.3,0.2-0.6,0.4-0.9,0.6l-0.4,0.2c-0.7,0.5-1.5,0.9-2.8,1.3
		c-0.9,0.3-2.5,0.4-3.8,0.1c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1
		c-0.6-0.4-1.2-0.7-2-0.8c-0.3,0-0.8,0.3-1.3,0.5c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1
		c-0.6,0.4-1.5,0.9-3.1,1c-1.9,0-2.8-0.4-3.4-0.7c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9
		c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2c-0.5-0.4-1-0.8-1.8-1.1c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4
		c-0.7,0.8-1.3,1.6-2.3,2.4c-0.6,0.4-1.3,1-2.8,1.1c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9
		c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.3-1.9-2.2-2.8l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3
		c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8c-0.7,1.2-1.5,2.5-2.5,3.7l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2
		c-0.9-0.1-1.5-0.3-1.8-0.4c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3
		c-0.3-0.4-0.6-0.9-1.2-1.2c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3c-0.8,1.2-1.4,2.4-2,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4
		c-0.5,1.1-1.1,2.1-2,3.2l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1
		c-1-1-1.4-2-1.9-2.9l-0.2-0.4c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4L760,34
		c-0.1-0.2-0.3-0.4-0.5-0.6c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5
		l-0.3,0.6c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1
		c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5
		c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2
		c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5
		c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5
		l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1
		c-0.2,1-0.4,2.1-0.6,3.1c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1
		c-0.3,1.6-0.7,3.4-1.3,5.1l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5
		c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8
		l-0.8-5.6c-0.3-1.9-0.6-3.7-0.8-5.6c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3
		c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3
		c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5
		C688.8,70,688.7,70,688.6,70z"
            />
            <path
              fill="currentColor"
              d="M668.4,70c0.4,0,0.9-0.1,1.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4
		c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6
		c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3
		c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6
		c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5
		l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7
		c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4
		c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3
		c-0.1-1.2-0.3-2.3-0.4-3.5c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4
		c-0.2-0.7-0.4-1.5-1-2.2c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6
		c-0.5,1.6-0.8,3.2-1.1,4.9c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2
		c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5
		c-0.6-1.8-1-3.5-1.3-5.1c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1
		c-0.2-1-0.4-2.1-0.6-3.1c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6
		c-0.6,0-1.1,0.1-1.5,0.3c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5
		c-0.2,0.9-0.4,1.7-0.5,2.6c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2
		c-0.5-0.8-0.7-1.6-1-2.3l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5
		c-0.2-0.9-0.5-1.8-0.8-2.7l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1
		c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1
		c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4
		c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4
		l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.5,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4
		c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4
		c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3
		c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3
		c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3
		c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9
		c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6
		c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3
		c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9
		c-0.6,0.6-1.2,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4
		c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2l-0.2,0.1c-0.6,0.4-1.2,0.8-1.7,1.3
		c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1l-0.4-0.2
		c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3c-0.4,0.2-0.8,0.4-1.1,0.6
		c-0.8,0.4-1.5,0.8-2.4,1.2c-1,0.4-1.6,0.4-1.9,0.4c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4c-1-0.5-2.1-1-3.5-1.4
		c-1.3-0.4-2.8-0.5-4.1-0.3c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9c-0.8,0.2-1.5,0.3-2.2,0.3
		c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1
		c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2-0.4,0.4,0,0.6c0.4,0.2,0.9,0.3,1.5,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0
		c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7l0.7,0.3c1.2,0.4,2.5,0.7,4.1,0.8c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1
		l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2l1,0.5c1,0.4,2.2,0.9,4,1.1
		c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1c0.5,0,1.1,0.2,1.3,0.2
		c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1c1.5-0.4,2.3-0.9,3.1-1.4
		l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8c0.3,0,0.8,0.3,1.3,0.5
		c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1c0.6,0.4,1.5,0.9,3.1,1c1.9,0,2.8-0.4,3.4-0.7
		c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2c0.5-0.4,1-0.8,1.8-1.1
		c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4c0.7,0.8,1.3,1.6,2.3,2.4c0.6,0.4,1.3,1,2.8,1.1
		c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2
		c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3
		c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2
		l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1
		c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1c0.2,0,0.4,0,0.6,0.1c1.5,0,2-0.5,2.3-0.7
		c0.1,0,0.1-0.1,0.2-0.1c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4
		c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4c0.5,1.2,1,2.5,1.6,4.1
		c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7c0.2,0,0.4,0,0.6-0.1
		c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4l0.7-2.5
		c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3
		c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7
		c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2
		c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6
		c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4
		c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7
		c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5
		c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8c0.3-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6
		c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3
		c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7
		c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.4,0.5,1.2,0.5C668.2,70,668.3,70,668.4,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M688.6,70c-0.4,0-0.9-0.1-1.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6
		c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2
		c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5
		c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3
		c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1
		c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9
		c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7
		c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3c0.1-1.2,0.3-2.3,0.4-3.5
		c0.1-1.1,0.3-2.2,0.4-3.3l0.7-5.3c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4c0.2-0.7,0.4-1.5,1-2.2
		c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6c0.5,1.6,0.8,3.2,1.1,4.9
		c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8
		c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4
		l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4
		c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7
		c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9
		l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1
		c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9
		l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4
		c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2
		c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7
		c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1
		c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7
		l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3
		c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1
		c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1
		c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1
		c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8
		c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2
		l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3
		c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2
		c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6
		c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3
		c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2
		c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.4,0.4,0,0.6
		c-0.4,0.2-1,0.2-1.5,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2h0c-0.9,0-1.6,0.2-2.5,0.4
		c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.5,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3
		c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6
		c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6
		c-0.3,0.2-0.6,0.4-0.9,0.6l-0.4,0.2c-0.7,0.5-1.5,0.9-2.8,1.3c-0.9,0.3-2.5,0.4-3.8,0.1c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2
		c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1c-0.6-0.4-1.2-0.7-2-0.8c-0.3,0-0.8,0.3-1.3,0.5
		c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1c-0.6,0.4-1.5,0.9-3.1,1c-1.9,0-2.8-0.4-3.4-0.7
		c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2c-0.5-0.4-1-0.8-1.8-1.1
		c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4c-0.7,0.8-1.3,1.6-2.3,2.4c-0.6,0.4-1.3,1-2.8,1.1
		c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.3-1.9-2.2-2.8
		l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8c-0.7,1.2-1.5,2.5-2.5,3.7
		l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2c-0.9-0.1-1.5-0.3-1.8-0.4c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3
		c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3c-0.3-0.4-0.6-0.9-1.2-1.2c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3
		c-0.8,1.2-1.4,2.4-2,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4c-0.5,1.1-1.1,2.1-2,3.2l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1
		c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1c-1-1-1.4-2-1.9-2.9l-0.2-0.4c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1
		c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4L760,34c-0.1-0.2-0.3-0.4-0.5-0.6c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4
		c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5l-0.3,0.6c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1
		c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8
		l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1
		c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9
		c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1
		c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7
		c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1c-0.2,1-0.4,2.1-0.6,3.1
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1c-0.3,1.6-0.7,3.4-1.3,5.1
		l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5
		c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8l-0.8-5.6c-0.3-1.9-0.6-3.7-0.8-5.6
		c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6
		l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5
		l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5C688.8,70,688.7,70,688.6,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M668.4,70c0.4,0,0.9-0.1,1.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6
		c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2
		c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5
		c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1
		c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9
		c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7
		c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3c-0.1-1.2-0.3-2.3-0.4-3.5
		c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4c-0.2-0.7-0.4-1.5-1-2.2
		c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.5,1.6-0.8,3.2-1.1,4.9
		c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8
		c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5c-0.6-1.8-1-3.5-1.3-5.1
		c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1c-0.2-1-0.4-2.1-0.6-3.1
		c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.6,0-1.1,0.1-1.5,0.3
		c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5c-0.2,0.9-0.4,1.7-0.5,2.6
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2c-0.5-0.8-0.7-1.6-1-2.3
		l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5c-0.2-0.9-0.5-1.8-0.8-2.7
		l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0
		c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5
		c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1
		c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4
		c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5
		l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.5,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7
		l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3
		c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3
		c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1
		c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1
		c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1
		c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2
		c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9c-0.6,0.6-1.2,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6
		l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2l-0.2,0.1
		c-0.6,0.4-1.2,0.8-1.7,1.3c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1
		l-0.4-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3
		c-0.4,0.2-0.8,0.4-1.1,0.6c-0.8,0.4-1.5,0.8-2.4,1.2c-1,0.4-1.6,0.4-1.9,0.4c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4
		c-1-0.5-2.1-1-3.5-1.4c-1.3-0.4-2.8-0.5-4.1-0.3c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9
		c-0.8,0.2-1.5,0.3-2.2,0.3c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6
		c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2-0.4,0.4,0,0.6c0.4,0.2,0.9,0.3,1.5,0.1c0.1,0,0.2,0,0.3-0.1
		l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7l0.7,0.3c1.2,0.4,2.5,0.7,4.1,0.8
		c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2
		l1,0.5c1,0.4,2.2,0.9,4,1.1c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1
		c0.5,0,1.1,0.2,1.3,0.2c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1
		c1.5-0.4,2.3-0.9,3.1-1.4l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8
		c0.3,0,0.8,0.3,1.3,0.5c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1c0.6,0.4,1.5,0.9,3.1,1
		c1.9,0,2.8-0.4,3.4-0.7c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2
		c0.5-0.4,1-0.8,1.8-1.1c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4c0.7,0.8,1.3,1.6,2.3,2.4
		c0.6,0.4,1.3,1,2.8,1.1c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9c0.3-0.4,0.6-0.8,0.9-1.2
		c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8
		c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2
		l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3
		c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1
		c0.2,0,0.4,0,0.6,0.1c1.5,0,2-0.5,2.3-0.7c0.1,0,0.1-0.1,0.2-0.1c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1
		c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4
		c0.5,1.2,1,2.5,1.6,4.1c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7
		c0.2,0,0.4,0,0.6-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4
		l0.7-2.5c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3
		c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7
		c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2
		c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6
		c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4
		c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7
		c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5
		c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8c0.3-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6
		c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3
		c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7
		c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.4,0.5,1.2,0.5C668.2,70,668.3,70,668.4,70z"
            />
          </AnimatedGroup>
        </StyledSVGContainer>
      </FullScreenWrapper>
    );
  }

  if (kind === IconLoaderKind.PULSE) {
    return (
      <Wrapper {...props}>
        <StyledSVGContainer viewBox="0 0 80 80">
          <g>
            <circle
              fill="none"
              stroke="currentColor"
              strokeWidth="6"
              cx="40"
              cy="40"
              r="37"
            />
          </g>
          <AnimatedGroup>
            <path
              fill="currentColor"
              d="M241.7,70c-0.4,0-0.9-0.1-1.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4
		c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6
		c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3
		c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6
		c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3
		c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7
		c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4
		c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3
		c0.1-1.2,0.3-2.3,0.4-3.5c0.1-1.1,0.3-2.2,0.4-3.3L247,31c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4
		c0.2-0.7,0.4-1.5,1-2.2c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6
		c0.5,1.6,0.8,3.2,1.1,4.9c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2
		c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5
		c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1
		c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3
		c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6
		c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7
		c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3
		c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0
		c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5
		c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5
		l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3
		c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1
		l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2
		c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4
		c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1
		c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8
		c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2
		c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6
		c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3
		c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7
		c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9
		c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1
		c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5
		c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4
		c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3
		c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1
		c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.7,0.4,0.3,0.6c-0.4,0.2-1.2,0.2-1.8,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2
		h0c-0.9,0-1.6,0.2-2.5,0.4c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.4,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5
		c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5
		c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1
		c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6c-0.3,0.2-0.6,0.4-0.9,0.6L409,43c-0.7,0.5-1.5,0.9-2.8,1.3c-0.9,0.3-2.5,0.4-3.8,0.1
		c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1c-0.6-0.4-1.2-0.7-2-0.8
		c-0.3,0-0.8,0.3-1.3,0.5c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1c-0.6,0.4-1.5,0.9-3.1,1
		c-1.9,0-2.8-0.4-3.4-0.7c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2
		c-0.5-0.4-1-0.8-1.8-1.1c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4c-0.7,0.8-1.3,1.6-2.3,2.4
		c-0.6,0.4-1.3,1-2.8,1.1c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9c-0.3-0.4-0.6-0.8-0.9-1.2
		c-0.7-0.9-1.3-1.9-2.2-2.8l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8
		c-0.7,1.2-1.5,2.5-2.5,3.7l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2c-0.9-0.1-1.5-0.3-1.8-0.4
		c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3c-0.3-0.4-0.6-0.9-1.2-1.2
		c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3c-0.8,1.2-1.4,2.4-1.9,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4c-0.5,1.1-1.1,2.1-2,3.2
		l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1c-1-1-1.4-2-1.9-2.9l-0.2-0.4
		c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4l-0.2-0.2c-0.1-0.2-0.3-0.4-0.5-0.6
		c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5l-0.3,0.6
		c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1
		c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5
		c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2
		c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5
		c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5
		l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1
		c-0.2,1-0.4,2.1-0.6,3.1c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1
		c-0.3,1.6-0.7,3.4-1.3,5.1l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5
		c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8
		l-0.8-5.6c-0.3-1.9-0.6-3.7-0.9-5.6c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3
		c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3
		c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5
		C242,70,241.9,70,241.7,70z"
            />
            <path
              fill="currentColor"
              d="M221.5,70c0.4,0,0.9-0.1,1.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4
		c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6
		c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3
		c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6
		c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5
		l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7
		c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4
		c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3
		c-0.1-1.2-0.3-2.3-0.4-3.5c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4
		c-0.2-0.7-0.4-1.5-1-2.2c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6
		c-0.5,1.6-0.8,3.2-1.1,4.9c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2
		c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5
		c-0.6-1.8-1-3.5-1.3-5.1c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1
		c-0.2-1-0.4-2.1-0.6-3.1c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6
		c-0.6,0-1.1,0.1-1.5,0.3c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5
		c-0.2,0.9-0.4,1.7-0.5,2.6c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2
		c-0.5-0.8-0.7-1.6-1-2.3l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5
		c-0.2-0.9-0.5-1.8-0.8-2.7l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1
		c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1
		c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4
		c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4
		l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.4,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4
		c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4
		c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3
		c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3
		c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3
		c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9
		c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6
		c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3
		c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9
		c-0.6,0.6-1.3,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4
		c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2L65,40.2c-0.6,0.4-1.2,0.8-1.7,1.3
		c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1l-0.4-0.2
		c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3c-0.4,0.2-0.8,0.4-1.1,0.6
		c-0.8,0.4-1.5,0.8-2.4,1.2C39.6,43,39,43,38.7,43c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4c-1-0.5-2.1-1-3.5-1.4c-1.3-0.4-2.8-0.5-4-0.3
		c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9c-0.8,0.2-1.5,0.3-2.2,0.3c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2
		c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1C4.1,41,4,41.3,4.4,41.5
		c0.4,0.2,1.2,0.2,1.8,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7
		l0.7,0.3c1.2,0.4,2.4,0.7,4.1,0.8c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8
		c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2l1,0.5c1,0.4,2.2,0.9,4,1.1c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3
		c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1c0.5,0,1.1,0.2,1.3,0.2c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2
		c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1c1.5-0.4,2.3-0.9,3.1-1.4l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2
		l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8c0.3,0,0.8,0.3,1.3,0.5c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1
		c0.6,0.4,1.5,0.9,3.1,1c1.9,0,2.8-0.4,3.4-0.7c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9
		c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2c0.5-0.4,1-0.8,1.8-1.1c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4
		c0.7,0.8,1.3,1.6,2.3,2.4c0.6,0.4,1.3,1,2.8,1.1c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9
		c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3
		c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2
		c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3
		c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4
		c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1c0.2,0,0.4,0,0.6,0.1c1.5,0,2.1-0.5,2.3-0.7c0.1,0,0.1-0.1,0.2-0.1
		c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2
		c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4c0.5,1.2,1,2.5,1.6,4.1c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6
		c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7c0.2,0,0.4,0,0.6-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2
		c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4l0.7-2.5c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6
		c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9
		c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8
		c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1
		c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4
		c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1
		c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8
		c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8
		c0.4-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5
		c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4
		c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3
		c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1
		c0.1,0.2,0.4,0.5,1.2,0.5C221.3,70,221.4,70,221.5,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M241.7,70c-0.4,0-0.9-0.1-1.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6
		c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2
		c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5
		c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3
		c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1
		c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9
		c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7
		c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3c0.1-1.2,0.3-2.3,0.4-3.5
		c0.1-1.1,0.3-2.2,0.4-3.3L247,31c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4c0.2-0.7,0.4-1.5,1-2.2
		c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6c0.5,1.6,0.8,3.2,1.1,4.9
		c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8
		c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4
		l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4
		c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7
		c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9
		l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1
		c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9
		l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4
		c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2
		c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7
		c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1
		c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7
		l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3
		c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1
		c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1
		c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1
		c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8
		c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2
		l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3
		c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2
		c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6
		c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3
		c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2
		c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.7,0.4,0.3,0.6
		c-0.4,0.2-1.2,0.2-1.8,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2h0c-0.9,0-1.6,0.2-2.5,0.4
		c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.4,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3
		c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6
		c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6
		c-0.3,0.2-0.6,0.4-0.9,0.6L409,43c-0.7,0.5-1.5,0.9-2.8,1.3c-0.9,0.3-2.5,0.4-3.8,0.1c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2
		c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1c-0.6-0.4-1.2-0.7-2-0.8c-0.3,0-0.8,0.3-1.3,0.5
		c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1c-0.6,0.4-1.5,0.9-3.1,1c-1.9,0-2.8-0.4-3.4-0.7
		c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2c-0.5-0.4-1-0.8-1.8-1.1
		c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4c-0.7,0.8-1.3,1.6-2.3,2.4c-0.6,0.4-1.3,1-2.8,1.1
		c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.3-1.9-2.2-2.8
		l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8c-0.7,1.2-1.5,2.5-2.5,3.7
		l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2c-0.9-0.1-1.5-0.3-1.8-0.4c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3
		c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3c-0.3-0.4-0.6-0.9-1.2-1.2c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3
		c-0.8,1.2-1.4,2.4-1.9,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4c-0.5,1.1-1.1,2.1-2,3.2l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1
		c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1c-1-1-1.4-2-1.9-2.9l-0.2-0.4c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1
		c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4l-0.2-0.2c-0.1-0.2-0.3-0.4-0.5-0.6c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4
		c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5l-0.3,0.6c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1
		c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8
		l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1
		c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9
		c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1
		c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7
		c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1c-0.2,1-0.4,2.1-0.6,3.1
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1c-0.3,1.6-0.7,3.4-1.3,5.1
		l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5
		c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8l-0.8-5.6c-0.3-1.9-0.6-3.7-0.9-5.6
		c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6
		l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5
		l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5C242,70,241.9,70,241.7,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M221.5,70c0.4,0,0.9-0.1,1.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6
		c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2
		c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5
		c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1
		c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9
		c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7
		c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3c-0.1-1.2-0.3-2.3-0.4-3.5
		c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4c-0.2-0.7-0.4-1.5-1-2.2
		c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.5,1.6-0.8,3.2-1.1,4.9
		c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8
		c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5c-0.6-1.8-1-3.5-1.3-5.1
		c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1c-0.2-1-0.4-2.1-0.6-3.1
		c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.6,0-1.1,0.1-1.5,0.3
		c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5c-0.2,0.9-0.4,1.7-0.5,2.6
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2c-0.5-0.8-0.7-1.6-1-2.3
		l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5c-0.2-0.9-0.5-1.8-0.8-2.7
		l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0
		c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5
		c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1
		c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4
		c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5
		l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.4,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7
		l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3
		c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3
		c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1
		c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1
		c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1
		c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2
		c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9c-0.6,0.6-1.3,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6
		l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2L65,40.2
		c-0.6,0.4-1.2,0.8-1.7,1.3c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1
		l-0.4-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3
		c-0.4,0.2-0.8,0.4-1.1,0.6c-0.8,0.4-1.5,0.8-2.4,1.2C39.6,43,39,43,38.7,43c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4
		c-1-0.5-2.1-1-3.5-1.4c-1.3-0.4-2.8-0.5-4-0.3c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9c-0.8,0.2-1.5,0.3-2.2,0.3
		c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1
		c-0.2,0-0.3,0.1-0.5,0.1C4.1,41,4,41.3,4.4,41.5c0.4,0.2,1.2,0.2,1.8,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0
		c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7l0.7,0.3c1.2,0.4,2.4,0.7,4.1,0.8c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1
		l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2l1,0.5c1,0.4,2.2,0.9,4,1.1
		c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1c0.5,0,1.1,0.2,1.3,0.2
		c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1c1.5-0.4,2.3-0.9,3.1-1.4
		l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8c0.3,0,0.8,0.3,1.3,0.5
		c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1c0.6,0.4,1.5,0.9,3.1,1c1.9,0,2.8-0.4,3.4-0.7
		c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2c0.5-0.4,1-0.8,1.8-1.1
		c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4c0.7,0.8,1.3,1.6,2.3,2.4c0.6,0.4,1.3,1,2.8,1.1
		c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2
		c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3
		c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2
		l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1
		c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1c0.2,0,0.4,0,0.6,0.1c1.5,0,2.1-0.5,2.3-0.7
		c0.1,0,0.1-0.1,0.2-0.1c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4
		c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4c0.5,1.2,1,2.5,1.6,4.1
		c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7c0.2,0,0.4,0,0.6-0.1
		c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4l0.7-2.5
		c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3
		c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7
		c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2
		c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6
		c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4
		c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7
		c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5
		c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8c0.4-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6
		c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3
		c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7
		c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.4,0.5,1.2,0.5C221.3,70,221.4,70,221.5,70z"
            />
            <path
              fill="currentColor"
              d="M688.6,70c-0.4,0-0.9-0.1-1.1-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4
		c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6
		c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3
		c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6
		c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3
		c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7
		c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4
		c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3
		c0.1-1.2,0.3-2.3,0.4-3.5c0.1-1.1,0.3-2.2,0.4-3.3l0.7-5.3c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4
		c0.2-0.7,0.4-1.5,1-2.2c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6
		c0.5,1.6,0.8,3.2,1.1,4.9c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2
		c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5
		c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1
		c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3
		c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6
		c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7
		c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3
		c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0
		c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5
		c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5
		l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3
		c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1
		l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2
		c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4
		c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1
		c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8
		c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2
		c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6
		c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3
		c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7
		c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9
		c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1
		c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5
		c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4
		c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3
		c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1
		c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.4,0.4,0,0.6c-0.4,0.2-1,0.2-1.5,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2h0
		c-0.9,0-1.6,0.2-2.5,0.4c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.5,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5
		c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5
		c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1
		c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6c-0.3,0.2-0.6,0.4-0.9,0.6l-0.4,0.2c-0.7,0.5-1.5,0.9-2.8,1.3
		c-0.9,0.3-2.5,0.4-3.8,0.1c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1
		c-0.6-0.4-1.2-0.7-2-0.8c-0.3,0-0.8,0.3-1.3,0.5c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1
		c-0.6,0.4-1.5,0.9-3.1,1c-1.9,0-2.8-0.4-3.4-0.7c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9
		c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2c-0.5-0.4-1-0.8-1.8-1.1c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4
		c-0.7,0.8-1.3,1.6-2.3,2.4c-0.6,0.4-1.3,1-2.8,1.1c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9
		c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.3-1.9-2.2-2.8l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3
		c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8c-0.7,1.2-1.5,2.5-2.5,3.7l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2
		c-0.9-0.1-1.5-0.3-1.8-0.4c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3
		c-0.3-0.4-0.6-0.9-1.2-1.2c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3c-0.8,1.2-1.4,2.4-2,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4
		c-0.5,1.1-1.1,2.1-2,3.2l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1
		c-1-1-1.4-2-1.9-2.9l-0.2-0.4c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4L760,34
		c-0.1-0.2-0.3-0.4-0.5-0.6c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5
		l-0.3,0.6c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9
		c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1
		c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5
		c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2
		c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5
		c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5
		l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1
		c-0.2,1-0.4,2.1-0.6,3.1c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1
		c-0.3,1.6-0.7,3.4-1.3,5.1l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5
		c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8
		l-0.8-5.6c-0.3-1.9-0.6-3.7-0.8-5.6c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6
		c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3
		c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3
		c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5
		C688.8,70,688.7,70,688.6,70z"
            />
            <path
              fill="currentColor"
              d="M668.4,70c0.4,0,0.9-0.1,1.1-0.1c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4
		c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6
		c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3
		c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6
		c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5
		l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7
		c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4
		c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3
		c-0.1-1.2-0.3-2.3-0.4-3.5c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4
		c-0.2-0.7-0.4-1.5-1-2.2c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6
		c-0.5,1.6-0.8,3.2-1.1,4.9c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2
		c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5
		c-0.6-1.8-1-3.5-1.3-5.1c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1
		c-0.2-1-0.4-2.1-0.6-3.1c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6
		c-0.6,0-1.1,0.1-1.5,0.3c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5
		c-0.2,0.9-0.4,1.7-0.5,2.6c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2
		c-0.5-0.8-0.7-1.6-1-2.3l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5
		c-0.2-0.9-0.5-1.8-0.8-2.7l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1
		c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
		c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1
		c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4
		c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4
		l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.5,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4
		c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4
		c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3
		c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3
		c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3
		c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9
		c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6
		c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3
		c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9
		c-0.6,0.6-1.2,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4
		c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2l-0.2,0.1c-0.6,0.4-1.2,0.8-1.7,1.3
		c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1l-0.4-0.2
		c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3c-0.4,0.2-0.8,0.4-1.1,0.6
		c-0.8,0.4-1.5,0.8-2.4,1.2c-1,0.4-1.6,0.4-1.9,0.4c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4c-1-0.5-2.1-1-3.5-1.4
		c-1.3-0.4-2.8-0.5-4.1-0.3c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9c-0.8,0.2-1.5,0.3-2.2,0.3
		c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1
		c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2-0.4,0.4,0,0.6c0.4,0.2,0.9,0.3,1.5,0.1c0.1,0,0.2,0,0.3-0.1l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0
		c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7l0.7,0.3c1.2,0.4,2.5,0.7,4.1,0.8c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1
		l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2l1,0.5c1,0.4,2.2,0.9,4,1.1
		c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1c0.5,0,1.1,0.2,1.3,0.2
		c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1c1.5-0.4,2.3-0.9,3.1-1.4
		l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8c0.3,0,0.8,0.3,1.3,0.5
		c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1c0.6,0.4,1.5,0.9,3.1,1c1.9,0,2.8-0.4,3.4-0.7
		c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2c0.5-0.4,1-0.8,1.8-1.1
		c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4c0.7,0.8,1.3,1.6,2.3,2.4c0.6,0.4,1.3,1,2.8,1.1
		c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9c0.3-0.4,0.6-0.8,0.9-1.2c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2
		c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3
		c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2
		l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1
		c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1c0.2,0,0.4,0,0.6,0.1c1.5,0,2-0.5,2.3-0.7
		c0.1,0,0.1-0.1,0.2-0.1c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4
		c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4c0.5,1.2,1,2.5,1.6,4.1
		c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7c0.2,0,0.4,0,0.6-0.1
		c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4l0.7-2.5
		c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3
		c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7
		c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2
		c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6
		c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4
		c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7
		c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5
		c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8c0.3-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6
		c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3
		c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7
		c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.4,0.5,1.2,0.5C668.2,70,668.3,70,668.4,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M688.6,70c-0.4,0-0.9-0.1-1.1-0.1
		c-0.1,0-0.2-0.1-0.3-0.1c-0.5-0.4-0.7-0.8-0.8-1.1c0-0.1-0.1-0.2-0.2-0.4c-0.6-1.6-0.9-3.2-1.2-4.8l0-0.2c-0.3-1.9-0.6-3.8-0.9-5.6
		c-0.3-2.1-0.6-4.2-0.8-6.4l-0.2-1.9c-0.1-1.2-0.3-2.4-0.4-3.6c-0.1-0.9-0.2-1.8-0.3-2.7c-0.1-1.1-0.2-2.1-0.4-3.2
		c-0.1-1.2-0.2-2.3-0.4-3.5l-0.9-8.1c-0.1-1.3-0.3-2.5-0.5-3.8l-0.4-3.3c-0.2-1.7-0.5-3.3-0.7-5c-0.3-1.8-0.6-3.5-1.1-5
		c-0.1-0.1-0.1-0.3-0.2-0.5c-0.1-0.4-0.2-0.7-0.6-1.1c-0.3-0.2,0-0.5,0.6-0.6c0.6-0.1,1.4,0,1.7,0.2c0.5,0.4,0.7,0.9,0.8,1.3
		c0.1,0.2,0.1,0.3,0.2,0.5c0.5,1.6,0.8,3.3,1.1,5.1c0.3,1.7,0.5,3.3,0.7,5l0.4,3.3c0.2,1.3,0.3,2.5,0.5,3.8l0.9,8.1
		c0.1,1.2,0.3,2.3,0.4,3.5c0.1,1.1,0.2,2.1,0.4,3.2c0.1,0.9,0.2,1.8,0.3,2.7c0.1,1.2,0.3,2.4,0.4,3.6l0.2,1.9
		c0.3,2.1,0.5,4.2,0.8,6.4c0.2,1.9,0.5,3.8,0.9,5.6l0,0.2c0.2,1.3,0.5,2.7,0.9,4c0.1-0.3,0.2-0.6,0.3-0.9l0.2-0.7
		c0.3-1.5,0.6-3.1,0.9-4.7c0.3-2.1,0.7-4.2,0.9-6.3c0.3-1.8,0.5-3.7,0.7-5.5l0.8-6.3c0.1-1.2,0.3-2.3,0.4-3.5
		c0.1-1.1,0.3-2.2,0.4-3.3l0.7-5.3c0.2-1.5,0.5-3,0.7-4.6l0.3-1.9c0.2-1.2,0.5-2.5,0.9-3.7l0.1-0.4c0.2-0.7,0.4-1.5,1-2.2
		c0.1-0.3,0.8-0.5,1.6-0.5c0.6,0,1.3,0.1,1.6,0.4c0.7,0.7,0.9,1.5,1.1,2.2c0.1,0.2,0.1,0.4,0.2,0.6c0.5,1.6,0.8,3.2,1.1,4.9
		c0.3,1.8,0.7,3.7,0.9,5.5c0.3,1.9,0.6,3.7,0.9,5.6l0.8,5.6c0.2,1.3,0.4,2.5,0.6,3.7l0.3,2c0.3,1.9,0.6,3.8,1,5.8l0.2,0.8
		c0.3,1.3,0.6,2.7,1,4.1l0.2,0.5c0.1,0.4,0.2,0.7,0.4,1c0-0.1,0.1-0.2,0.1-0.3l0.2-0.5c0.6-1.8,1-3.5,1.3-5.1c0.3-1.3,0.5-2.7,0.8-4
		l1.2-7c0.2-1,0.3-2,0.5-3c0.2-1,0.4-2,0.5-3c0.1-0.7,0.3-1.4,0.4-2.1c0.2-1,0.4-2.1,0.6-3.1c0.3-1.6,0.8-3.4,1.3-5.2l0.1-0.4
		c0.3-0.7,0.5-1.5,1.2-2.2c0.5-0.5,1.1-0.6,1.6-0.6c0.6,0,1.1,0.1,1.5,0.3c0.7,0.5,1,0.9,1.2,1.4c0.1,0.1,0.1,0.3,0.2,0.4
		c0.6,1.5,1,3,1.4,4.5l0,0.2c0.4,1.7,0.8,3.3,1.1,5c0.2,0.9,0.4,1.7,0.5,2.6c0.1,0.7,0.3,1.4,0.4,2.1c0.4,1.9,0.7,3.8,1.2,5.7
		c0.3,1.7,0.8,3.4,1.2,5.1c0.4,1.4,0.8,2.8,1.5,4.2c0.5-0.8,0.7-1.6,1-2.3l0.3-0.7c0.5-1.6,0.9-3.2,1.3-4.8c0.3-1.3,0.6-2.6,0.9-3.9
		l0.2-0.9c0.3-1.3,0.6-2.7,1-4l0.7-2.5c0.2-0.9,0.5-1.8,0.8-2.7l0.1-0.3c0.4-1.2,0.8-2.5,1.7-3.7c0,0,0.1-0.1,0.1-0.1
		c0.1,0,0.1-0.1,0.1-0.1c0.2-0.2,0.5-0.5,1.5-0.7c0.2,0,0.4,0,0.6,0c1.2,0.1,1.6,0.5,1.8,0.7c0,0,0.1,0.1,0.1,0.1
		c0,0,0.1,0.1,0.1,0.1c0.9,1.2,1.3,2.4,1.7,3.6l0.2,0.6c0.5,1.5,0.9,3,1.4,4.5c0.3,1,0.6,2,0.8,3.1l0.7,2.5c0.4,1.3,0.8,2.6,1.2,3.9
		l0,0.1c0.4,1.2,0.9,2.5,1.7,3.7c0,0,0.1,0.1,0.1,0.1c0.6-0.8,1-1.6,1.4-2.5l0.3-0.6c0.6-1.5,1.1-3,1.6-4.5c0.5-1.5,1-3,1.4-4.4
		c0.5-1.6,1-2.9,1.6-4.2l0.2-0.4c0.4-0.8,0.8-1.6,1.6-2.3c0.5-0.4,1.1-0.6,1.8-0.7c0.6,0,1.1,0,1.6,0.2c1,0.5,1.4,1,1.7,1.4l0.2,0.2
		c0.8,1.2,1.2,2.3,1.7,3.5l0.6,1.4c0.4,1,0.8,2.1,1.2,3.1l0.4,1.1c0.4,0.9,0.7,1.9,1.2,2.8l0.2,0.4c0.4,0.9,0.9,1.8,1.7,2.7
		c0,0,0,0,0.1,0.1c0.1-0.1,0.1-0.1,0.2-0.2l0.2-0.2c0.8-1,1.4-2,1.9-3.1l0.2-0.4c0.6-1.3,1.2-2.5,1.7-3.8l0.5-1
		c0.6-1.2,1.2-2.5,2-3.7l0.2-0.3c0.4-0.5,0.8-1,1.6-1.4c0.5-0.2,1.1-0.3,1.8-0.3c0.7,0,1.3,0.2,1.6,0.4c1,0.5,1.4,1.1,1.8,1.7
		l0.2,0.3c0.8,1.2,1.5,2.5,2.1,3.7l0.5,1c0.5,1,1.1,2.1,1.7,3.1l0.2,0.3c0.5,0.7,0.9,1.3,1.7,1.9c0.3-0.2,0.5-0.4,0.8-0.6l0.3-0.3
		c1-1.1,1.7-2.4,2.4-3.5l0.5-0.8c0.7-1.1,1.3-2.2,2.2-3.2l0.3-0.3c0.5-0.5,0.9-1,1.9-1.5c1-0.4,2.4-0.4,3.3-0.1
		c1.2,0.4,1.7,0.9,2.1,1.4l0.2,0.2c0.9,1,1.6,1.9,2.2,2.9c0.3,0.4,0.5,0.8,0.8,1.2c0.7,1,1.4,1.9,2.5,2.8l0.1,0.1
		c0.1,0.1,0.4,0.3,0.6,0.3c0.4-0.1,0.7-0.4,1-0.6c0.9-0.7,1.6-1.5,2.2-2.3l0.4-0.4c0.7-0.9,1.4-1.7,2.3-2.6l0.1-0.1
		c0.6-0.5,1.2-1.1,2.3-1.5c0.5-0.2,1.3-0.3,1.9-0.3c0.7,0,1.3,0.1,1.8,0.3c1.1,0.4,1.8,0.9,2.3,1.4l0.2,0.2c0.7,0.6,1.2,1.2,1.8,1.8
		c0.3,0.3,0.6,0.6,0.9,0.9c0.6,0.6,1.2,1.2,2.1,1.7c0.3,0.1,0.8,0.3,1,0.4c0.3,0,0.8-0.3,1.3-0.6l0.2-0.1c1-0.7,1.9-1.5,2.6-2.2
		l0.5-0.4c0.6-0.5,1.2-1,2-1.4c0.6-0.3,2-1.1,4.2-0.9c1.8,0.3,2.7,0.8,3.4,1.2l0.2,0.1c0.6,0.4,1.2,0.8,1.7,1.3
		c0.4,0.3,0.8,0.6,1.3,0.9l0.3,0.2c0.7,0.4,1.3,0.8,2.2,1c0.2,0,0.6,0,0.7-0.1c1-0.3,1.6-0.7,2.3-1.1l0.4-0.2
		c0.3-0.2,0.6-0.4,0.9-0.5c0.9-0.6,1.9-1.2,3.2-1.7c1.2-0.4,2.6-0.6,4-0.5c1.8,0.3,2.9,0.8,3.9,1.3c0.4,0.2,0.8,0.4,1.1,0.6
		c0.8,0.4,1.5,0.8,2.4,1.2c1,0.4,1.6,0.4,1.9,0.4c0.9-0.1,1.8-0.5,2.6-0.8l0.9-0.4c1-0.5,2.1-1,3.5-1.4c1.3-0.4,2.8-0.5,4-0.3
		c1.7,0.2,2.9,0.7,4,1.1l0.8,0.3c0.8,0.3,1.5,0.6,2.4,0.9c0.8,0.2,1.5,0.3,2.2,0.3c0.9-0.1,1.8-0.3,2.7-0.6l0.7-0.2
		c0.8-0.3,1.6-0.5,2.4-0.8c1-0.3,2.3-0.6,4-0.6c1.2,0,2.3,0.2,3.1,0.3l0.3,0.1c0.2,0,0.3,0.1,0.5,0.1c0.6,0.2,0.4,0.4,0,0.6
		c-0.4,0.2-1,0.2-1.5,0.1c-0.1,0-0.2,0-0.3-0.1l-0.3-0.1c-0.6-0.1-1.2-0.2-1.7-0.2h0c-0.9,0-1.6,0.2-2.5,0.4
		c-0.7,0.2-1.5,0.5-2.2,0.7l-0.7,0.3c-1.2,0.4-2.5,0.7-4.1,0.8c-1.9,0.1-3.1-0.2-4.1-0.5c-1-0.3-1.9-0.6-2.7-1l-0.7-0.3
		c-0.9-0.3-1.8-0.7-2.9-0.8c-0.6-0.1-1.3,0.1-1.8,0.2c-1.1,0.3-2.1,0.8-3,1.2l-1,0.5c-1,0.4-2.2,0.9-4,1.1c-2.2,0.2-3.8-0.4-4.3-0.6
		c-1.1-0.4-1.9-0.9-2.7-1.3c-0.4-0.2-0.7-0.4-1.1-0.6c-0.8-0.4-1.7-0.8-2.7-1c-0.5,0-1.1,0.2-1.3,0.2c-1.1,0.4-2,1-2.9,1.6
		c-0.3,0.2-0.6,0.4-0.9,0.6l-0.4,0.2c-0.7,0.5-1.5,0.9-2.8,1.3c-0.9,0.3-2.5,0.4-3.8,0.1c-1.5-0.4-2.3-0.9-3.1-1.4l-0.3-0.2
		c-0.5-0.3-0.9-0.7-1.3-1c-0.5-0.4-1.1-0.8-1.6-1.2l-0.2-0.1c-0.6-0.4-1.2-0.7-2-0.8c-0.3,0-0.8,0.3-1.3,0.5
		c-0.7,0.4-1.2,0.8-1.8,1.3l-0.5,0.4c-0.7,0.7-1.6,1.5-2.8,2.3l-0.2,0.1c-0.6,0.4-1.5,0.9-3.1,1c-1.9,0-2.8-0.4-3.4-0.7
		c0,0-0.1-0.1-0.2-0.1c-1-0.6-1.6-1.2-2.3-1.9c-0.3-0.3-0.6-0.6-0.9-0.9c-0.6-0.6-1.1-1.2-1.8-1.8l-0.2-0.2c-0.5-0.4-1-0.8-1.8-1.1
		c-0.9,0.3-1.4,0.7-1.9,1.2l-0.1,0.1c-0.8,0.8-1.5,1.6-2.2,2.5l-0.4,0.4c-0.7,0.8-1.3,1.6-2.3,2.4c-0.6,0.4-1.3,1-2.8,1.1
		c-0.1,0-0.2,0-0.4,0h-0.1c-1.6,0-2.4-0.5-2.9-0.8l-0.2-0.1c-1.2-1-2-2-2.7-2.9c-0.3-0.4-0.6-0.8-0.9-1.2c-0.7-0.9-1.3-1.9-2.2-2.8
		l-0.2-0.2c-0.4-0.4-0.7-0.7-1.3-1c-0.6,0.3-0.9,0.7-1.3,1.1l-0.2,0.3c-0.8,1-1.5,2.1-2.1,3.2l-0.5,0.8c-0.7,1.2-1.5,2.5-2.5,3.7
		l-0.3,0.3c-0.4,0.4-0.8,0.8-1.9,1.2c-0.5,0.1-1,0.2-1.6,0.2c-0.9-0.1-1.5-0.3-1.8-0.4c-1.1-0.7-1.7-1.5-2.2-2.2l-0.2-0.3
		c-0.7-1.1-1.2-2.1-1.8-3.2l-0.5-1c-0.6-1.2-1.3-2.5-2.1-3.7l-0.2-0.3c-0.3-0.4-0.6-0.9-1.2-1.2c-0.4,0.3-0.6,0.6-0.9,0.9l-0.2,0.3
		c-0.8,1.2-1.4,2.4-2,3.6l-0.4,1c-0.6,1.3-1.1,2.6-1.7,3.8l-0.2,0.4c-0.5,1.1-1.1,2.1-2,3.2l-0.2,0.2c-0.3,0.3-0.7,0.8-1.9,1
		c-0.2,0-0.4,0-0.6,0.1c-1.5,0-2-0.5-2.3-0.7c-0.1,0-0.1-0.1-0.2-0.1c-1-1-1.4-2-1.9-2.9l-0.2-0.4c-0.4-1-0.8-1.9-1.2-2.8l-0.5-1.1
		c-0.4-1-0.8-2.1-1.2-3.1l-0.5-1.4c-0.5-1.1-1-2.3-1.7-3.4L760,34c-0.1-0.2-0.3-0.4-0.5-0.6c-0.6,0.6-0.9,1.2-1.2,1.9l-0.2,0.4
		c-0.5,1.2-1,2.5-1.6,4.1c-0.5,1.5-1,3-1.4,4.4c-0.5,1.5-1,3-1.6,4.5l-0.3,0.6c-0.4,0.9-0.8,1.9-1.7,2.9l-0.2,0.1
		c-0.2,0.2-0.8,0.8-2.4,0.7c-0.2,0-0.4,0-0.6-0.1c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.9-1.3-1.3-2.6-1.8-3.8
		l0-0.1c-0.5-1.3-0.8-2.6-1.2-4l-0.7-2.5c-0.3-1-0.6-2-0.8-3.1c-0.4-1.5-0.9-3-1.4-4.5l-0.2-0.6c-0.4-1-0.8-2.1-1.4-3.1
		c-0.7,1.1-1.1,2.2-1.4,3.3l-0.1,0.3c-0.3,0.9-0.5,1.8-0.8,2.6l-0.7,2.5c-0.3,1.3-0.7,2.7-1,4l-0.2,0.9c-0.3,1.3-0.6,2.6-0.9,3.9
		c-0.4,1.6-0.8,3.2-1.3,4.8l-0.3,0.7c-0.3,0.9-0.7,1.9-1.4,2.9l-0.1,0.2c-0.2,0.2-0.5,0.7-1.7,0.8c-0.4,0-0.8,0-1.1-0.1
		c-0.9-0.2-1.2-0.6-1.4-0.9c-0.1-0.1-0.1-0.2-0.2-0.2c-0.9-1.6-1.3-3.1-1.7-4.5c-0.5-1.7-0.9-3.4-1.2-5.1c-0.4-1.9-0.8-3.8-1.2-5.7
		c-0.2-0.7-0.3-1.4-0.4-2.1c-0.2-0.9-0.3-1.7-0.5-2.6c-0.3-1.7-0.7-3.3-1.1-5l0-0.2c-0.4-1.4-0.8-2.9-1.4-4.4
		c-0.1-0.1-0.1-0.3-0.2-0.4c-0.2,0.4-0.3,0.7-0.5,1.1l-0.1,0.4c-0.6,1.8-1,3.5-1.3,5.1c-0.2,1-0.4,2.1-0.6,3.1
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.2,1-0.3,2-0.5,3c-0.2,1-0.4,2-0.5,3l-1.2,7c-0.3,1.4-0.5,2.7-0.8,4.1c-0.3,1.6-0.7,3.4-1.3,5.1
		l-0.2,0.5c-0.2,0.6-0.5,1.2-1.1,1.8c-0.4,0.3-1,0.4-1.6,0.4c-0.8,0-1.4-0.3-1.7-0.5c-0.7-0.8-0.9-1.6-1.2-2.4l-0.2-0.5
		c-0.4-1.4-0.7-2.8-1-4.1l-0.2-0.8c-0.4-1.9-0.7-3.9-1-5.8l-0.3-2c-0.2-1.3-0.4-2.5-0.6-3.8l-0.8-5.6c-0.3-1.9-0.6-3.7-0.8-5.6
		c-0.3-1.8-0.6-3.7-1-5.5c-0.3-1.7-0.6-3.3-1.1-4.8c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.1-0.3-0.2-0.5c-0.1,0.2-0.1,0.4-0.2,0.6
		l-0.1,0.4c-0.4,1.2-0.6,2.5-0.8,3.7l-0.3,1.9c-0.3,1.5-0.5,3-0.7,4.5l-0.7,5.3c-0.1,1.1-0.3,2.2-0.4,3.3c-0.2,1.2-0.3,2.3-0.4,3.5
		l-0.8,6.3c-0.2,1.8-0.5,3.7-0.7,5.5c-0.3,2.1-0.6,4.2-0.9,6.3c-0.3,1.6-0.6,3.1-0.9,4.7l-0.2,0.7c-0.2,0.9-0.5,1.8-1.1,2.8
		c0,0-0.1,0.1-0.1,0.1c-0.1,0.2-0.4,0.5-1.2,0.5C688.8,70,688.7,70,688.6,70z"
            />
            <path
              fill="none"
              stroke="currentColor"
              strokeWidth="4"
              strokeLinejoin="round"
              d="M668.4,70c0.4,0,0.9-0.1,1.1-0.1
		c0.1,0,0.2-0.1,0.3-0.1c0.5-0.4,0.7-0.8,0.8-1.1c0-0.1,0.1-0.2,0.2-0.4c0.6-1.6,0.9-3.2,1.2-4.8l0-0.2c0.3-1.9,0.6-3.8,0.9-5.6
		c0.3-2.1,0.6-4.2,0.8-6.4l0.2-1.9c0.1-1.2,0.3-2.4,0.4-3.6c0.1-0.9,0.2-1.8,0.3-2.7c0.1-1.1,0.2-2.1,0.4-3.2
		c0.1-1.2,0.2-2.3,0.4-3.5l0.9-8.1c0.1-1.3,0.3-2.5,0.5-3.8l0.4-3.3c0.2-1.7,0.5-3.3,0.7-5c0.3-1.8,0.6-3.5,1.1-5
		c0.1-0.1,0.1-0.3,0.2-0.5c0.1-0.4,0.2-0.7,0.6-1.1c0.3-0.2,0-0.5-0.6-0.6c-0.6-0.1-1.4,0-1.7,0.2c-0.5,0.4-0.7,0.9-0.8,1.3
		c-0.1,0.2-0.1,0.3-0.2,0.5c-0.5,1.6-0.8,3.3-1.1,5.1c-0.3,1.7-0.5,3.3-0.7,5l-0.4,3.3c-0.2,1.3-0.3,2.5-0.5,3.8l-0.9,8.1
		c-0.1,1.2-0.3,2.3-0.4,3.5c-0.1,1.1-0.2,2.1-0.4,3.2c-0.1,0.9-0.2,1.8-0.3,2.7c-0.1,1.2-0.3,2.4-0.4,3.6l-0.2,1.9
		c-0.3,2.1-0.5,4.2-0.8,6.4c-0.2,1.9-0.5,3.8-0.9,5.6l0,0.2c-0.2,1.3-0.5,2.7-0.9,4c-0.1-0.3-0.2-0.6-0.3-0.9l-0.2-0.7
		c-0.3-1.5-0.6-3.1-0.9-4.7c-0.3-2.1-0.7-4.2-0.9-6.3c-0.3-1.8-0.5-3.7-0.7-5.5l-0.8-6.3c-0.1-1.2-0.3-2.3-0.4-3.5
		c-0.1-1.1-0.3-2.2-0.4-3.3l-0.7-5.3c-0.2-1.5-0.5-3-0.7-4.6l-0.3-1.9c-0.2-1.2-0.5-2.5-0.9-3.7l-0.1-0.4c-0.2-0.7-0.4-1.5-1-2.2
		c-0.1-0.3-0.8-0.5-1.6-0.5c-0.6,0-1.3,0.1-1.6,0.4c-0.7,0.7-0.9,1.5-1.1,2.2c-0.1,0.2-0.1,0.4-0.2,0.6c-0.5,1.6-0.8,3.2-1.1,4.9
		c-0.3,1.8-0.7,3.7-0.9,5.5c-0.3,1.9-0.6,3.7-0.9,5.6l-0.8,5.6c-0.2,1.3-0.4,2.5-0.6,3.7l-0.3,2c-0.3,1.9-0.6,3.8-1,5.8l-0.2,0.8
		c-0.3,1.3-0.6,2.7-1,4.1l-0.2,0.5c-0.1,0.4-0.2,0.7-0.4,1c0-0.1-0.1-0.2-0.1-0.3l-0.2-0.5c-0.6-1.8-1-3.5-1.3-5.1
		c-0.3-1.3-0.5-2.7-0.8-4l-1.2-7c-0.2-1-0.3-2-0.5-3c-0.2-1-0.4-2-0.5-3c-0.1-0.7-0.3-1.4-0.4-2.1c-0.2-1-0.4-2.1-0.6-3.1
		c-0.3-1.6-0.8-3.4-1.3-5.2l-0.1-0.4c-0.3-0.7-0.5-1.5-1.2-2.2c-0.5-0.5-1.1-0.6-1.6-0.6c-0.6,0-1.1,0.1-1.5,0.3
		c-0.7,0.5-1,0.9-1.2,1.4c-0.1,0.1-0.1,0.3-0.2,0.4c-0.6,1.5-1,3-1.4,4.5l0,0.2c-0.4,1.7-0.8,3.3-1.1,5c-0.2,0.9-0.4,1.7-0.5,2.6
		c-0.1,0.7-0.3,1.4-0.4,2.1c-0.4,1.9-0.7,3.8-1.2,5.7c-0.3,1.7-0.8,3.4-1.2,5.1c-0.4,1.4-0.8,2.8-1.5,4.2c-0.5-0.8-0.7-1.6-1-2.3
		l-0.3-0.7c-0.5-1.6-0.9-3.2-1.3-4.8c-0.3-1.3-0.6-2.6-0.9-3.9l-0.2-0.9c-0.3-1.3-0.6-2.7-1-4l-0.7-2.5c-0.2-0.9-0.5-1.8-0.8-2.7
		l-0.1-0.3c-0.4-1.2-0.8-2.5-1.7-3.7c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.5-1.5-0.7c-0.2,0-0.4,0-0.6,0
		c-1.2,0.1-1.6,0.5-1.8,0.7c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.9,1.2-1.3,2.4-1.7,3.6l-0.2,0.6c-0.5,1.5-0.9,3-1.4,4.5
		c-0.3,1-0.6,2-0.8,3.1l-0.7,2.5c-0.4,1.3-0.8,2.6-1.2,3.9l0,0.1c-0.4,1.2-0.9,2.5-1.7,3.7c0,0-0.1,0.1-0.1,0.1
		c-0.6-0.8-1-1.6-1.4-2.5l-0.3-0.6c-0.6-1.5-1.1-3-1.6-4.5c-0.5-1.5-1-3-1.4-4.4c-0.5-1.6-1-2.9-1.6-4.2l-0.2-0.4
		c-0.4-0.8-0.8-1.6-1.6-2.3c-0.5-0.4-1.1-0.6-1.8-0.7c-0.6,0-1.1,0-1.6,0.2c-1,0.5-1.4,1-1.7,1.4l-0.2,0.2c-0.8,1.2-1.2,2.3-1.7,3.5
		l-0.6,1.4c-0.4,1-0.8,2.1-1.2,3.1l-0.5,1.1c-0.4,0.9-0.7,1.9-1.2,2.8l-0.2,0.4c-0.4,0.9-0.9,1.8-1.7,2.7c0,0,0,0-0.1,0.1
		c-0.1-0.1-0.1-0.1-0.2-0.2l-0.2-0.2c-0.8-1-1.4-2-1.9-3.1l-0.2-0.4c-0.6-1.3-1.2-2.5-1.7-3.8l-0.5-1c-0.6-1.2-1.2-2.5-2-3.7
		l-0.2-0.3c-0.4-0.5-0.8-1-1.6-1.4c-0.5-0.2-1.1-0.3-1.8-0.3c-0.7,0-1.3,0.2-1.6,0.4c-1,0.5-1.4,1.1-1.8,1.7l-0.2,0.3
		c-0.8,1.2-1.5,2.5-2.1,3.7l-0.5,1c-0.5,1-1.1,2.1-1.7,3.1l-0.2,0.3c-0.5,0.7-0.9,1.3-1.7,1.9c-0.3-0.2-0.5-0.4-0.8-0.6l-0.3-0.3
		c-1-1.1-1.7-2.4-2.4-3.5l-0.5-0.8c-0.7-1.1-1.3-2.2-2.2-3.2l-0.3-0.3c-0.5-0.5-0.9-1-1.9-1.5c-1-0.4-2.4-0.4-3.3-0.1
		c-1.2,0.4-1.7,0.9-2.1,1.4l-0.2,0.2c-0.9,1-1.6,1.9-2.2,2.9c-0.3,0.4-0.5,0.8-0.8,1.2c-0.7,1-1.4,1.9-2.5,2.8l-0.1,0.1
		c-0.1,0.1-0.4,0.3-0.6,0.3c-0.4-0.1-0.7-0.4-1-0.6c-0.9-0.7-1.6-1.5-2.2-2.3l-0.4-0.4c-0.7-0.9-1.4-1.7-2.3-2.6l-0.1-0.1
		c-0.6-0.5-1.2-1.1-2.3-1.5c-0.5-0.2-1.3-0.3-1.9-0.3c-0.7,0-1.3,0.1-1.8,0.3c-1.1,0.4-1.8,0.9-2.3,1.4l-0.2,0.2
		c-0.7,0.6-1.2,1.2-1.8,1.8c-0.3,0.3-0.6,0.6-0.9,0.9c-0.6,0.6-1.2,1.2-2.1,1.7c-0.3,0.1-0.8,0.3-1,0.4c-0.3,0-0.8-0.3-1.3-0.6
		l-0.2-0.1c-1-0.7-1.9-1.5-2.6-2.2l-0.5-0.4c-0.6-0.5-1.2-1-2-1.4c-0.6-0.3-2-1.1-4.2-0.9c-1.8,0.3-2.7,0.8-3.4,1.2l-0.2,0.1
		c-0.6,0.4-1.2,0.8-1.7,1.3c-0.4,0.3-0.8,0.6-1.3,0.9l-0.3,0.2c-0.7,0.4-1.3,0.8-2.2,1c-0.2,0-0.6,0-0.7-0.1c-1-0.3-1.6-0.7-2.3-1.1
		l-0.4-0.2c-0.3-0.2-0.6-0.4-0.9-0.5c-0.9-0.6-1.9-1.2-3.2-1.7c-1.2-0.4-2.6-0.6-4-0.5c-1.8,0.3-2.9,0.8-3.9,1.3
		c-0.4,0.2-0.8,0.4-1.1,0.6c-0.8,0.4-1.5,0.8-2.4,1.2c-1,0.4-1.6,0.4-1.9,0.4c-0.9-0.1-1.8-0.5-2.6-0.8l-0.9-0.4
		c-1-0.5-2.1-1-3.5-1.4c-1.3-0.4-2.8-0.5-4.1-0.3c-1.7,0.2-2.9,0.7-4,1.1l-0.8,0.3c-0.8,0.3-1.5,0.6-2.4,0.9
		c-0.8,0.2-1.5,0.3-2.2,0.3c-0.9-0.1-1.8-0.3-2.7-0.6l-0.7-0.2c-0.8-0.3-1.6-0.5-2.4-0.8c-1-0.3-2.3-0.6-4-0.6
		c-1.2,0-2.3,0.2-3.1,0.3l-0.3,0.1c-0.2,0-0.3,0.1-0.5,0.1c-0.6,0.2-0.4,0.4,0,0.6c0.4,0.2,0.9,0.3,1.5,0.1c0.1,0,0.2,0,0.3-0.1
		l0.3-0.1c0.6-0.1,1.2-0.2,1.7-0.2h0c0.9,0,1.6,0.2,2.5,0.4c0.7,0.2,1.5,0.5,2.2,0.7l0.7,0.3c1.2,0.4,2.5,0.7,4.1,0.8
		c1.9,0.1,3.1-0.2,4.1-0.5c1-0.3,1.9-0.6,2.7-1l0.7-0.3c0.9-0.3,1.8-0.7,2.9-0.8c0.6-0.1,1.3,0.1,1.8,0.2c1.1,0.3,2.1,0.8,3,1.2
		l1,0.5c1,0.4,2.2,0.9,4,1.1c2.2,0.2,3.8-0.4,4.3-0.6c1.1-0.4,1.9-0.9,2.7-1.3c0.4-0.2,0.7-0.4,1.1-0.6c0.8-0.4,1.7-0.8,2.7-1
		c0.5,0,1.1,0.2,1.3,0.2c1.1,0.4,2,1,2.9,1.6c0.3,0.2,0.6,0.4,0.9,0.6l0.4,0.2c0.7,0.5,1.5,0.9,2.8,1.3c0.9,0.3,2.5,0.4,3.8,0.1
		c1.5-0.4,2.3-0.9,3.1-1.4l0.3-0.2c0.5-0.3,0.9-0.7,1.3-1c0.5-0.4,1.1-0.8,1.6-1.2l0.2-0.1c0.6-0.4,1.2-0.7,2-0.8
		c0.3,0,0.8,0.3,1.3,0.5c0.7,0.4,1.2,0.8,1.8,1.3l0.5,0.4c0.7,0.7,1.6,1.5,2.8,2.3l0.2,0.1c0.6,0.4,1.5,0.9,3.1,1
		c1.9,0,2.8-0.4,3.4-0.7c0,0,0.1-0.1,0.2-0.1c1-0.6,1.6-1.2,2.3-1.9c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.6,1.1-1.2,1.8-1.8l0.2-0.2
		c0.5-0.4,1-0.8,1.8-1.1c0.9,0.3,1.4,0.7,1.9,1.2l0.1,0.1c0.8,0.8,1.5,1.6,2.2,2.5l0.4,0.4c0.7,0.8,1.3,1.6,2.3,2.4
		c0.6,0.4,1.3,1,2.8,1.1c0.1,0,0.2,0,0.4,0h0.1c1.6,0,2.4-0.5,2.9-0.8l0.2-0.1c1.2-1,2-2,2.7-2.9c0.3-0.4,0.6-0.8,0.9-1.2
		c0.7-0.9,1.3-1.9,2.2-2.8l0.2-0.2c0.4-0.4,0.7-0.7,1.3-1c0.6,0.3,0.9,0.7,1.3,1.1l0.2,0.3c0.8,1,1.5,2.1,2.1,3.2l0.5,0.8
		c0.7,1.2,1.5,2.5,2.5,3.7l0.3,0.3c0.4,0.4,0.8,0.8,1.9,1.2c0.5,0.1,1,0.2,1.6,0.2c0.9-0.1,1.5-0.3,1.8-0.4c1.1-0.7,1.7-1.5,2.2-2.2
		l0.2-0.3c0.7-1.1,1.2-2.1,1.8-3.2l0.5-1c0.6-1.2,1.3-2.5,2.1-3.7l0.2-0.3c0.3-0.4,0.6-0.9,1.2-1.2c0.4,0.3,0.6,0.6,0.9,0.9l0.2,0.3
		c0.8,1.2,1.4,2.4,1.9,3.6l0.4,1c0.6,1.3,1.1,2.6,1.7,3.8l0.2,0.4c0.5,1.1,1.1,2.1,2,3.2l0.2,0.2c0.3,0.3,0.7,0.8,1.9,1
		c0.2,0,0.4,0,0.6,0.1c1.5,0,2-0.5,2.3-0.7c0.1,0,0.1-0.1,0.2-0.1c1-1,1.4-2,1.9-2.9l0.2-0.4c0.4-1,0.8-1.9,1.2-2.8l0.5-1.1
		c0.4-1,0.8-2.1,1.2-3.1l0.5-1.4c0.5-1.1,1-2.3,1.7-3.4l0.2-0.2c0.1-0.2,0.3-0.4,0.5-0.6c0.6,0.6,0.9,1.2,1.2,1.9l0.2,0.4
		c0.5,1.2,1,2.5,1.6,4.1c0.5,1.5,1,3,1.4,4.4c0.5,1.5,1,3,1.6,4.5l0.3,0.6c0.4,0.9,0.8,1.9,1.7,2.9l0.2,0.1c0.2,0.2,0.8,0.8,2.4,0.7
		c0.2,0,0.4,0,0.6-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.1,0.2-0.2c0.9-1.3,1.3-2.6,1.8-3.8l0-0.1c0.5-1.3,0.8-2.6,1.2-4
		l0.7-2.5c0.3-1,0.6-2,0.8-3.1c0.4-1.5,0.9-3,1.4-4.5l0.2-0.6c0.4-1,0.8-2.1,1.4-3.1c0.7,1.1,1.1,2.2,1.4,3.3l0.1,0.3
		c0.3,0.9,0.5,1.8,0.8,2.6l0.7,2.5c0.3,1.3,0.7,2.7,1,4l0.2,0.9c0.3,1.3,0.6,2.6,0.9,3.9c0.4,1.6,0.8,3.2,1.3,4.8l0.3,0.7
		c0.3,0.9,0.7,1.9,1.4,2.9l0.1,0.2c0.2,0.2,0.5,0.7,1.7,0.8c0.4,0,0.8,0,1.1-0.1c0.9-0.2,1.2-0.6,1.4-0.9c0.1-0.1,0.1-0.2,0.2-0.2
		c0.9-1.6,1.3-3.1,1.7-4.5c0.5-1.7,0.9-3.4,1.2-5.1c0.4-1.9,0.8-3.8,1.2-5.7c0.2-0.7,0.3-1.4,0.4-2.1c0.2-0.9,0.3-1.7,0.5-2.6
		c0.3-1.7,0.7-3.3,1.1-5l0-0.2c0.4-1.4,0.8-2.9,1.4-4.4c0.1-0.1,0.1-0.3,0.2-0.4c0.2,0.4,0.3,0.7,0.5,1.1l0.1,0.4
		c0.6,1.8,1,3.5,1.3,5.1c0.2,1,0.4,2.1,0.6,3.1c0.1,0.7,0.3,1.4,0.4,2.1c0.2,1,0.3,2,0.5,3c0.2,1,0.4,2,0.5,3l1.2,7
		c0.3,1.4,0.5,2.7,0.8,4.1c0.3,1.6,0.7,3.4,1.3,5.1l0.2,0.5c0.2,0.6,0.5,1.2,1.1,1.8c0.4,0.3,1,0.4,1.6,0.4c0.8,0,1.4-0.3,1.7-0.5
		c0.7-0.8,0.9-1.6,1.2-2.4l0.2-0.5c0.4-1.4,0.7-2.8,1-4.1l0.2-0.8c0.3-1.9,0.7-3.9,1-5.8l0.3-2c0.2-1.3,0.4-2.5,0.6-3.8l0.8-5.6
		c0.3-1.9,0.6-3.7,0.9-5.6c0.3-1.8,0.6-3.7,1-5.5c0.3-1.7,0.6-3.3,1.1-4.8c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.2,0.1-0.3,0.2-0.5
		c0.1,0.2,0.1,0.4,0.2,0.6l0.1,0.4c0.4,1.2,0.6,2.5,0.8,3.7l0.3,1.9c0.3,1.5,0.5,3,0.7,4.5l0.7,5.3c0.1,1.1,0.3,2.2,0.4,3.3
		c0.2,1.2,0.3,2.3,0.4,3.5l0.8,6.3c0.2,1.8,0.5,3.7,0.7,5.5c0.3,2.1,0.6,4.2,0.9,6.3c0.3,1.6,0.6,3.1,0.9,4.7l0.2,0.7
		c0.2,0.9,0.5,1.8,1.1,2.8c0,0,0.1,0.1,0.1,0.1c0.1,0.2,0.4,0.5,1.2,0.5C668.2,70,668.3,70,668.4,70z"
            />
          </AnimatedGroup>
        </StyledSVGContainer>
      </Wrapper>
    );
  }

  if (kind === IconLoaderKind.LARGE) {
    return (
      <Wrapper {...props}>
        <SVGContainer gradient={props.gradient} viewBox="0 0 40 40">
          <circle
            cx="20"
            cy="20"
            r="18"
            stroke="var(--color-label-quaternary)"
            strokeWidth="3"
          />
          <AnimatedPath
            d="M38.1996 19.9999C38.1996 30.0415 30.0593 38.1817 20.0178 38.1817C9.97621 38.1817 1.83594 30.0415 1.83594 19.9999C1.83594 9.95839 9.97621 1.81812 20.0178 1.81812"
            stroke="currentColor"
            strokeWidth="3"
          />
        </SVGContainer>
      </Wrapper>
    );
  }

  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 20 20">
        <circle
          cx="10"
          cy="10"
          r="8"
          stroke="var(--color-label-quaternary)"
          strokeWidth="2"
        />
        <AnimatedPath
          d="M18.0156 10C18.0156 14.4183 14.4339 18 10.0156 18C5.59735 18 2.01562 14.4183 2.01562 10C2.01562 5.58172 5.59735 2 10.0156 2"
          stroke="currentColor"
          strokeWidth="2"
        />
      </SVGContainer>
    </Wrapper>
  );
}
