import { IBaseRequest } from '@/api/types';
import { IPaymentMethod } from '@/entities';

interface IGetPaymentMethodsProps extends IBaseRequest {
  clientId: string;
  params: {
    direction?: string;
    senderCurrencyId?: string;
    receiverCountryId?: string;
    receiverCurrencyId?: string;
  };
}

export async function getPaymentMethods({
  api,
  clientId,
  params,
}: IGetPaymentMethodsProps) {
  const response = await api.get<IPaymentMethod[]>(
    `clients/${clientId}/payment_methods`,
    {
      params,
    },
  );

  return response.data;
}
