import { IBaseRequest } from '@/api/types';
import { IMostSentToStats } from '@/entities';

interface IGetMostSentToStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    accountId?: string;
    currencyId?: string;
    since?: number;
    till?: number;
  };
}

export async function getMostSentToStats({
  api,
  clientId,
  params,
}: IGetMostSentToStatsProps): Promise<IMostSentToStats> {
  const response = await api.get<IMostSentToStats>(
    `clients/${clientId}/stat/most_sent_to`,
    {
      params,
    },
  );

  return response.data;
}
