import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createSuccessResponse, createErrorHandler } from '@/api/helpers';

interface IUpdateOtpBackupCodesProps extends IBaseRequest {}

interface IUpdateOtpBackupCodesResult {
  backupCodes: string[];
}

export async function updateOtpBackupCodes({
  api,
}: IUpdateOtpBackupCodesProps): Promise<
  IBaseResponse<IUpdateOtpBackupCodesResult>
> {
  try {
    const response = await api.patch<IUpdateOtpBackupCodesResult>(
      `user/otp_backup_codes`,
      {},
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
