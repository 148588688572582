export function Loader() {
  return (
    <div className="loader-container">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0"
        y="0"
        viewBox="0 0 16 16"
        color="var(--color-loader)"
        className="loader"
      >
        <path d="M8.83654 0.476471C8.86279 0.240385 9.07574 0.0690973 9.31008 0.107995C10.7241 0.342705 12.0519 0.953613 13.1524 1.88015C14.3628 2.8992 15.2438 4.25429 15.6841 5.77406C16.1243 7.29383 16.104 8.91002 15.6258 10.4182C15.1475 11.9265 14.2327 13.259 12.9971 14.2473C11.7615 15.2357 10.2605 15.8354 8.68408 15.9707C7.10762 16.106 5.52645 15.7708 4.14051 15.0074C2.75457 14.2441 1.6261 13.0869 0.897821 11.6823C0.235656 10.4051 -0.0686511 8.9755 0.0129759 7.54449C0.0265035 7.30733 0.240385 7.13721 0.476471 7.16346V7.16346C0.712557 7.18971 0.8813 7.40242 0.869309 7.63965C0.805538 8.90135 1.07765 10.1602 1.66149 11.2863C2.31147 12.54 3.31859 13.5727 4.55551 14.254C5.79242 14.9352 7.20358 15.2344 8.61052 15.1136C10.0175 14.9929 11.357 14.4576 12.4598 13.5756C13.5625 12.6935 14.3789 11.5043 14.8058 10.1582C15.2326 8.81217 15.2508 7.36976 14.8578 6.01341C14.4649 4.65706 13.6786 3.44767 12.5984 2.5382C11.6281 1.72126 10.4597 1.17925 9.21481 0.964317C8.98074 0.923903 8.81029 0.712557 8.83654 0.476471V0.476471Z"></path>
      </svg>
      <div className="loader-description-placeholder"></div>
    </div>
  );
}
