import { IContactDetail, IPaymentMethodValidateResult } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IValidatePaymentMethodsProps extends IBaseRequest {
  clientId?: string;
  id: string;
  dto: {
    contactPaymentMethods: Array<{
      id?: string;
      paymentKind: string;
      details: IContactDetail[];
    }>;
  };
}

export async function validatePaymentMethods({
  api,
  clientId,
  id,
  dto,
}: IValidatePaymentMethodsProps): Promise<
  IBaseResponse<IPaymentMethodValidateResult[]>
> {
  try {
    const response = await api.post<IPaymentMethodValidateResult[]>(
      `clients/${clientId}/contacts/${id}/payment_methods/bulk_validate`,
      dto,
    );

    return createSuccessResponse({
      result: response.data,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
