import { IAgreementSign } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementSignsProps extends IBaseRequest {
  state: string;
  latest: boolean;
  clientId?: string;
}

export async function getAgreementSigns({
  api,
  state,
  latest,
  clientId,
}: IGetAgreementSignsProps): Promise<IAgreementSign[]> {
  const response = await api.get<IAgreementSign[]>(`user/agreement_signs`, {
    params: {
      state,
      latest,
      clientId,
    },
  });

  return response.data;
}
