import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconNewIntercomChat(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 31 31">
        <path
          d="M10.1612 16.1419C10.5751 16.1419 10.9215 16.0072 11.2004 15.7377C11.4792 15.4682 11.6187 15.1342 11.6187 14.7358C11.6187 14.3374 11.4787 14.004 11.1987 13.7356C10.9187 13.4672 10.5717 13.333 10.1578 13.333C9.74392 13.333 9.39754 13.4678 9.11868 13.7373C8.83981 14.0068 8.70038 14.3407 8.70038 14.7391C8.70038 15.1375 8.84038 15.4709 9.12038 15.7393C9.40038 16.0077 9.74733 16.1419 10.1612 16.1419ZM15.5504 16.1419C15.9642 16.1419 16.3106 16.0072 16.5895 15.7377C16.8684 15.4682 17.0078 15.1342 17.0078 14.7358C17.0078 14.3374 16.8678 14.004 16.5878 13.7356C16.3078 13.4672 15.9608 13.333 15.5469 13.333C15.133 13.333 14.7866 13.4678 14.5078 13.7373C14.2289 14.0068 14.0895 14.3407 14.0895 14.7391C14.0895 15.1375 14.2295 15.4709 14.5095 15.7393C14.7895 16.0077 15.1364 16.1419 15.5504 16.1419ZM20.8033 16.1419C21.2172 16.1419 21.5635 16.0072 21.8424 15.7377C22.1213 15.4682 22.2607 15.1342 22.2607 14.7358C22.2607 14.3374 22.1207 14.004 21.8407 13.7356C21.5607 13.4672 21.2137 13.333 20.7999 13.333C20.3859 13.333 20.0396 13.4678 19.7607 13.7373C19.4818 14.0068 19.3424 14.3407 19.3424 14.7391C19.3424 15.1375 19.4824 15.4709 19.7624 15.7393C20.0424 16.0077 20.3894 16.1419 20.8033 16.1419ZM7.66926 24.5686L5.62646 26.5348C5.13358 27.0092 4.57262 27.1208 3.94357 26.8695C3.31452 26.6183 3 26.1696 3 25.5236V7.95868C3 7.13521 3.29653 6.43623 3.88958 5.86174C4.48264 5.28725 5.20426 5 6.05445 5H24.9261C25.7816 5 26.5078 5.28725 27.1047 5.86174C27.7016 6.43623 28 7.13521 28 7.95868V21.6286C28 22.4469 27.7016 23.1415 27.1047 23.7123C26.5078 24.2831 25.7816 24.5686 24.9261 24.5686H7.66926Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
