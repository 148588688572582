import { StyledText } from '@/ui/components/desktop/Text/index.styles';
import { ITextProps, TextKind } from '@/ui/components/desktop/Text/types.ts';
import { forwardRef } from 'react';
import styled from '@emotion/styled';

export const Text = forwardRef<HTMLParagraphElement, ITextProps>(
  ({ as, kind = TextKind.BODY_DEFAULT, ...props }, ref) => {
    return <StyledText as={as} ref={ref} {...props} kind={kind} />;
  },
);

export const TextOrChildren = forwardRef<HTMLParagraphElement, ITextProps>(
  ({ children, ...props }, ref) => {
    if (typeof children === 'string' || typeof children === 'number') {
      return (
        <StyledText {...props} ref={ref}>
          {children}
        </StyledText>
      );
    }

    return children;
  },
);

export const PrimaryText = styled(Text)`
  color: var(--color-label-primary);
`;

export const SecondaryText = styled(Text)`
  color: var(--color-label-secondary);
`;

export const TertiaryText = styled(Text)`
  color: var(--color-label-tertiary);
`;
