import { PropsWithChildren, useCallback, useMemo, useState } from 'react';
import { css, Global } from '@emotion/react';
import { Theme } from '@/ui/ThemeProvider/types';
import {
  basicStyles,
  scroll,
  toastStyles,
} from '@/ui/ThemeProvider/index.styles';
import { allColors, darkColors, lightColors } from '@/ui/ThemeProvider/colors';
import { ThemeContext } from '@/ui/ThemeProvider/useTheme';

function checkThemeValidity(theme?: string | null) {
  return theme === Theme.DARK || theme === Theme.LIGHT;
}

function updateSavedTheme(theme?: Theme) {
  const updatedTheme = theme && checkThemeValidity(theme) ? theme : Theme.LIGHT;

  return localStorage.setItem('ariv.theme', updatedTheme);
}

function getInitialTheme() {
  const savedTheme = localStorage.getItem('ariv.theme');

  if (!checkThemeValidity(savedTheme)) {
    updateSavedTheme(Theme.LIGHT);

    return Theme.LIGHT;
  }

  return savedTheme as Theme;
}

export function ThemeProvider({ children }: PropsWithChildren) {
  const [theme, setTheme] = useState<Theme>(getInitialTheme);

  const switchTheme = useCallback(() => {
    setTheme((previousTheme) => {
      const newTheme = previousTheme === Theme.LIGHT ? Theme.DARK : Theme.LIGHT;

      updateSavedTheme(newTheme);

      return newTheme;
    });
  }, []);

  const globalStyles = useMemo(() => {
    const colors = theme === Theme.LIGHT ? lightColors : darkColors;

    return css`
      :root {
        ${allColors}
        ${colors}
      }

      ${toastStyles}
      ${basicStyles}
      ${scroll}
    `;
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      <Global styles={globalStyles} />
      {children}
    </ThemeContext.Provider>
  );
}
