import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetInvoicePaymentDetailsPdfProps extends IBaseRequest {
  clientId: string;
  id: string;
  params: {
    pdfDisplayName: string;
    pdfFilename: string;
  };
}

export interface IGetInvoicePaymentDetailsPdfResponse {
  clientId: string;
  createdAt: number;
  id: string;
  state: DocumentState;
  updatedAt: number;
  userId: string;
}

export async function getInvoicePaymentDetailsPdf({
  api,
  clientId,
  id,
  params,
}: IGetInvoicePaymentDetailsPdfProps) {
  const response = await api.get<IGetInvoicePaymentDetailsPdfResponse>(
    `clients/${clientId}/invoices/${id}/payment_details/pdf`,
    {
      params,
    },
  );

  return response.data;
}
