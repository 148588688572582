import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { User } from '@/entities';

export async function setReferralCode({
  api,
}: IBaseRequest): Promise<IBaseResponse<User>> {
  try {
    const response = await api.post<IBaseResponse<User>>(`user/referral_code`);

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
