import { IAccountsAuditBankLetterAvailability } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountsAuditBankLetterAvailabilityProps extends IBaseRequest {
  clientId: string;
}

export async function getAccountsAuditBankLetterAvailability({
  api,
  clientId,
}: IGetAccountsAuditBankLetterAvailabilityProps): Promise<IAccountsAuditBankLetterAvailability> {
  const response = await api.get<IAccountsAuditBankLetterAvailability>(
    `clients/${clientId}/client_accounts/audit_bank_letter/availability`,
  );

  return response.data;
}
