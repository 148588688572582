import { Transaction } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IDeleteLabelsProps extends IBaseRequest {
  clientId?: string;
  id: string;
  dto: {
    labelIds: string[];
  };
}

export async function deleteLabels({
  api,
  clientId,
  id,
  dto,
}: IDeleteLabelsProps): Promise<IBaseResponse<Transaction>> {
  try {
    const response = await api.delete<IBaseResponse<Transaction>>(
      `clients/${clientId}/display_transactions/${id}/labels`,
      { data: dto },
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
