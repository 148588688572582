import { PropsWithChildren } from 'react';
import { posthog } from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { config } from '@/config';

if (typeof window !== 'undefined') {
  posthog.init(config.posthog.apiKey, {
    api_host: config.posthog.projectUrl,
    autocapture: false,
    // Disable in development
    loaded: (posthog) => {
      if (config.node.env === 'development') posthog.opt_out_capturing();
    },
  });
}

export function PosthogProvider({ children }: PropsWithChildren<unknown>) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
