import { IBaseRequest } from '@/api/types';
import { ITransactionsAmountStats } from '@/entities';

interface IGetTransactionsAmountStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    accountId?: string;
    currencyId?: string;
    since?: number;
    till?: number;
    period?: string;
  };
}

export async function getTransactionsAmountStats({
  api,
  clientId,
  params,
}: IGetTransactionsAmountStatsProps): Promise<ITransactionsAmountStats> {
  const response = await api.get<ITransactionsAmountStats>(
    `clients/${clientId}/stat/transactions_amount`,
    {
      params,
    },
  );

  return response.data;
}
