import { IAgreementSign } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementSignsProps extends IBaseRequest {
  clientId: string;
}

export async function getLatestToSignAgreements({
  api,
  clientId,
}: IGetAgreementSignsProps) {
  const response = await api.get<IAgreementSign[]>(
    `clients/${clientId}/agreement_signs/latest_to_sign`,
  );

  return response.data;
}
