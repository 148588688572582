import { jwtDecode, JwtPayload } from 'jwt-decode';

export interface IJwtArivalPayload extends JwtPayload {
  tfa_confirmed: boolean;
  session_id: string;
}

export function decodeToken(token: string) {
  try {
    return {
      success: true,
      error: undefined,
      payload: jwtDecode<IJwtArivalPayload>(token),
    };
  } catch (error) {
    return {
      success: false,
      error,
      payload: undefined,
    };
  }
}

export function getExpireTime(token: string) {
  const decodedToken = decodeToken(token);

  if (decodedToken.success) {
    return decodedToken.payload?.exp ?? 0;
  }

  return 0;
}
