import { IBaseRequest } from '@/api/types';
import { IPurposesOfTransferStats } from '@/entities';

interface IGetPurposesOfTransfersStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    currencyId?: string;
    since?: number;
    till?: number;
  };
}

export async function getPurposesOfTransfersStats({
  api,
  clientId,
  params,
}: IGetPurposesOfTransfersStatsProps): Promise<IPurposesOfTransferStats> {
  const response = await api.get<IPurposesOfTransferStats>(
    `clients/${clientId}/stat/purposes_of_transfers`,
    {
      params,
    },
  );

  return response.data;
}
