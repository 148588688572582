import { getRandomToken } from './getRandomToken';
import { sha256 } from './sha256';
import { config } from '@/config';

export async function getPair() {
  const token = getRandomToken();
  const hash = await sha256(
    config.security.metricass.version,
    token + config.security.metricass.salt,
  );

  return {
    token,
    hash,
  };
}
