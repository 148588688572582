import { Wrapper } from '@/ui/components/desktop/Icon/shared';
import { SVGContainer } from '@/ui/components/desktop/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconRefresh(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 32 32">
        <path
          d="M23.5333 8.4667C21.36 6.29336 18.28 5.04003 14.8933 5.3867C9.99999 5.88003 5.97332 9.85336 5.42665 14.7467C4.69332 21.2134 9.69332 26.6667 16 26.6667C20.2533 26.6667 23.9067 24.1734 25.6133 20.5867C26.04 19.6934 25.4 18.6667 24.4133 18.6667C23.92 18.6667 23.4533 18.9334 23.24 19.3734C21.7333 22.6134 18.12 24.6667 14.1733 23.7867C11.2133 23.1334 8.82665 20.72 8.19999 17.76C7.07999 12.5867 11.0133 8.00003 16 8.00003C18.2133 8.00003 20.1867 8.92003 21.6267 10.3734L19.6133 12.3867C18.7733 13.2267 19.36 14.6667 20.5467 14.6667H25.3333C26.0667 14.6667 26.6667 14.0667 26.6667 13.3334V8.5467C26.6667 7.36003 25.2267 6.76003 24.3867 7.60003L23.5333 8.4667Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
