import { useCallback, useState } from 'react';
import { useApi } from '@/api';
import { CacheKeys } from '@/api/keys';
import { useQuery } from '@tanstack/react-query';

export function useSettings() {
  const { api, client, mutate } = useApi();
  const [isUpdating, setUpdating] = useState(false);

  const { isLoading, data } = useQuery({
    queryKey: [CacheKeys.settings],
    queryFn: async () => api.user.getSettings({ api: client }),
  });

  const handleSaveSettings = useCallback(
    async (values: Record<string, unknown>, cacheReset: boolean = true) => {
      try {
        setUpdating(true);

        await api.user.saveSettings({
          api: client,
          dto: {
            settings: values,
          },
        });

        if (cacheReset) {
          mutate([CacheKeys.settings]);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setUpdating(false);
      }
    },
    [api.user, client],
  );

  return {
    settings: data?.settings ?? {},
    isLoading,
    isUpdating,
    setUpdating,
    handleSaveSettings,
  };
}
