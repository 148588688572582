import { IBaseRequest, IBaseResponse } from '@/api/types';
import { createErrorHandler, createSuccessResponse } from '@/api/helpers';

interface IDeleteSearchQueryProps extends IBaseRequest {
  id: string;
  clientId: string;
}

export async function deleteSearchQuery({
  api,
  id,
  clientId,
}: IDeleteSearchQueryProps): Promise<IBaseResponse<boolean>> {
  try {
    const response = await api.delete<IBaseResponse<IBaseResponse<boolean>>>(
      `clients/${clientId}/search_queries/${id}`,
    );

    return createSuccessResponse({
      result: response.data.success,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
