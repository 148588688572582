import { IAgreementSignDocument } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementsProps extends IBaseRequest {
  params?: {
    code?: string;
  };
}

export async function getAgreements({
  api,
  params,
}: IGetAgreementsProps): Promise<IAgreementSignDocument> {
  const response = await api.get<IAgreementSignDocument>(`user/agreements`, {
    params,
  });

  return response.data;
}
