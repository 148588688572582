import { IBaseRequest } from '@/api/types';
import { IWebAuthnCredential } from '@/entities';

interface IGetWebAuthnCredentialsProps extends IBaseRequest {}

export async function getWebAuthnCredentials({
  api,
}: IGetWebAuthnCredentialsProps): Promise<IWebAuthnCredential[]> {
  const response = await api.get<IWebAuthnCredential[]>(
    `auth/webauthn_credentials`,
  );

  return response.data;
}
