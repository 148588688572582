import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetTransactionPdfProps extends IBaseRequest {
  clientId: string;
  id: string;
  params: {
    pdfDisplayName: string;
    pdfFilename: string;
  };
}

interface IGetTransactionPdfResponse {
  clientId: string;
  createdAt: number;
  id: string;
  state: DocumentState;
  updatedAt: number;
  userId: string;
}

export async function getTransactionPdf({
  api,
  clientId,
  id,
  params,
}: IGetTransactionPdfProps) {
  const response = await api.get<IGetTransactionPdfResponse>(
    `clients/${clientId}/display_transactions/${id}/pdf`,
    {
      params,
    },
  );

  return response.data;
}
