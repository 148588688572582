import { jwtDecode, JwtPayload } from 'jwt-decode';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { FlexibleObject } from '@/entities';
import { getVerificationSign } from './getVerificationSign';

interface ICreateRequestWithTempTokenProps<T extends FlexibleObject>
  extends IBaseRequest {
  dto: T;
  url: string;
  captchaToken: string;
}

type ISendVerificationCodeExtra = {
  token: string;
};

type ISendVerificationCodeResponse = IBaseResponse<string> &
  ISendVerificationCodeExtra;

interface ITmpTokenPayload extends JwtPayload {
  seed: string;
}

export async function createRequestWithTempToken<T extends FlexibleObject, R>({
  api,
  dto,
  url,
  captchaToken,
}: ICreateRequestWithTempTokenProps<T>) {
  const tempTokenResponse = await api.post<ISendVerificationCodeResponse>(
    '/auth/tmp_tokens',
    {},
    {
      headers: {
        USER_ACTIVITY_G: captchaToken,
      },
    },
  );

  const token = tempTokenResponse.data.token;
  const seed = (jwtDecode(token) as ITmpTokenPayload).seed;

  const hash = await getVerificationSign(dto, seed);

  const response = await api.post<R>(
    url,
    {
      ...dto,
      sign: hash,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

  return response;
}
