export async function sha256(message: string, secret: string) {
  const enc = new TextEncoder();
  const algorithm = { name: 'HMAC', hash: 'SHA-256' };

  const key = await crypto.subtle.importKey(
    'raw',
    enc.encode(secret),
    algorithm,
    false,
    ['sign', 'verify'],
  );

  const signature = await crypto.subtle.sign(
    algorithm.name,
    key,
    enc.encode(message),
  );

  // convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(signature));

  // convert bytes to hex string
  return hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
}
