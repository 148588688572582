import { Currency } from '@/entities';
import { IBaseRequest, IBaseSortable } from '@/api/types';

interface IGetContactCurrenciesProps extends IBaseRequest, IBaseSortable {
  clientId?: string;
}

export async function getCurrencies({
  api,
  clientId,
  orderBy,
  orderDirection,
}: IGetContactCurrenciesProps): Promise<Currency[]> {
  const response = await api.get<Currency[]>(
    `clients/${clientId}/contacts/currencies`,
    {
      params: {
        orderBy,
        orderDirection,
      },
    },
  );

  return response.data;
}
