import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAuditBankLetterPdfProps extends IBaseRequest {
  clientId: string;
  dto: {
    paymentClientAccountId?: string;
    includeTransactions: boolean;
    since: number;
    till: number;
    contacts?: string;
    emails: string[];
  };
}

interface IGetAuditBankLetterPdfResponse {
  clientId: string;
  createdAt: number;
  id: string;
  state: DocumentState;
  updatedAt: number;
  userId: string;
  file: string;
  displayName: string;
}

export async function getAuditBankLetterPdf({
  api,
  clientId,
  dto,
}: IGetAuditBankLetterPdfProps) {
  const response = await api.post<IGetAuditBankLetterPdfResponse>(
    `clients/${clientId}/audit_bank_letter/pdf`,
    dto,
  );

  return response.data;
}
