import { IBaseRequest } from '@/api/types';

interface IGetDraftDocumentsProps extends IBaseRequest {
  id: string;
  clientId: string;
  limit?: number;
}

export async function getDraftDocuments({
  api,
  id,
  clientId,
  limit = 5,
}: IGetDraftDocumentsProps): Promise<{ suggestedDocumentIds?: string[] }> {
  const response = await api.get<{ suggestedDocumentIds?: string[] }>(
    `clients/${clientId}/drafts/${id}/suggested_documents`,
    {
      params: {
        limit,
      },
    },
  );

  return response.data;
}
