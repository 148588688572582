import { IBaseRequest } from '@/api/types';
import { AuditActivity } from '@/entities';
import { IGetAuditLogsParams } from './types';

interface IGetAuditLogsProps extends IBaseRequest {
  clientId: string;
  params: IGetAuditLogsParams;
}

export async function getAuditLogs({
  api,
  clientId,
  params,
}: IGetAuditLogsProps): Promise<AuditActivity[]> {
  const response = await api.get<AuditActivity[]>(
    `clients/${clientId}/audit_logs`,
    { params },
  );

  return response.data;
}
