import { Account } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPaymentMethodAccountsProps extends IBaseRequest {
  clientId: string;
  contactId: string;
  id: string;
}

export async function getPaymentMethodAccounts({
  api,
  clientId,
  contactId,
  id,
}: IGetPaymentMethodAccountsProps): Promise<Account[]> {
  const response = await api.get<Account[]>(
    `clients/${clientId}/contacts/${contactId}/payment_methods/${id}/client_accounts`,
  );

  return response.data;
}
