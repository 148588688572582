import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IActivateWiseProps extends IBaseRequest {
  clientId: string;
}

interface IActivateWiseResponse {
  success: boolean;
}

export async function activateWise({
  api,
  clientId,
}: IActivateWiseProps): Promise<IBaseResponse<IActivateWiseResponse>> {
  try {
    const response = await api.post<IBaseResponse<IActivateWiseResponse>>(
      `wise/clients/${clientId}/activate`,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: {
          success: response.data.success,
        },
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
