import { IRfi } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRfisProps extends IBaseRequest {
  clientId: string;
}

export async function getRfis({
  api,
  clientId,
}: IGetRfisProps): Promise<IRfi[]> {
  const response = await api.get<IRfi[]>(`clients/${clientId}/rfi`);

  return response.data;
}
