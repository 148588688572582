import { IDraftdValidateResult } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IValidatePreviewProps extends IBaseRequest {
  id: string;
  clientId: string;
}

export async function validateDraft({
  api,
  id,
  clientId,
}: IValidatePreviewProps): Promise<IDraftdValidateResult> {
  const response = await api.post<IDraftdValidateResult>(
    `clients/${clientId}/drafts/${id}/validate`,
  );

  return response.data;
}
