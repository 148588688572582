import { IBaseRequest } from '@/api/types';

interface IGetTestProps extends IBaseRequest {}

export async function getTest({ api }: IGetTestProps): Promise<unknown> {
  try {
    const response = await api.get<unknown>('auth/sessions/test');

    return response.data;
  } catch (e) {
    return undefined;
  }
}
