import { UserDocument } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IUploadDocumentsProps extends IBaseRequest {
  formData: FormData;
}

export async function uploadDocuments({
  api,
  formData,
}: IUploadDocumentsProps): Promise<IBaseResponse<UserDocument[]>> {
  try {
    const response = await api.post<IBaseResponse<UserDocument[]>>(
      `documents/bulk`,
      formData,
    );

    if (response.data.data) {
      return createSuccessResponse({
        result: response.data.data,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
