import { Transaction } from '@/entities';
import { IBaseRequest } from '@/api/types';

export interface IGetTransactionsParams {
  since?: number;
  till?: number;
  accountIds?: string;
  labelIds?: string;
  contactIds?: string;
  paymentKinds?: string;
  sameClient?: boolean;
  moneyDirection?: string;
  source?: string;
  amountGt?: number;
  amountLt?: number;
  search?: string;
  state?: string;
  kind?: string;
  kindNot?: string;
  documentIds?: string;
  states?: string;
  userIds?: string;
  recurring?: boolean;
  scheduled?: boolean;
  isDeferred?: boolean;
  limit?: number;
  offset?: number;
  orderBy?: string;
  orderDirection?: string;
}

interface IGetTransactionsProps extends IBaseRequest {
  clientId: string;
  params?: IGetTransactionsParams;
}

export async function getTransactions({
  api,
  clientId,
  params,
}: IGetTransactionsProps): Promise<Transaction[]> {
  const response = await api.get<Transaction[]>(
    `clients/${clientId}/display_transactions`,
    { params },
  );

  return response.data;
}
