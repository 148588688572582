import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import { User } from '@/entities';
import { AuthenticationPublicKeyCredential } from '@github/webauthn-json/browser-ponyfill';

interface ICreateWebAuthnSessionProps extends IBaseRequest {
  dto: {
    user: {
      id: string;
    };
    credential: AuthenticationPublicKeyCredential;
  };
}

interface ICreateWebAuthnSessionExtra {
  token: string;
  refreshToken: string;
}

type ICreateWebAuthnSessionResponse = IBaseResponse<User> &
  ICreateWebAuthnSessionExtra;

export async function createWebAuthnSession({
  api,
  dto,
}: ICreateWebAuthnSessionProps): Promise<
  IBaseResponse<User, ICreateWebAuthnSessionExtra>
> {
  try {
    const response = await api.post<ICreateWebAuthnSessionResponse>(
      'auth/sessions/by_webauthn_credential',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse<User, ICreateWebAuthnSessionExtra>({
        result: response.data.result,
        extra: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse<User, ICreateWebAuthnSessionExtra>({
      error: response.data.error,
      extra: {
        token: '',
        refreshToken: '',
      },
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
