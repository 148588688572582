import { IBaseRequest } from '@/api/types';
import { Counters, ICounter } from '@/entities';

interface ICountersProps extends IBaseRequest {
  clientId?: string;
  params?: {
    pendingDrafts?: boolean;
    createdDrafts?: boolean;
    draftRecipients?: boolean;
    clientUserInvites?: boolean;
    clientUserInactive?: boolean;
    displayTransactions?: boolean;
    deferredDisplayTransactions?: boolean;
    deferredDisplayTransactions_30?: boolean;
    favoriteContacts?: boolean;
    rfi?: boolean;
  };
}

export async function getCounters({
  api,
  params,
  clientId,
}: ICountersProps): Promise<Record<Counters, ICounter>> {
  const response = await api.get<Record<Counters, ICounter>>(
    `clients/${clientId}/counters`,
    {
      params,
    },
  );

  return response.data;
}
