import { IBaseRequest } from '@/api/types';

interface IGetUserIdsResult {
  userIds: (string | null)[];
}

export async function getUserIds({
  api,
}: IBaseRequest): Promise<IGetUserIdsResult> {
  const response = await api.get<IGetUserIdsResult>(`documents/user_ids`);

  return response.data;
}
