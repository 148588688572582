import { ISearch } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ICreateDocumentsSearchProps extends IBaseRequest {
  clientId?: string;
  dto: {
    searchPhrase: string;
    limit: number;
  };
}

export async function createDocumentsSearch({
  api,
  clientId,
  dto,
}: ICreateDocumentsSearchProps): Promise<IBaseResponse<ISearch>> {
  try {
    const response = await api.post<IBaseResponse<ISearch>>(
      `documents/search`,
      dto,
      {
        params: {
          client_ids: clientId,
        },
      },
    );
    if (response.data.data) {
      return createSuccessResponse({
        result: response.data.data,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
