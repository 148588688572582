import { IBaseRequest } from '@/api/types';

interface ILandingDocsTokenResult {
  success: boolean;
  token: string;
}

export async function getLandingDocsToken({
  api,
}: IBaseRequest): Promise<ILandingDocsTokenResult> {
  const response = await api.get<ILandingDocsTokenResult>(
    'user/landing_docs_token',
  );

  return response.data;
}
