import {
  ButtonText,
  Wrapper,
} from '@/ui/components/mobile/Button/index.styles.ts';
import {
  ButtonKind,
  ButtonStyleGuide,
  IButtonProps,
} from '@/ui/components/mobile/Button/types.ts';
import { ParentBackground } from '@/ui/components/types/shared.ts';
import { useMemo } from 'react';
import { disabledClickPrevent } from '@/functions/utils/disabledClickPrevent';
import { TextKind } from '@/ui/components/mobile/Text/types.ts';
import { IconLoader } from '@/ui/components/mobile/Icon/variations/Loader';
import { IconHourglass } from '@/ui/components/mobile/Icon/variations/Hourglass';

const TEXT_KIND_BY_BUTTON_KIND = {
  [ButtonKind.ENTITY_ACTION]: TextKind.CAPTION_REGULAR,
  [ButtonKind.MAIN]: TextKind.BODY_REGULAR,
  [ButtonKind.SECONDARY]: TextKind.CAPTION_REGULAR,
  [ButtonKind.LOAD_MORE]: TextKind.CAPTION_REGULAR,
};

export function Button({
  styleGuide = ButtonStyleGuide.BRAND,
  kind = ButtonKind.MAIN,
  parentBackground = ParentBackground.TWO,
  icon,
  fullWidth = false,
  children,
  onClick,
  disabled,
  loading = false,
  ...props
}: IButtonProps) {
  const renderedIcon = useMemo(() => {
    if (kind === ButtonKind.LOAD_MORE && loading) {
      return <IconHourglass key={kind} />;
    }

    if (loading) {
      return <IconLoader />;
    }

    return icon;
  }, [icon, loading, kind]);

  return (
    <Wrapper
      disabled={disabled || loading}
      onClick={disabledClickPrevent(disabled || loading, onClick)}
      styleGuide={styleGuide}
      kind={kind}
      hasIcon={Boolean(icon) || loading}
      parentBackground={parentBackground}
      fullWidth={fullWidth}
      hasChildren={Boolean(children)}
      {...props}
    >
      {renderedIcon}
      <ButtonText kind={TEXT_KIND_BY_BUTTON_KIND[kind]}>{children}</ButtonText>
    </Wrapper>
  );
}
