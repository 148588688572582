import { createContext, ReactNode, useContext } from 'react';
import { useFingerprint } from './useFingerprint';

export interface IFingerprintContext {
  id: string;
  score: number;
  sendFpAction: () => void;
  getFpScore: () => void;
  canContinue: boolean;
  regularActivity: boolean;
  isScoreLoading: boolean;
}

export const FingerprintContext = createContext<IFingerprintContext>({
  id: '',
  score: 0,
  sendFpAction: () => {},
  getFpScore: () => {},
  canContinue: true,
  regularActivity: true,
  isScoreLoading: true,
});

export function useFingerprintLocal() {
  return useContext(FingerprintContext);
}

interface IFingerprintProviderProps {
  children: ReactNode;
}

export function FingerprintProvider({ children }: IFingerprintProviderProps) {
  const {
    fingerprintId,
    sendEvent,
    getScore,
    score,
    canContinue,
    regularActivity,
    isScoreLoading,
  } = useFingerprint();

  return (
    <FingerprintContext.Provider
      value={{
        id: fingerprintId,
        sendFpAction: sendEvent,
        getFpScore: getScore,
        score,
        canContinue,
        regularActivity,
        isScoreLoading,
      }}
    >
      {children}
    </FingerprintContext.Provider>
  );
}
