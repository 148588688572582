import { IncomingInvite } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetUserInvitesProps extends IBaseRequest {
  params?: {
    limit?: number;
    state?: string;
  };
}

export async function getUserInvites({
  api,
  params,
}: IGetUserInvitesProps): Promise<IncomingInvite[]> {
  const response = await api.get<IncomingInvite[]>(`user/invites`, {
    params,
  });

  return response.data;
}
