import { ClosureClients } from '@/entities';
import { IBaseRequest } from '@/api/types';

export async function getClosableClients({
  api,
}: IBaseRequest): Promise<ClosureClients> {
  const response = await api.get<ClosureClients>('clients/closure');

  return response.data;
}
