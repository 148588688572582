import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IResponse {
  error: string | null;
  refreshToken: string | null;
  result: null;
  success: boolean;
  token: string | null;
}

interface IRefreshSessionProps extends IBaseRequest {
  refreshToken: string;
}

export async function refreshSession({
  api,
  refreshToken,
}: IRefreshSessionProps): Promise<
  IBaseResponse<{
    at: string;
    rt: string;
  }>
> {
  try {
    const response = await api.post<IResponse>('auth/sessions/refresh', {
      refreshToken,
    });

    if (
      response.data.success &&
      response.data.token &&
      response.data.refreshToken
    ) {
      return createSuccessResponse({
        result: {
          at: response.data.token,
          rt: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse({
      error: response.data.error ?? 'Cannot update session',
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
