import { IRoleTemplate } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRoleTemplatesProps extends IBaseRequest {
  clientId?: string;
}

export async function getRoleTemplates({
  api,
  clientId,
}: IGetRoleTemplatesProps): Promise<IRoleTemplate[]> {
  const response = await api.get<IRoleTemplate[]>(
    `clients/${clientId}/roles/templates`,
  );

  return response.data;
}
