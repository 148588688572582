import { UserClientUser } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetUserClientUser extends IBaseRequest {
  clientUserId: string;
}

export async function getUserClientUser({
  api,
  clientUserId,
}: IGetUserClientUser): Promise<UserClientUser> {
  const response = await api.get<UserClientUser>(
    `user/client_users/${clientUserId}`,
  );

  return response.data;
}
