import { ClientDraftSpecialActivity } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAvailableSpecialActivitiesProps extends IBaseRequest {
  clientDraftId: string;
}

export async function getAvailableSpecialActivities({
  api,
  clientDraftId,
}: IGetAvailableSpecialActivitiesProps) {
  const response = await api.get<ClientDraftSpecialActivity[]>(
    `client_drafts/${clientDraftId}/available_special_activities`,
  );

  return response.data;
}
