import { Wrapper } from '@/ui/components/mobile/Icon/shared';
import { SVGContainer } from '@/ui/components/mobile/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconDownloadFile(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 32 32">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.1851 28.415C6.5751 28.805 7.0301 29 7.5501 29H24.4501C24.9701 29 25.4251 28.805 25.8151 28.415C26.2051 28.025 26.4001 27.57 26.4001 27.05V10.93C26.4001 10.67 26.3514 10.4208 26.2539 10.1825C26.1564 9.94417 26.0101 9.7275 25.8151 9.5325L19.8676 3.585C19.6726 3.39 19.4559 3.24375 19.2176 3.14625C18.9793 3.04875 18.7301 3 18.4701 3H7.5501C7.0301 3 6.5751 3.195 6.1851 3.585C5.7951 3.975 5.6001 4.43 5.6001 4.95V27.05C5.6001 27.57 5.7951 28.025 6.1851 28.415ZM7.5501 4.95H18.3076V10.02C18.3076 10.3017 18.3997 10.5346 18.5839 10.7188C18.768 10.9029 19.0009 10.995 19.2826 10.995H24.4501V27.05H7.5501V10.995V10.7188V10.02V4.95ZM15.9997 15.2526C15.7181 15.2526 15.4852 15.3447 15.301 15.5288C15.1168 15.713 15.0247 15.9459 15.0247 16.2276L15.0247 21.7851L13.0097 19.7701C12.8364 19.5968 12.6197 19.5101 12.3597 19.5101C12.0997 19.5101 11.8722 19.6076 11.6772 19.8026C11.4822 19.9976 11.3847 20.2305 11.3847 20.5013C11.3847 20.7722 11.4822 20.9943 11.6772 21.1676L15.3822 24.7426C15.4906 24.8509 15.5989 24.9268 15.7073 24.9701C15.8156 25.0134 15.9347 25.0351 16.0647 25.0351C16.1947 25.0351 16.3139 25.0134 16.4222 24.9701C16.5306 24.9268 16.6389 24.8509 16.7472 24.7426L20.4198 21.0701C20.5931 20.8968 20.6798 20.6801 20.6798 20.4201C20.6798 20.1601 20.5822 19.9326 20.3872 19.7376C20.1922 19.5426 19.9648 19.4451 19.7048 19.4451C19.4447 19.4451 19.2172 19.5426 19.0222 19.7376L16.9747 21.7851V16.2276C16.9747 15.9459 16.8827 15.713 16.6985 15.5288C16.5143 15.3447 16.2814 15.2526 15.9997 15.2526Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
