export function fancyTimeFromSeconds(durationInSeconds: number) {
  // Hours, minutes and seconds
  const hrs = ~~(durationInSeconds / 3600);
  const mins = ~~((durationInSeconds % 3600) / 60);
  const secs = ~~durationInSeconds % 60;

  // Output like "1:01" or "4:03:59" or "123:03:59"
  let ret = '';

  if (hrs > 0) {
    ret += String(hrs) + 'h ' + (mins < 10 ? '0' : '');
  }

  if (mins > 0) {
    ret += String(mins) + 'm ' + (secs < 10 ? '0' : '');
  }

  ret += String(secs) + 's';
  return ret;
}
