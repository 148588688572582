import { IBaseRequest } from '@/api/types';
import { UnifiedAccount } from '@/entities';

interface IGetAccountProps extends IBaseRequest {
  id: string;
}

export async function getAccount({
  api,
  id,
}: IGetAccountProps): Promise<UnifiedAccount> {
  const response = await api.get<UnifiedAccount>(`accounts/${id}`);

  return response.data;
}
