import { ClosableAccount } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetClosureAccountsProps extends IBaseRequest {
  clientId: string;
}

export async function getClosureAccounts({
  api,
  clientId,
}: IGetClosureAccountsProps): Promise<ClosableAccount[]> {
  const response = await api.get<ClosableAccount[]>(
    `clients/${clientId}/accounts/closure`,
  );

  return response.data;
}
