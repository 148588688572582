import { ISearch } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface ICreateSearchProps extends IBaseRequest {
  clientId?: string;
  dto: {
    searchPhrase: string;
    limit: number;
  };
}

export async function createSearch({
  api,
  clientId,
  dto,
}: ICreateSearchProps): Promise<IBaseResponse<ISearch>> {
  try {
    const response = await api.post<IBaseResponse<ISearch>>(
      `clients/${clientId}/search`,
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
