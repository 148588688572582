import { IAgreementSign } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAgreementSignsProps extends IBaseRequest {
  clientId: string;
  state: string;
}

export async function getAgreementSigns({
  api,
  clientId,
  state,
}: IGetAgreementSignsProps) {
  const response = await api.get<IAgreementSign[]>(
    `clients/${clientId}/agreement_signs`,
    {
      params: {
        state,
      },
    },
  );

  return response.data;
}
