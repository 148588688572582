import { useCallback } from 'react';
import { useCopyToClipboard, useOrientation } from 'react-use';
import {
  browserName,
  browserVersion,
  deviceType,
  engineName,
  engineVersion,
  getUA,
  mobileModel,
  mobileVendor,
  osName,
  osVersion,
} from 'react-device-detect';
import { getConfiguration } from '@/config/configuration';
import { useUser } from '@/hooks/user/useUser';
import { useSelectedClient } from '@/providers/ClientSelectProvider/useSelectedClient';
import { useToastNotifications as useDesktopToastNotifications } from '@/ui/components/desktop/ToastNotification/useToastNotifications';
import { useToastNotifications as useMobileToastNotifications } from '@/ui/components/mobile/ToastNotification/useToastNotifications';
import { useSettings } from '@/hooks/user/useSettings';
import { hasUserMedia } from '@/functions/utils/hasUserMedia';
import { useResolution } from './useResolution';

export function useDeviceReport() {
  const { user } = useUser();
  const { clientId } = useSelectedClient();
  const { angle, type } = useOrientation();
  const {
    app: { version },
  } = getConfiguration();
  const { settings } = useSettings();
  const [, copyToClipboard] = useCopyToClipboard();
  const { isMobile } = useResolution();

  const desktopToastNotifications = useDesktopToastNotifications();
  const mobileToastNotifications = useMobileToastNotifications();

  const collectDeviceAndAccountInformation = useCallback(
    () => ({
      userID: user?.id,
      clientID: clientId,

      windowOuterHeight: window.outerHeight,
      windowOuterWidth: window.outerWidth,

      windowInnerHeight: window.innerHeight,
      windowInnerWidth: window.innerWidth,

      documentHeight: document.documentElement.clientHeight,
      documentWidth: document.documentElement.clientWidth,

      deviceOrientationAngle: angle,
      deviceOrientationType: type,

      browserName,
      osName,
      osVersion,
      browserVersion,
      mobileVendor,
      mobileModel,
      engineName,
      engineVersion,
      getUA,
      deviceType,
      version,

      onBoardingStep: settings?.onboarding?.[clientId]?.step,
      onBoardingType: settings?.onboarding?.[clientId]?.type,
      settingsTfaChannel: settings?.tfaChannel,
      settingsDeactivationRequested: settings?.deactivationRequested,

      mediaDevicesGetUserMedia: hasUserMedia(),
    }),
    [
      angle,
      clientId,
      settings?.deactivationRequested,
      settings?.onboarding,
      settings?.tfaChannel,
      type,
      user?.id,
      version,
    ],
  );

  const handleDownloadReport = useCallback(() => {
    const information = collectDeviceAndAccountInformation();
    const jsonString = JSON.stringify(information);

    if (isMobile) {
      copyToClipboard(jsonString);
      mobileToastNotifications.handleSuccess('Device report copied');
    } else {
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'device_report.json';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      desktopToastNotifications.handleSuccess('Device report saved');
    }
  }, [
    collectDeviceAndAccountInformation,
    copyToClipboard,
    desktopToastNotifications,
    isMobile,
    mobileToastNotifications,
  ]);

  return {
    collectDeviceAndAccountInformation,
    handleDownloadReport,
  };
}
