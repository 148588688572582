import { IBaseRequest } from '@/api/types';

export interface IDocumentsCountParams {
  accountsIds?: string;
  displayName?: string;
  documentKinds?: string;
  clientIds?: string;
  createdSince?: number;
  createdTill?: number;
  comment?: string;
  ids?: string;
  isPinned?: boolean;
  kind?: string;
  kinds?: string;
  kindNot?: string;
  sources?: string;
  states?: string;
  subjectIds?: string;
  userIds?: string;
}

interface IFetchDocumentsCountProps extends IBaseRequest {
  params?: IDocumentsCountParams;
}

interface IFetchDocumentsCountResponse {
  count?: number;
}

export async function fetchDocumentsCount({
  api,
  params,
}: IFetchDocumentsCountProps): Promise<IFetchDocumentsCountResponse> {
  const response = await api.get<IFetchDocumentsCountResponse>(
    `documents/count`,
    {
      params,
    },
  );

  return response.data;
}
