import { ReactNode } from 'react';
import { useLockBodyScroll } from 'react-use';

interface ILockedModalProps {
  children: ReactNode;
}

export function LockedModal({ children }: ILockedModalProps) {
  useLockBodyScroll(true);

  return <>{children}</>;
}
