import { IAddressesSuggestion } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAddressPredictionsProps extends IBaseRequest {
  clientId: string;
  address: string;
}

export async function getAddressPredictions({
  api,
  clientId,
  address,
}: IGetAddressPredictionsProps): Promise<IAddressesSuggestion[]> {
  const response = await api.get<IAddressesSuggestion[]>(
    `clients/${clientId}/addresses/predictions`,
    { params: { address } },
  );

  return response.data;
}
