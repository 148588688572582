import { IBaseRequest } from '@/api/types';
import { Tariff } from '@/entities';

interface ITariffProps extends IBaseRequest {
  clientId?: string;
}

export async function getTariff({
  api,
  clientId,
}: ITariffProps): Promise<Tariff> {
  const response = await api.get<Tariff>(`clients/${clientId}/tariff`);

  return response.data;
}
