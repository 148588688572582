const TRANSITION = {
  opened: {
    duration: 0.6,
    ease: [0.22, 1, 0.36, 1],
    opacity: {
      duration: 0.1,
      ease: [0.61, 1, 0.88, 1],
    },
  },
  closed: {
    duration: 0.1,
    ease: [0.61, 1, 0.88, 1],
  },
};

export const VARIANTS = {
  initial: {
    opacity: 0,
    scale: 1.02,
    backdropFilter: 'blur(0px)',
    webkitBackdropFilter: 'blur(0px)',
    background: 'rgba(11, 28, 73, 0)',
  },
  opened: {
    opacity: 1,
    scale: 1,
    backdropFilter: 'blur(6px)',
    webkitBackdropFilter: 'blur(6px)',
    background: 'rgba(11, 28, 73, 0.25)',
    transition: TRANSITION.opened,
  },
  closed: {
    opacity: 0,
    transition: TRANSITION.closed,
  },
};
