import { css } from '@emotion/react';
import { getBreakpoint } from '@/functions/utils/getBreakpoint';
import { isSafari, browserVersion } from 'react-device-detect';

const isModernSafari = isSafari && Number(browserVersion) >= 12;

export const toastStyles = css`
  .Toastify__toast-container {
    width: 384px;

    @media (max-width: ${getBreakpoint('MOBILE_LANDSCAPE', 'down')}) {
      width: 100%;
      top: 20px;
      left: 0;
      padding: 0 20px;
    }
  }

  .Toastify__toast-icon {
    display: none;
  }

  .Toastify__toast {
    min-height: initial;
    padding: 0;
    margin-bottom: 20px;
    border-radius: 12px;
    background: transparent;
    box-shadow: none;
  }

  .Toastify__slide-enter--top-center {
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }

  .Toastify__toast-body {
    display: block;
    margin: 0;
    padding: 0;
  }

  .Toastify__progress-bar {
    height: 4px;
    opacity: 1;
  }

  .Toastify__progress-bar--bg {
    height: 0;
  }

  .Toastify__progress-bar--wrp {
    bottom: -1px;
  }

  .Toastify__progress-bar.brand {
    background-color: var(--color-brand-default);
  }

  .Toastify__progress-bar.danger {
    background-color: var(--color-danger-default);
  }

  .Toastify__progress-bar.success {
    background-color: var(--color-success-default);
  }

  .Toastify__progress-bar.waiting {
    background-color: var(--color-waiting-default);
  }
`;

export const basicStyles = css`
  * {
    letter-spacing: 0.05px !important;
    box-sizing: border-box;
  }

  body {
    margin: 0;
    padding: 0;
    background: var(--color-background-light);
    overflow-x: hidden;
  }

  .x-hellosign-embedded--in-modal {
    z-index: 1000;
  }
`;

export const scroll = isModernSafari
  ? css``
  : css`
      @media (min-width: ${getBreakpoint('MOBILE_LANDSCAPE')}) {
        &::-webkit-scrollbar {
          width: var(--scrollbar-width);
        }

        &::-webkit-scrollbar-track {
          background: var(--scrollbar-track-color);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--scrollbar-thumb-color);
          border-radius: 4px;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: var(--scrollbar-thumb-hover-color);
        }
      }
    `;
