import { IBaseRequest } from '@/api/types';
import { IContactPaymentMethodFields } from '@/entities';

interface IGetBankPaymentMethodFieldsProps extends IBaseRequest {
  clientId: string;
  params: {
    receiverCountryId?: string;
    receiverCurrencyId?: string;
  };
}

export async function getBankPaymentMethodFields({
  api,
  clientId,
  params,
}: IGetBankPaymentMethodFieldsProps): Promise<IContactPaymentMethodFields[]> {
  const response = await api.get<IContactPaymentMethodFields[]>(
    `clients/${clientId}/payment_methods/payment_method_fields`,
    {
      params,
    },
  );

  return response.data;
}
