import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ParentBackground } from '../../types/shared';
import { Text } from '../Text';
import {
  IToastMessageStylesProps,
  ToastMessageKind,
} from '../../types/toastNotification';

const STYLEGUIDE = {
  [ParentBackground.ONE]: {
    [ToastMessageKind.BRAND]: css`
      --background-color: var(--color-brand-additional-on-bg-one);
      --content-color: var(--color-brand-default);
    `,
    [ToastMessageKind.WAITING]: css`
      --background-color: var(--color-waiting-additional-on-bg-one);
      --content-color: var(--color-waiting-default);
    `,
    [ToastMessageKind.SUCCESS]: css`
      --background-color: var(--color-success-additional-on-bg-one);
      --content-color: var(--color-success-default);
    `,
    [ToastMessageKind.DANGER]: css`
      --background-color: var(--color-danger-additional-on-bg-one);
      --content-color: var(--color-danger-default);
    `,
  },
  [ParentBackground.TWO]: {
    [ToastMessageKind.BRAND]: css`
      --background-color: var(--color-brand-additional-on-bg-two);
      --content-color: var(--color-brand-default);
    `,
    [ToastMessageKind.WAITING]: css`
      --background-color: var(--color-waiting-additional-on-bg-two);
      --content-color: var(--color-waiting-default);
    `,
    [ToastMessageKind.SUCCESS]: css`
      --background-color: var(--color-success-additional-on-bg-two);
      --content-color: var(--color-success-default);
    `,
    [ToastMessageKind.DANGER]: css`
      --background-color: var(--color-danger-additional-on-bg-two);
      --content-color: var(--color-danger-default);
    `,
  },
};

export const ToastContainer = styled.div<IToastMessageStylesProps>(
  ({ kind, parentBackground }) => css`
    ${STYLEGUIDE[parentBackground][kind]}

    display: grid;
    grid-auto-flow: column;
    grid-column-gap: 10px;
    align-items: start;
    box-sizing: border-box;
    padding: 18px;
    border-radius: 12px;
    background-color: var(--background-color);
  `,
);

export const StyledText = styled(Text)`
  color: var(--content-color);
  word-break: break-word;
`;
