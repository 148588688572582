import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { ParentBackground } from '@/ui/components/types/shared.ts';
import {
  ButtonKind,
  ButtonStyleGuide,
  MergedButtonStyleGuide,
} from '@/ui/components/desktop/Button/types.ts';
import { TextOrChildren } from '@/ui/components/desktop/Text';
import { Wrapper as IconWrapper } from '@/ui/components/desktop/Icon/shared';

interface IWrapperStyleProps {
  hasChildren: boolean;
  styleGuide: ButtonStyleGuide;
  kind: ButtonKind;
  hasIcon: boolean;
  fullWidth: boolean;
  parentBackground: ParentBackground;
  transparent: boolean;
}

const DIMENSIONS = {
  [ButtonKind.SMALL]: css`
    --padding-vertical: 4px;
    --padding-horizontal: 12px;
    --column-gap: 6px;
    --border-radius: 6px;
    --button-icon-size: 16px;
    --button-min-height: 24px;
  `,
  [ButtonKind.MEDIUM]: css`
    --padding-vertical: 7px;
    --padding-horizontal: 16px;
    --column-gap: 10px;
    --border-radius: 8px;
    --button-icon-size: 20px;
    --button-min-height: 34px;
  `,
  [ButtonKind.LARGE]: css`
    --padding-vertical: 12px;
    --padding-horizontal: 20px;
    --column-gap: 10px;
    --border-radius: 12px;
    --button-icon-size: 24px;
    --button-min-height: 48px;
  `,
};

const SHARED_STYLEGUIDES = {
  [MergedButtonStyleGuide.BRAND_INVERTED]: {
    default: css`
      --background-color: var(--color-brand-default);
      --content-color: var(--color-white-static);
    `,
    hover: css`
      --background-color: var(--color-brand-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-brand-default);
      --content-color: var(--color-label-tertiary);
    `,
    focus: css`
      --background-color: var(--color-brand-default);
      --content-color: var(--color-white-static);
      --color-shadow: var(--color-brand-default);
    `,
  },
  [MergedButtonStyleGuide.DANGER_INVERTED]: {
    default: css`
      --background-color: var(--color-danger-default);
      --content-color: var(--color-white-static);
    `,
    hover: css`
      --background-color: var(--color-danger-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-danger-default);
      --content-color: var(--color-white-static);
    `,
    focus: css`
      --background-color: var(--color-danger-default);
      --content-color: var(--color-white-static);
      --color-shadow: var(--color-danger-default);
    `,
  },
  [MergedButtonStyleGuide.WAITING_INVERTED]: {
    default: css`
      --background-color: var(--color-waiting-default);
      --content-color: var(--color-white-static);
    `,
    hover: css`
      --background-color: var(--color-waiting-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-waiting-default);
      --content-color: var(--color-white-static);
    `,
    focus: css`
      --background-color: var(--color-waiting-default);
      --content-color: var(--color-white-static);
      --color-shadow: var(--color-waiting-default);
    `,
  },
  [MergedButtonStyleGuide.SUCCESS_INVERTED]: {
    default: css`
      --background-color: var(--color-success-default);
      --content-color: var(--color-white-static);
    `,
    hover: css`
      --background-color: var(--color-success-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-success-default);
      --content-color: var(--color-label-tertiary);
    `,
    focus: css`
      --background-color: var(--color-success-default);
      --content-color: var(--color-white-static);
      --color-shadow: var(--color-success-default);
    `,
  },
  [MergedButtonStyleGuide.GRADIENT]: {
    default: css`
      --background-color: var(--color-gradient-default);
      --content-color: var(--color-white-static);
    `,
    hover: css`
      --background-color: var(--color-gradient-default);
      --content-color: var(--color-white-static);
    `,
    pressed: css`
      --background-color: var(--color-gradient-default);
      --content-color: var(--color-label-tertiary);
    `,
    focus: css`
      --background-color: var(--color-gradient-default);
      --content-color: var(--color-white-static);
      --color-shadow: var(--color-brand-default);
    `,
  },
};

const STYLEGUIDE = {
  [ParentBackground.ONE]: {
    [MergedButtonStyleGuide.BRAND]: {
      default: css`
        --background-color: var(--color-brand-additional-on-bg-one);
        --content-color: var(--color-brand-default);
      `,
      hover: css`
        --background-color: var(--color-hover-brand-surface-on-bg-one);
        --content-color: var(--color-brand-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-brand-surface-on-bg-one);
        --content-color: var(--color-brand-default);
      `,
      focus: css`
        --background-color: var(--color-brand-additional-on-bg-one);
        --content-color: var(--color-brand-default);
        --color-shadow: var(--color-brand-default);
      `,
    },
    [MergedButtonStyleGuide.DANGER]: {
      default: css`
        --background-color: var(--color-danger-additional-on-bg-one);
        --content-color: var(--color-danger-default);
      `,
      hover: css`
        --background-color: var(--color-hover-danger-surface-on-bg-one);
        --content-color: var(--color-danger-default);
      `,
      pressed: css`
        --background-color: var(--color-hover-danger-surface-on-bg-one);
        --content-color: var(--color-danger-default);
      `,
      focus: css`
        --background-color: var(--color-danger-additional-on-bg-one);
        --content-color: var(--color-danger-default);
        --color-shadow: var(--color-danger-default);
      `,
    },
    [MergedButtonStyleGuide.WAITING]: {
      default: css`
        --background-color: var(--color-waiting-additional-on-bg-one);
        --content-color: var(--color-waiting-default);
      `,
      hover: css`
        --background-color: var(--color-waiting-additional-on-bg-two);
        --content-color: var(--color-waiting-default);
      `,
      pressed: css`
        --background-color: var(--color-waiting-additional-on-bg-two);
        --content-color: var(--color-waiting-default);
      `,
      focus: css`
        --background-color: var(--color-waiting-additional-on-bg-two);
        --content-color: var(--color-waiting-default);
        --color-shadow: var(--color-waiting-default);
      `,
    },
    [MergedButtonStyleGuide.SUCCESS]: {
      default: css`
        --background-color: var(--color-success-additional-on-bg-one);
        --content-color: var(--color-success-default);
      `,
      hover: css`
        --background-color: var(--color-hover-success-surface-on-bg-one);
        --content-color: var(--color-success-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-successr-surface-on-bg-one);
        --content-color: var(--color-success-default);
      `,
      focus: css`
        --background-color: var(--color-success-additional-on-bg-one);
        --content-color: var(--color-success-default);
        --color-shadow: var(--color-success-default);
      `,
    },
    [MergedButtonStyleGuide.DISABLED]: {
      default: css`
        --background-color: var(--color-disabled-on-bg-one);
        --content-color: var(--color-label-tertiary);
        --color-shadow: none;
      `,
    },
    ...SHARED_STYLEGUIDES,
  },
  [ParentBackground.TWO]: {
    [MergedButtonStyleGuide.BRAND]: {
      default: css`
        --background-color: var(--color-brand-additional-on-bg-two);
        --content-color: var(--color-brand-default);
      `,
      hover: css`
        --background-color: var(--color-hover-brand-surface-on-bg-two);
        --content-color: var(--color-brand-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-brand-surface-on-bg-two);
        --content-color: var(--color-brand-default);
      `,
      focus: css`
        --background-color: var(--color-brand-additional-on-bg-two);
        --content-color: var(--color-brand-default);
        --color-shadow: var(--color-brand-default);
      `,
    },
    [MergedButtonStyleGuide.DANGER]: {
      default: css`
        --background-color: var(--color-danger-additional-on-bg-two);
        --content-color: var(--color-danger-default);
      `,
      hover: css`
        --background-color: var(--color-hover-danger-surface-on-bg-two);
        --content-color: var(--color-danger-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-danger-surface-on-bg-two);
        --content-color: var(--color-danger-default);
      `,
      focus: css`
        --background-color: var(var(--color-danger-additional-on-bg-two));
        --content-color: var(--color-danger-default);
        --color-shadow: var(--color-danger-default);
      `,
    },
    [MergedButtonStyleGuide.WAITING]: {
      default: css`
        --background-color: var(--color-waiting-additional-on-bg-two);
        --content-color: var(--color-waiting-default);
      `,
      hover: css`
        --background-color: var(--color-waiting-additional-on-bg-one);
        --content-color: var(--color-waiting-default);
      `,
      pressed: css`
        --background-color: var(--color-waiting-additional-on-bg-one);
        --content-color: var(--color-waiting-default);
      `,
      focus: css`
        --background-color: var(--color-waiting-additional-on-bg-one);
        --content-color: var(--color-waiting-default);
        --color-shadow: var(--color-waiting-default);
      `,
    },
    [MergedButtonStyleGuide.SUCCESS]: {
      default: css`
        --background-color: var(--color-success-additional-on-bg-two);
        --content-color: var(--color-success-default);
      `,
      hover: css`
        --background-color: var(--color-hover-success-surface-on-bg-two);
        --content-color: var(--color-success-default);
      `,
      pressed: css`
        --background-color: var(--color-pressed-success-surface-on-bg-two);
        --content-color: var(--color-success-default);
      `,
      focus: css`
        --background-color: var(--color-success-additional-on-bg-two);
        --content-color: var(--color-success-default);
        --color-shadow: var(--color-success-default);
      `,
    },
    [MergedButtonStyleGuide.DISABLED]: {
      default: css`
        --background-color: var(--color-disabled-on-bg-two);
        --content-color: var(--color-label-tertiary);
        --color-shadow: none;
      `,
    },
    ...SHARED_STYLEGUIDES,
  },
};

const clickAnimation = css`
  transform: scale(1);

  &:active {
    transform: scale(0.98);
    transition:
      transform 0.3s cubic-bezier(0.16, 1, 0.3, 1),
      background-color 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  }
`;

export const Wrapper = styled.button<IWrapperStyleProps>(
  ({
    hasChildren,
    fullWidth,
    onClick,
    disabled,
    kind,
    styleGuide,
    parentBackground,
    hasIcon,
    transparent,
  }) => css`
    ${DIMENSIONS[kind]};
    ${STYLEGUIDE[parentBackground][styleGuide].default}
    ${transparent &&
    css`
      --background-color: transparent;
    `}

    display: flex;
    align-items: center;
    justify-content: center;
    width: ${fullWidth ? '100%' : 'fit-content'};
    height: fit-content;
    min-height: var(--button-min-height);
    margin: 0;
    padding: var(--padding-vertical) var(--padding-horizontal)
      var(--padding-vertical)
      ${hasIcon
        ? 'calc(var(--padding-horizontal) - 4px)'
        : 'var(--padding-horizontal)'};
    cursor: pointer;
    border: none;
    border-radius: var(--border-radius);
    background: var(--background-color);
    transition:
      transform 1.2s cubic-bezier(0.22, 1, 0.36, 1),
      background-color 0.6s cubic-bezier(0.22, 1, 0.36, 1);
    ${onClick && !disabled && clickAnimation}

    ${IconWrapper} {
      --icon-width: var(--button-icon-size);
      --icon-height: var(--button-icon-size);
      --icon-color: var(--content-color);

      margin-right: ${hasChildren && 'var(--column-gap)'};

      & > svg {
        transition: color 0.6s cubic-bezier(0.22, 1, 0.36, 1);
      }
    }

    & > span,
    p {
      color: var(--content-color);
      transition: color 0.6s cubic-bezier(0.16, 1, 0.3, 1);
    }

    &:hover {
      ${transparent
        ? STYLEGUIDE[parentBackground][styleGuide].default
        : STYLEGUIDE[parentBackground][styleGuide].hover}
    }

    &:active {
      ${STYLEGUIDE[parentBackground][styleGuide].pressed}

      & > span,
      p {
        color: var(--content-color);
        transition: color 0.3s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }

    &:focus-visible {
      ${STYLEGUIDE[parentBackground][styleGuide].focus}

      outline: none;

      &::after {
        opacity: 0.7;
      }
    }

    &:disabled {
      ${STYLEGUIDE[parentBackground][MergedButtonStyleGuide.DISABLED].default};
      ${transparent &&
      css`
        --background-color: transparent;
      `}

      cursor: not-allowed;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
      pointer-events: none;
      user-select: none;
      border-radius: var(--border-radius);
      box-shadow: 0 0 6px 0 var(--color-shadow);
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
    }
  `,
);

export const ButtonText = styled(TextOrChildren)`
  color: var(--content-color);
  transition: color 0.3s cubic-bezier(0.16, 1, 0.3, 1);
`;
