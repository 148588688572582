import { BankEntity } from '@/entities';
import { IBaseRequest } from '@/api/types';

export async function getBankEntities({
  api,
}: IBaseRequest): Promise<BankEntity[]> {
  const response = await api.get<BankEntity[]>('bank_entities');

  return response.data;
}
