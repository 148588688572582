import { IBaseRequest } from '@/api/types';
import { IAuditLog } from '@/entities/AuditLog.ts';

interface IGetTransactionAuditLogsProps extends IBaseRequest {
  clientId: string;
  id: string;
}

export async function getTransactionAuditLogs({
  api,
  clientId,
  id,
}: IGetTransactionAuditLogsProps): Promise<IAuditLog[]> {
  const response = await api.get<IAuditLog[]>(
    `clients/${clientId}/display_transactions/${id}/audit_logs`,
  );

  return response.data;
}
