import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import {
  CredentialCreationOptionsJSON,
  CredentialRequestOptionsJSON,
} from '@github/webauthn-json/browser-ponyfill';

interface ICreateRegisterWebAuthnChallengeProps extends IBaseRequest {}

interface ICreateRegisterWebAuthnChallengeResponse {
  success: boolean;
  creationOptions: CredentialCreationOptionsJSON['publicKey'];
  error?: string;
}

export async function createRegisterWebAuthnChallenge({
  api,
}: ICreateRegisterWebAuthnChallengeProps): Promise<
  IBaseResponse<ICreateRegisterWebAuthnChallengeResponse['creationOptions']>
> {
  try {
    const response = await api.post<ICreateRegisterWebAuthnChallengeResponse>(
      'auth/webauthn_challenges/creation',
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.creationOptions,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}

interface ICreateLoginWebAuthnChallengeProps extends IBaseRequest {
  dto: {
    user: {
      id: string;
    };
  };
}

interface ICreateLoginWebAuthnChallengeResponse {
  success: boolean;
  authenticationOptions: CredentialRequestOptionsJSON['publicKey'];
  error?: string;
}

export async function createLoginWebAuthnChallenge({
  api,
  dto,
}: ICreateLoginWebAuthnChallengeProps): Promise<
  IBaseResponse<ICreateLoginWebAuthnChallengeResponse['authenticationOptions']>
> {
  try {
    const response = await api.post<ICreateLoginWebAuthnChallengeResponse>(
      'auth/webauthn_challenges/authentication',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.authenticationOptions,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
