import { IBaseRequest } from '@/api/types';
import { IGetPaymentsParams } from './types';
import { Payment } from '@/entities/Payments';

interface IGetNeedAttentionPaymentsProps extends IBaseRequest {
  clientId: string;
  params?: IGetPaymentsParams;
}

export async function getNeedAttentionPayments({
  api,
  clientId,
  params,
}: IGetNeedAttentionPaymentsProps): Promise<Payment[]> {
  const response = await api.get<Payment[]>(
    `clients/${clientId}/payments/need_attention`,
    { params },
  );

  return response.data;
}
