import { DocumentState } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAccountsMonthlyStatementPdfProps extends IBaseRequest {
  clientId: string;
  params?: {
    accountIds?: string;
    pdfDisplayName?: string;
    pdfFilename?: string;
    since?: number;
    till?: number;
  };
}

interface IGetAccountsMonthlyStatementPdfResponse {
  clientId: string;
  createdAt: number;
  id: string;
  state: DocumentState;
  updatedAt: number;
  userId: string;
}

export async function getAccountsMonthlyStatementPdf({
  api,
  clientId,
  params,
}: IGetAccountsMonthlyStatementPdfProps) {
  const response = await api.get<IGetAccountsMonthlyStatementPdfResponse>(
    `clients/${clientId}/client_accounts/statement/pdf`,
    { params },
  );

  return response.data;
}
