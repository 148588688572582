import { ParentBackground } from '../../types/shared';
import { IToastMessageProps } from '../../types/toastNotification';
import { TextKind } from '../Text/types';
import { StyledText, ToastContainer } from './index.style';

export function ToastMessage({
  message,
  kind,
  parentBackground = ParentBackground.ONE,
  customButton,
}: IToastMessageProps) {
  return (
    <ToastContainer parentBackground={parentBackground} kind={kind}>
      <StyledText kind={TextKind.BODY_SMALL}>{message}</StyledText>
      {customButton}
    </ToastContainer>
  );
}
