import { AuthMethod } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAvailableTfaChannelsProps extends IBaseRequest {}

interface IAvailableTfaChannels {
  channels: AuthMethod[];
}

export async function getAvailableTfaChannels({
  api,
}: IGetAvailableTfaChannelsProps): Promise<IAvailableTfaChannels> {
  const response = await api.get<IAvailableTfaChannels>(`user/tfa_channels`);

  return response.data;
}
