import { Client } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetClientProps extends IBaseRequest {
  clientId: string;
}

export async function getClient({ api, clientId }: IGetClientProps) {
  const response = await api.get<Client>(`clients/${clientId}`);

  return response.data;
}
