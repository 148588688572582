import { IBaseRequest } from '@/api/types';

interface IGetFingerprintCountProps extends IBaseRequest {
  fingerprint: string;
}

interface IGetFingerprintCountResponse {
  count: number;
}

export async function getFingerprintCount({
  api,
  fingerprint,
}: IGetFingerprintCountProps) {
  try {
    const response = await api.get<IGetFingerprintCountResponse>(
      `special_cases/fingerprint`,
      { params: { fingerprint } },
    );

    return {
      success: true,
      count: response.data.count,
    };
  } catch (error) {
    return {
      success: false,
      count: undefined,
    };
  }
}
