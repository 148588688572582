import { IConfirmationModalProps } from '@/ui/components/mobile/ConfirmationModal/types.ts';
import { createPortal } from 'react-dom';
import {
  ButtonsWrapper,
  ModalWrapper,
  ScrollableWrapper,
  StyledModal,
  TimeoutIndicator,
  TimeoutIndicatorWrapper,
  Title,
  TitleWrapper,
} from '@/ui/components/mobile/ConfirmationModal/index.styles.ts';
import { TextKind } from '../Text/types';
import { getPercentage } from '@/functions/utils/getPercentage.ts';
import { useMemo } from 'react';
import { Button } from '@/ui/components/mobile/Button';
import { ButtonKind } from '@/ui/components/mobile/Button/types.ts';

export function ConfirmationModal({
  opened,
  title,
  timer,
  children,
  className,
  closeByClickOutside,
  buttons,
  buttonsOrientation,
  close,
}: IConfirmationModalProps) {
  const renderedButtons = useMemo(
    () =>
      buttons?.map((props, index) => (
        <Button kind={ButtonKind.MAIN} {...props} key={index} />
      )),
    [buttons],
  );

  return createPortal(
    <StyledModal
      opened={opened}
      close={close}
      closeByClickOutside={closeByClickOutside}
    >
      <ModalWrapper className={className}>
        {timer && (
          <TimeoutIndicatorWrapper>
            <TimeoutIndicator
              widthPercent={getPercentage(timer.remaining, timer.initial)}
            />
          </TimeoutIndicatorWrapper>
        )}
        {title && (
          <TitleWrapper>
            <Title kind={TextKind.TITLE_H4}>{title}</Title>
          </TitleWrapper>
        )}
        {children && <ScrollableWrapper>{children}</ScrollableWrapper>}
        {buttons && (
          <ButtonsWrapper orientation={buttonsOrientation}>
            {renderedButtons}
          </ButtonsWrapper>
        )}
      </ModalWrapper>
    </StyledModal>,
    document.body,
  );
}
