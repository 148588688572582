import { ReferralCode } from '@/entities';
import { IBaseRequest } from '@/api/types';

export async function getReferralCode({
  api,
}: IBaseRequest): Promise<ReferralCode> {
  const response = await api.get<ReferralCode>(`user/referral_code`);

  return response.data;
}
