import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createSuccessResponse,
  createErrorResponse,
  createErrorHandler,
} from '@/api/helpers';
import { IAccountLimits } from '@/entities';

interface IClientUserAccountDto {
  clientAccountId: string;
  limits: IAccountLimits;
}

interface IChangeAdminProps extends IBaseRequest {
  dto: {
    clientId: string;
    newRoleId: string;
    accounts: IClientUserAccountDto[];
    newAdminId: string;
    channel: string;
    verificationCode: string;
  };
}

export async function changeAdmin({
  api,
  dto,
}: IChangeAdminProps): Promise<IBaseResponse<string>> {
  try {
    const response = await api.post<IBaseResponse<string>>(
      'special_cases/change_admin',
      dto,
    );

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      errors: response.data.errors,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
