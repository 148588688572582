import { OutgoingInvite } from '@/entities';
import { IBaseRequest } from '@/api/types';

export interface IGetClientUserInvitesParams {
  roleId?: string;
  limit?: number;
  notAccepted?: boolean;
}

interface IGetClientUserInvitesProps extends IBaseRequest {
  clientId?: string;
  params?: IGetClientUserInvitesParams;
}

export async function getClientUserInvites({
  api,
  clientId,
  params,
}: IGetClientUserInvitesProps) {
  const response = await api.get<OutgoingInvite[]>(
    `clients/${clientId}/invites`,
    { params },
  );

  return response.data;
}
