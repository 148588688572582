import { useState, useCallback, useEffect } from 'react';

export interface IModalController {
  opened: boolean;
  toggle: () => void;
  close: () => void;
  open: () => void;
}

export function useModalController(withBodyLock?: boolean): IModalController {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    if (withBodyLock) {
      if (opened) {
        document.body.style.position = 'fixed';
      } else {
        document.body.style.removeProperty('position');
      }
    }
  }, [opened, withBodyLock]);

  const toggle = useCallback(() => setOpened(!opened), [opened]);
  const close = useCallback(() => setOpened(false), [setOpened]);
  const open = useCallback(() => setOpened(true), [setOpened]);

  return {
    opened,
    toggle,
    close,
    open,
  };
}
