import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import {
  InvalidateOptions,
  InvalidateQueryFilters,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createAxiosClient } from './client';
import { api } from './methods';
import { config } from '@/config';

export enum MutateStrategy {
  DEFAULT = 'default',
  MULTIPLE = 'multiple',
}

export type ApiType = typeof api;

export const AxiosClientContext = createContext({
  client: createAxiosClient(''),
  queryClient: new QueryClient(),
  api,
  invalidate: async (
    filters?: InvalidateQueryFilters,
    options?: InvalidateOptions,
  ) => {},
  mutate: async (
    keys: Array<string | undefined>,
    strategy: MutateStrategy = MutateStrategy.DEFAULT,
  ) => {},
});

export function AxiosClientProvider({ children }: PropsWithChildren<unknown>) {
  const client = useMemo(() => {
    return createAxiosClient(config.api.bankApi);
  }, []);

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      }),
    [],
  );

  const mutate = useCallback(
    async (
      keys: Array<string | undefined>,
      strategy: MutateStrategy = MutateStrategy.DEFAULT,
    ) => {
      if (strategy === MutateStrategy.DEFAULT) {
        return queryClient.invalidateQueries({ queryKey: keys });
      }

      if (strategy === MutateStrategy.MULTIPLE) {
        return queryClient.invalidateQueries({
          predicate: (query) =>
            !!query.queryKey.filter((key) => keys.includes(String(key))).length,
        });
      }
    },
    [queryClient],
  );

  return (
    <AxiosClientContext.Provider
      value={{
        client,
        queryClient,
        api,
        invalidate: queryClient.invalidateQueries,
        mutate,
      }}
    >
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={true} />
        {children}
      </QueryClientProvider>
    </AxiosClientContext.Provider>
  );
}

export function useApi() {
  return useContext(AxiosClientContext);
}
