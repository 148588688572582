import { ClientUser } from '@/entities';
import { IBaseRequest, IBaseSortable } from '@/api/types';

export interface IGetClientUsersParams extends IBaseSortable {
  roleId?: string;
  createdSince?: string;
  createdTill?: string;
  limit?: number;
  offset?: number;
  states?: string;
}

interface IGetClientUserProps extends IBaseRequest {
  clientId?: string;
  params?: IGetClientUsersParams;
}

export async function getClientUsers({
  api,
  clientId,
  params,
}: IGetClientUserProps) {
  const response = await api.get<ClientUser[]>(
    `clients/${clientId}/client_users`,
    {
      params,
    },
  );

  return response.data;
}
