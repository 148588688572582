import { IQRCodeData } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

export async function createQRLogin({
  api,
}: IBaseRequest): Promise<IBaseResponse<IQRCodeData>> {
  try {
    const response = await api.post<IBaseResponse<IQRCodeData>>('auth/qr');

    if (response.data.success) {
      return createSuccessResponse({
        result: response.data.result,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
