import { useResolution } from '@/device/useResolution';
import { ErrorFallback as ErrorFallbackMobile } from './mobile';
import { ErrorFallback as ErrorFallbackDesktop } from './desktop';

export default function ErrorFallback() {
  const { isMobile } = useResolution();

  if (isMobile) {
    return <ErrorFallbackMobile />;
  }

  return <ErrorFallbackDesktop />;
}
