import { AidFormRedirect } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IAidFlowRedirectProps extends IBaseRequest {
  clientUserId: string;
  aidFormId: string;
}

export async function getAidRedirect({
  api,
  clientUserId,
  aidFormId,
}: IAidFlowRedirectProps): Promise<AidFormRedirect> {
  const response = await api.get<AidFormRedirect>(
    `user/client_users/${clientUserId}/aid_verifications/${aidFormId}/redirect`,
  );

  return response.data;
}
