import { Label } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetLabelsProps extends IBaseRequest {
  clientId?: string;
}

export async function getLabels({
  api,
  clientId,
}: IGetLabelsProps): Promise<Label[]> {
  const response = await api.get<Label[]>(`clients/${clientId}/labels`);

  return response.data;
}
