import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjsPluginUTC from 'dayjs/plugin/utc';
import dayjsPluginTimezone from 'dayjs/plugin/timezone';
import dayjsPluginCustomParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(relativeTime);
dayjs.extend(dayjsPluginUTC);
dayjs.extend(dayjsPluginTimezone);
dayjs.extend(dayjsPluginCustomParseFormat);
