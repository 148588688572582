import { IBaseRequest } from '@/api/types';
import { Balance } from '@/entities';

interface IGetOverallBalanceProps extends IBaseRequest {
  clientId: string;
  params: {
    accountIds: string;
    currency?: string;
  };
}

export async function getOverallBalance({
  api,
  clientId,
  params,
}: IGetOverallBalanceProps): Promise<Balance[]> {
  const response = await api.get<Balance[]>(
    `clients/${clientId}/stat/overall_balance`,
    {
      params,
    },
  );

  return response.data;
}
