import { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from 'react';
import { ParentBackground } from '@/ui/components/types/shared.ts';

export enum ButtonKind {
  ENTITY_ACTION = 'entity-action',
  MAIN = 'main',
  SECONDARY = 'secondary',
  LOAD_MORE = 'load-more',
}

export enum ButtonStyleGuide {
  BRAND = 'brand',
  BRAND_INVERTED = 'brand_inverted',
  DANGER = 'danger',
  DANGER_INVERTED = 'danger-inverted',
  WARNING = 'warning',
  WARNING_INVERTED = 'warning-inverted',
  SUCCESS = 'success',
  SUCCESS_INVERTED = 'success_inverted',
}

enum AdditionalButtonStyleGuide {
  DISABLED = 'disabled',
}

export const MergedButtonStyleGuide = {
  ...ButtonStyleGuide,
  ...AdditionalButtonStyleGuide,
};

export interface IButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  styleGuide?: ButtonStyleGuide;
  kind?: ButtonKind;
  icon?: ReactNode;
  loading?: boolean;
  fullWidth?: boolean;
  parentBackground?: ParentBackground;
}
