import { IBaseRequest } from '@/api/types';
import { AvailableAccount } from '@/entities';

interface IGetAvailableAccountsProps extends IBaseRequest {
  clientId: string;
}

export async function getAvailableAccounts({
  api,
  clientId,
}: IGetAvailableAccountsProps): Promise<AvailableAccount[]> {
  const response = await api.get<AvailableAccount[]>(
    `clients/${clientId}/available_accounts`,
  );

  return response.data;
}
