import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { config } from '@/config';

const MOBILE_APP_ONBOARDING_PARAM = config.mobile.onboardingParam;

export function useDevice() {
  const { search } = useLocation();

  const isIOSWebview = useMemo(() => {
    const userAgent = window.navigator.userAgent.toLowerCase(),
      isSafari = /safari/.test(userAgent),
      isIOS = /iphone|ipod|ipad/.test(userAgent);

    return isIOS ? !isSafari : false;
  }, []);

  const isAndroidWebview = useMemo(() => {
    const userAgent = window.navigator.userAgent;
    return userAgent.includes('wv');
  }, []);

  const isAndroidChromeTabsOnboarding = useMemo(
    () => search.includes(`p=${MOBILE_APP_ONBOARDING_PARAM}`),
    [search],
  );

  const isWebview = useMemo(
    () => isIOSWebview || isAndroidWebview,
    [isAndroidWebview, isIOSWebview],
  );

  return {
    isIOSWebview,
    isAndroidWebview,
    isWebview,
    isAndroidChromeTabsOnboarding,
  };
}
