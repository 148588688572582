import { IDraftdValidateResult } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IValidateInvoiceProps extends IBaseRequest {
  id?: string;
  clientId: string;
}

export async function validateInvoice({
  api,
  id,
  clientId,
}: IValidateInvoiceProps): Promise<IDraftdValidateResult> {
  const response = await api.post<IDraftdValidateResult>(
    `clients/${clientId}/invoices/${id}/validate`,
  );

  return response.data;
}
