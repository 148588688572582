import { IBaseRequest } from '@/api/types';
import { INotificationCounter } from '@/entities';

interface IGetNotificationsCountersProps extends IBaseRequest {
  params: {
    clientId: string;
    important?: boolean;
  };
}

export async function getNotificationsCounters({
  api,
  params,
}: IGetNotificationsCountersProps) {
  const response = await api.get<INotificationCounter>(
    `notifications/counters`,
    {
      params,
    },
  );

  return response.data;
}
