import { IBaseRequest } from '@/api/types';
import { IMostReceivedFromStats } from '@/entities';

interface IGetMostReceivedFromStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    accountId?: string;
    currencyId?: string;
    since?: number;
    till?: number;
  };
}

export async function getMostReceivedFromStats({
  api,
  clientId,
  params,
}: IGetMostReceivedFromStatsProps): Promise<IMostReceivedFromStats> {
  const response = await api.get<IMostReceivedFromStats>(
    `clients/${clientId}/stat/most_received_from`,
    {
      params,
    },
  );

  return response.data;
}
