import { IRoleTemplate } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetRoleTemplateProps extends IBaseRequest {
  clientId?: string;
  templateId?: string;
}

export async function getRoleTemplate({
  api,
  clientId,
  templateId,
}: IGetRoleTemplateProps): Promise<IRoleTemplate> {
  const response = await api.get<IRoleTemplate>(
    `clients/${clientId}/roles/templates/${templateId}`,
  );

  return response.data;
}
