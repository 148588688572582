import { ISuggestedPaymentDetails, PaymentMethodName } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetSuggestedPaymentDetailsProps extends IBaseRequest {
  accountNo: string;
  countryId: string;
  currencyId: string;
  routingNo: string;
  fullName?: string;
  accountType?: string;
  paymentKind: PaymentMethodName;
}

export async function getSuggestedPaymentDetails({
  api,
  accountNo,
  countryId,
  currencyId,
  routingNo,
  fullName,
  accountType,
  paymentKind,
}: IGetSuggestedPaymentDetailsProps): Promise<ISuggestedPaymentDetails> {
  const response = await api.post<ISuggestedPaymentDetails>(
    `payment_details_lookup/${paymentKind}`,
    {
      accountNo,
      countryId,
      currencyId,
      routingNo,
      fullName,
      accountType,
    },
  );

  return response.data;
}
