import { useCallback, useEffect, useMemo, useState } from 'react';
import FingerprintJS, { GetResult } from '@fingerprintjs/fingerprintjs-pro';
import { config } from '@/config';
function getFingerprint() {
  const fpPromise = FingerprintJS.load({
    apiKey: config.security.fingerprint.apiKey,
  });

  return fpPromise
    .then((fp) => fp.get())
    .then((result) => {
      return result;
    });
}

export function useFingerprint() {
  const [fingerprintId, setFingerprintId] = useState('');
  const [meta, setMeta] = useState<GetResult | undefined>();
  const [isScoreLoading] = useState(true);
  const [score] = useState(0);

  const sync = useCallback(async () => {
    const fpIdResponse = await getFingerprint();

    setFingerprintId(fpIdResponse?.visitorId);
    setMeta(fpIdResponse);
  }, []);

  // const getScore = useCallback(async () => {
  //   setScoreLoading(true);
  //   try {
  //     const {
  //       data: { count },
  //     } = (await api.specialCases.fingerprint({
  //       fingerprint: fingerprintId,
  //     })) as SynvoxAxiosResponse<{
  //       count: number;
  //     }>;
  //
  //     setScore(count);
  //
  //     return count;
  //   } catch (e) {
  //     console.log('getScore', e);
  //   } finally {
  //     setScoreLoading(false);
  //   }
  // }, [api.specialCases, fingerprintId]);

  // const sendEvent = useCallback(async () => {
  //   (await api.specialCases.fingerprint.post(
  //     {},
  //     { data: { fingerprint: fingerprintId } },
  //   )) as SynvoxAxiosResponse<{}>;
  //
  //   await getScore();
  // }, [api.specialCases.fingerprint, fingerprintId, getScore]);

  useEffect(() => {
    sync();
  }, []);

  // useEffect(() => {
  //   getScore();
  // }, [fingerprintId]);

  const regularActivity = useMemo(
    () => score < Number(config.security.fingerprint.criticalScore),
    [score],
  );

  const canContinue = useMemo(() => {
    if (!isScoreLoading) {
      return regularActivity;
    }

    return false;
  }, [isScoreLoading, regularActivity]);

  return {
    fingerprintId,
    meta,
    sendEvent: () => {},
    getScore: () => 0,
    score,
    canContinue,
    regularActivity,
    isScoreLoading,
  };
}
