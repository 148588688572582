import { IBaseRequest } from '@/api/types';
import { Currency } from '@/entities';

interface IGetPaymentMethodsCurrenciesProps extends IBaseRequest {
  clientId: string;
  params: {
    direction?: string;
    ownAccount?: boolean;
  };
}

export async function getPaymentMethodsCurrencies({
  api,
  clientId,
  params,
}: IGetPaymentMethodsCurrenciesProps) {
  const response = await api.get<Currency[]>(
    `clients/${clientId}/payment_methods/currencies`,
    {
      params,
    },
  );

  return response.data;
}
