import { forwardRef } from 'react';

export interface ImageProps extends React.HTMLAttributes<HTMLImageElement> {
  ref?: React.Ref<HTMLImageElement>;
  alt?: string;
  src?: string;
  width?: string;
  height?: string;
}

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ alt = 'Arival Bank International Corp.', ...props }, forwardRef) => {
    return <img alt={alt} ref={forwardRef} {...props} />;
  },
);
