import { Wrapper } from '@/ui/components/mobile/Icon/shared';
import { SVGContainer } from '@/ui/components/mobile/Icon/shared/SVGContainer';
import { IIconProps } from '@/ui/components/types/icon';

export function IconHourglass(props: IIconProps) {
  return (
    <Wrapper {...props}>
      <SVGContainer gradient={props.gradient} viewBox="0 0 21 20">
        <path
          d="M14.2916 18.3332C15.2083 18.3332 15.9583 17.5832 15.9583 16.6665L15.9499 14.0165C15.9499 13.5748 15.7749 13.1582 15.4666 12.8415L12.6249 9.99984L15.4666 7.1415C15.7749 6.83317 15.9499 6.40817 15.9499 5.9665L15.9583 3.33317C15.9583 2.4165 15.2083 1.6665 14.2916 1.6665L7.62492 1.6665C6.70825 1.6665 5.95825 2.4165 5.95825 3.33317V5.9665C5.95825 6.40817 6.13325 6.83317 6.44159 7.14984L9.29159 9.99984L6.44992 12.8332C6.13325 13.1498 5.95825 13.5748 5.95825 14.0165V16.6665C5.95825 17.5832 6.70825 18.3332 7.62492 18.3332H14.2916ZM7.62492 5.90817V4.1665C7.62492 3.70817 7.99992 3.33317 8.45825 3.33317L13.4583 3.33317C13.9166 3.33317 14.2916 3.70817 14.2916 4.1665V5.90817C14.2916 6.13317 14.1999 6.3415 14.0499 6.49984L10.9583 9.58317L7.86659 6.4915C7.71659 6.3415 7.62492 6.12484 7.62492 5.90817Z"
          fill="currentColor"
        />
      </SVGContainer>
    </Wrapper>
  );
}
