import { IBaseRequest } from '@/api/types';
import { IFeesStats } from '@/entities';

interface IGetFeesStatsProps extends IBaseRequest {
  clientId: string;
  params: {
    accountId?: string;
    currencyId?: string;
    since?: number;
    till?: number;
  };
}

export async function getFeesStats({
  api,
  clientId,
  params,
}: IGetFeesStatsProps): Promise<IFeesStats> {
  const response = await api.get<IFeesStats>(`clients/${clientId}/stat/fees`, {
    params,
  });

  return response.data;
}
