import { FlexibleObject } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';
import { createRequestWithTempToken } from '@/security/paranoid/createRequestWithTempToken';

interface ICreateSessionProps<T extends FlexibleObject> extends IBaseRequest {
  dto: T;
  path?: string;
  captchaToken: string;
}

type ICreateSessionExtra = {
  token: string;
  refreshToken: string;
};

type ICreateSessionResponse<T> = IBaseResponse<T> & ICreateSessionExtra;

export async function createSession<T extends FlexibleObject, R>({
  api,
  dto,
  path = '',
  captchaToken,
}: ICreateSessionProps<T>): Promise<IBaseResponse<R, ICreateSessionExtra>> {
  try {
    const response = await createRequestWithTempToken<
      T,
      ICreateSessionResponse<R>
    >({
      api,
      dto,
      url: `auth/sessions${path}`,
      captchaToken,
    });

    if (response.data.success) {
      return createSuccessResponse<R, ICreateSessionExtra>({
        result: response.data.result,
        extra: {
          token: response.data.token,
          refreshToken: response.data.refreshToken,
        },
      });
    }

    return createErrorResponse<R, ICreateSessionExtra>({
      error: response.data.error,
      extra: {
        token: '',
        refreshToken: '',
      },
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
