import { IBaseRequest } from '@/api/types';
import { ClientLimits } from '@/entities';

interface IGetLimitsStatProps extends IBaseRequest {
  clientId: string;
}

export async function getLimitsStat({
  api,
  clientId,
}: IGetLimitsStatProps): Promise<ClientLimits> {
  const response = await api.get<ClientLimits>(
    `clients/${clientId}/limits_stat`,
  );

  return response.data;
}
