import { UserDocument } from '@/entities';
import { IBaseRequest, IBaseResponse } from '@/api/types';
import {
  createErrorHandler,
  createErrorResponse,
  createSuccessResponse,
} from '@/api/helpers';

interface IDeleteDocumentProps extends IBaseRequest {
  documentId?: string;
}

export async function deleteDocument({
  api,
  documentId,
}: IDeleteDocumentProps): Promise<IBaseResponse<UserDocument>> {
  try {
    const response = await api.delete<IBaseResponse<UserDocument>>(
      `documents/${documentId}`,
    );

    if (response.data.data) {
      return createSuccessResponse({
        result: response.data.data,
      });
    }

    return createErrorResponse({
      error: response.data.error,
    });
  } catch (error) {
    return createErrorHandler(error);
  }
}
