import { createContext } from 'react';
import { Client, UserClientUser } from '@/entities';

export type ISelectedClientContext = {
  isClientSelectOpen: boolean;
  openClientSelect: () => void;
  closeClientSelect: () => void;
  client: Client | undefined;
  clientId: string;
  unifiedClientId: string;
  isIndividual: boolean;
  isBusiness: boolean;
  clientUser: UserClientUser | undefined;
  setClientUserOrClient: (params: {
    clientUserId?: string;
    clientId?: string;
    withReload?: boolean;
  }) => void;
  resetClientAndClientUser: () => void;
};

export const SelectedClientContext = createContext<ISelectedClientContext>({
  isClientSelectOpen: false,
  openClientSelect: () => {},
  closeClientSelect: () => {},
  client: undefined,
  clientId: '',
  unifiedClientId: '',
  isIndividual: false,
  isBusiness: false,
  clientUser: undefined,
  setClientUserOrClient: () => {},
  resetClientAndClientUser: () => {},
});
