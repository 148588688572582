import { PropsWithChildren, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePosthogRouter } from '@/posthog/usePosthogRouter';

export function useTopLocationOnChange() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
}

export function LocationObserver({ children }: PropsWithChildren<unknown>) {
  useTopLocationOnChange();
  usePosthogRouter();

  return <>{children}</>;
}
