export interface ILogout {
  popup: {
    opened: boolean;
    toggle: () => void;
    close: () => void;
    open: () => void;
  };
  process: () => Promise<void>;
}

export enum AuthorizedState {
  FETCHING = 'FETCHING',
  AUTHORIZED = 'AUTHORIZED',
  UNAUTHORIZED = 'UNAUTHORIZED',
}
