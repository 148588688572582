import { AidFlow } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetAidVerificationsProps extends IBaseRequest {
  clientDraftId: string;
}

export async function getAidVerifications({
  api,
  clientDraftId,
}: IGetAidVerificationsProps): Promise<AidFlow[]> {
  const response = await api.get<AidFlow[]>(
    `client_drafts/${clientDraftId}/aid_verifications`,
  );

  return response.data;
}
