import { ClientUserLimitsStat } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetLimitsStatProps extends IBaseRequest {
  clientId: string;
}

export async function getLimitsStat({
  api,
  clientId,
}: IGetLimitsStatProps): Promise<ClientUserLimitsStat[]> {
  const response = await api.get<ClientUserLimitsStat[]>(
    `clients/${clientId}/client_users/limits_stat`,
  );

  return response.data;
}
