import { ClientUserInvite } from '@/entities';
import { IBaseRequest } from '@/api/types';

interface IGetPublicInvitesProps extends IBaseRequest {
  id?: string;
}

export async function getPublicInvites({
  api,
  id,
}: IGetPublicInvitesProps): Promise<ClientUserInvite> {
  const response = await api.get<ClientUserInvite>(`public_invites/${id}`);

  return response.data;
}
